import React from 'react';
import classNames from 'classnames';
import { translation } from '@ccr-main/diapason-sharedlibrary-ui';
import useBuilderListStyles from './BuilderListStyle';


const BuilderList = (props) => {

    const { showHelpAndLibrary = false, filteredBy, builderList = [], closeDropdown, handleClick, helpOptionHandler, libraryOptionHandler, HasProductPermissions } = props;
    const { root, activeItem, spacingLevel1, spacingLevel2, spacingLevel3, spacingLevelHelpAndLibrary, disabled } = useBuilderListStyles();

    const getWorkflowList = () => {
        if (!builderList.length) { return translation("NOBUILDERSFOUND") };
        const list = [];
        builderList.forEach((workflow) => {
            const { helpOrLibrarySupported, childrenWorkflow, hideChildrenWorkflow } = workflow
            if (showHelpAndLibrary && !helpOrLibrarySupported) { return };
            list.push(getWorkflowItem(spacingLevel1, workflow));
            if (!hideChildrenWorkflow && childrenWorkflow && !!childrenWorkflow.length) {
                const childList = [];
                childrenWorkflow.forEach((childWorkflow) => {
                    const className = showHelpAndLibrary ? spacingLevelHelpAndLibrary : spacingLevel2;
                    childList.push(getWorkflowItem(className, workflow, childWorkflow));
                    showHelpAndLibrary && childList.push(getHelpAndLibraryItem(spacingLevel3, workflow, childWorkflow));
                })
                list.push(<div key={workflow && workflow.Id}>{childList}</div>);
            }
        })
        return list;
    }

    const getWorkflowItem = (className, workflow, childWorkflow = {}) => {
        const showItem = filteredBy ? childWorkflow[filteredBy] || workflow[filteredBy] : true;
        const launchUrl = !showHelpAndLibrary && (childWorkflow.launchUrl || workflow.launchUrl);
        const displayName = childWorkflow.displayName || workflow.displayName;

        if (HasProductPermissions(workflow)) {
            return (showItem &&
                <div key={workflow && workflow.Id} className={classNames(className, launchUrl && activeItem)} onClick={event => handleClick && handleClick(workflow, childWorkflow, closeDropdown)}>
                    {translation(displayName)}
                </div>
            );
        }
    }

    const getHelpAndLibraryItem = (className, workflow, childWorkflow) => {
        const isHelpRequired = childWorkflow.isHelpRequired || workflow.isHelpRequired;
        const isLibraryRequired = childWorkflow.isLibraryRequired || workflow.isLibraryRequired;
        return (
            <>
                <div className={classNames(className, isHelpRequired ? activeItem : disabled)} onClick={event => helpOptionHandler && helpOptionHandler(isHelpRequired, workflow, childWorkflow, closeDropdown)}>
                    Help
                </div>
                <div className={classNames(className, isLibraryRequired ? activeItem : disabled)} onClick={event => libraryOptionHandler && libraryOptionHandler(isLibraryRequired, workflow, childWorkflow, closeDropdown)}>
                    Library
                </div>
            </>
        )
    }

    return (
        <div className={root}>
            {getWorkflowList()}
        </div>
    )
}

export default BuilderList;
