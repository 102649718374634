import React from 'react';
// import { FormattedMessage as Culture } from 'react-intl';
import { injectIntlTranslation } from '@ccr-main/diapason-sharedlibrary-ui';
import { injectIntl } from "react-intl";

import './footer.scss';
import UseNotification from '../../Notifications/UserNotification';

const Footer = ({ isHiden, intl }) => {
    const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
    const isEdge = window.navigator.userAgent.indexOf("Edge") > -1 || window.navigator.userAgent.indexOf("Edg") > -1;
    return (<>
    
       { (!isChrome || isEdge) ? <div className="message-container"> <UseNotification message="#Caution the CSS portal is designed to run in Google chrome browser!! It seems you are in a different browser use Google Chrome for better user experience"/> </div> :null}
    
        <footer >
            <div className="container">
                <div className="footer-wrapper">
                    <div className="links">
                        <a
                            href="https://www.carrier.com/carrier/en/worldwide/legal/privacy-notice/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {/* <Culture id="FooterPrivacyPolicy" /> */}
                            {injectIntlTranslation(intl, "FooterPrivacyPolicy", "Privacy Notice")}
                        </a>
                        <a
                            href="https://www.carrier.com/carrier/en/worldwide/legal/terms-of-use/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {/* <Culture id="FooterTermsOfUse" /> */}
                            {injectIntlTranslation(intl, "FooterTermsOfUse", "Terms of Use")}
                        </a>
                        {/* <a 
                            href="https://www.carrier.com/carrier/en/worldwide/about/?location=us"
                            rel="noopener noreferrer" 
                            target="_blank"
                        >
                            <Culture id="FooterContactUs" />
                        </a> */}
                    </div>
                    <div className="details">
                        <a
                            href="https://www.corporate.carrier.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ display: 'none' }}
                        >
                            {/* <Culture id="CarrierCorpName" /> */}
                            {injectIntlTranslation(intl, "CarrierCorpName", "A Carrier Company")}
                        </a>
                        <span style={{ display: 'none' }}>&nbsp;|&nbsp; </span>
                        <span>
                            Cool Systems Select.
                            &nbsp;&nbsp;&copy;{new Date().getFullYear()}&nbsp; CCR. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
}

export default injectIntl(Footer);
