
import { FETCH_PRODUCT_LIST_START, FETCH_PRODUCT_LIST_FULFILLED, FETCH_PRODUCT_SUCCESS_DATA, PRODUCT_LIST_REFRESH, FETCH_PRODUCT_LIST_FAILED } from '../constants/constant';

const initialState = {
    records: [],
    isLoading: false,
    isSuccess: false,
    needRefresh: false,
    error: '',
    module: ''
};

const getNewProductListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCT_LIST_START:
            return {
                ...state,
                needRefresh: false,
                isLoading: true,
                module: action.module
            }
        case FETCH_PRODUCT_LIST_FULFILLED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                records: action.data,
                module: action.module,
                isSuccess: action.isSuccess,
            }
        case FETCH_PRODUCT_SUCCESS_DATA:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                isSuccess: action.isSuccess,
                module: action.module
            }
        case FETCH_PRODUCT_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                error: action.data,
                module: action.module
            }
        case PRODUCT_LIST_REFRESH:
            return {
                ...state,
                needRefresh: true,
                module: action.module
            }
        default:
            return state;
    }
}

export default getNewProductListReducer