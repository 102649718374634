import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    CableChannelList: [],
    CableChannelWidthDefaultValue: "",
    PipeNetworkList: [],
    PipeTrackGrid: [],
    successResponse: false
};

export default function pipingTrackReducer(state = { ...initialState }, action) {

    switch (action.type) {
        case actionConstants.GET_PIPE_TRACKS_RESPONSE:
            return {
                ...state,
                CableChannelList: action.payload.CableChannelList,
                CableChannelWidthDefaultValue: action.payload.CableChannelWidthDefaultValue,
                PipeNetworkList: action.payload.PipeNetworkList,
                PipeTrackGrid: action.payload.PipeTrackGrid
            }
        case actionConstants.RESET_PIPE_TRACKS_RESPONSE: {
            return { ...initialState }
        }
        case actionConstants.UPDATE_PIPE_TRACKS_RESPONSE_STATE: {
            return {
                ...state,
                successResponse: action.payload
            }
        }
        default:
            return state;
    }
}