import {
    FETCH_PROJECT_SELECTION_SUMMARY_RECORDS,
    GET_COLLING_DEMAN,
    SAVE_CABINET_DATA,
    GET_PRODUCT_RESUTLS,
    RESET_PRODUCT_RESUTLS,
    GET_CHARACTERISTICS_DETAILS_REFRIGERATION_SUPPLY,
    GET_PRICE_RESULTS,
    GET_PRODUCT_RESULTS_BY_ID,
    UPGRADE_TAG_VERSION,
    UPDATE_SUMMARY_TABS_MAPPINGS,
    GET_QUOTE_REPORT_IMAGES,
    GET_REPORT_CONTACT_DETAILS,
    FETCH_PROJECT_SELECTION_SUMMARY_LOADING,
} from "../constants/constant";

const summaryInitialState = {
    projectSummaryLoading: false,
    projectSummaryTags: [],
    projectSummary: [],
    summaryTabMappings: {},
    reportsMasterData: {},
    contactInfo: {
        contactInformation: [],
        additionalSpecifications: ""
    }
};

const projectSummaryReducer = (state = summaryInitialState, action) => {
    switch (action.type) {
        case FETCH_PROJECT_SELECTION_SUMMARY_LOADING:
            return {
                ...state,
                projectSummaryLoading: action.payload,
            }
        case FETCH_PROJECT_SELECTION_SUMMARY_RECORDS:
            return {
                ...state,
                projectSummaryTags: action.payload,
                projectSummary: action.record
            }
        case UPDATE_SUMMARY_TABS_MAPPINGS:
            return {
                ...state,
                summaryTabMappings: action.payload
            }
        case GET_QUOTE_REPORT_IMAGES:
            return {
                ...state,
                reportsMasterData: action.payload
            }
        case GET_REPORT_CONTACT_DETAILS:
            return {
                ...state,
                contactInfo: action.payload
            }
        default: return state;
    }
}
const initialState = {
    resultsList: [],
    selectedResultsList: [],
    postResults: [],
    parameterResults: [],
    zeroResultLimitValidation: []
};

const CoolingDemandReducer = (state = { CoolingDemand: [], CoolingDemandDetails: [] }, action) => {
    switch (action.type) {
        case GET_COLLING_DEMAN:
            return {
                ...state,
                CoolingDemand: action.payload,
                CoolingDemandDetails: action.record
            }
        default: return state;
    }
}

const PriceDetailsReducer = (state = { PriceProduct: [], PriceProductDetails: [] }, action) => {
    switch (action.type) {
        case GET_PRICE_RESULTS:
            return {
                ...state,
                PriceProduct: action.payload,
                PriceProductDetails: action.record
            }
        default: return state;
    }
}

const CABinetResultReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_RESUTLS:
            return {
                ...state,
                CabinetResults: action.payload

            }
        case RESET_PRODUCT_RESUTLS:
            return {
                ...state,
                CabinetResults: []

            }
        default: return state;
    }
}
const GetProductResultIDReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_RESULTS_BY_ID:
            return {
                ...state,
                resultsById: action.payload

            }
        default: return state;
    }
}
const UpgradeTagVersionReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPGRADE_TAG_VERSION:
            return {
                ...state,
                results: action.payload

            }
        default: return state;
    }
}
const SaveCabinetDataReducer = (state = { CoolingDemand: [], CoolingDemandDetails: [] }, action) => {
    switch (action.type) {
        case SAVE_CABINET_DATA:
            return {
                ...state,
                CABINETID: action.payload,
                CABINETID1: action.record
            }
        default: return state;
    }
}

const RefrigerationSupplyReducer = (state = { RefrigerationSupply: [], RefrigerationSupplyDetails: [] }, action) => {
    switch (action.type) {
        case GET_CHARACTERISTICS_DETAILS_REFRIGERATION_SUPPLY:
            return {
                ...state,
                Refrigeration: action.payload,
                Refrigerationdetails: action.record
            }
        default: return state;
    }
}

export {
    projectSummaryReducer,
    CoolingDemandReducer,
    SaveCabinetDataReducer,
    CABinetResultReducer,
    RefrigerationSupplyReducer,
    PriceDetailsReducer,
    GetProductResultIDReducer,
    UpgradeTagVersionReducer,
}
