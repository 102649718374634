import { combineReducers } from 'redux';
import racksResponseReducer from './racksResponseReducer';
import racksSelectionObjReducer from './racksSelectionObjReducer';
import racksGlobalReducer from './racksGlobalReducer';

const racksReducer = combineReducers({
    racksResponse: racksResponseReducer,
    racksSelectionObj: racksSelectionObjReducer,
    racksGlobalSelectionObj: racksGlobalReducer
});

export default racksReducer;