import React, { useState, useEffect, useRef } from 'react';
// import * as Msal from 'msal';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import { loadProgressBar } from 'axios-progress-bar';
import axios from 'axios';
import 'axios-progress-bar/dist/nprogress.css';
import RouterConfig from './components/router';
import { API } from './redux/constants/apiContants';
import Header from './components/common/header/header';
import Footer from './components/common/footer/footer';
import { Tour } from './components/webtour/webtourconfig'
import LeftDrawer from './components/common/navigation/LeftDrawer'
import FeedbackAndReport from './components/common/Assistance/FeedbackAndReport/FeedbackAndReport';
import Support from "./components/common/Assistance/Support/Support";
import { ReactAuthWrapper } from '@ccr-main/reactauthwrapper';
import { updatePermissionsAndLocale } from './redux/Actions/userRolesAndPermissionUpdate';
import { updateMessages, getMessages } from './redux/Actions/userPerference';
import { ApiService } from '@ccr-main/diapason-sharedlibrary-ui';
import appConfig from './Environment/environments';
import './App.scss';
import { createSvg, noPermissionText } from './utilities/errorScreen';
import theme from './utilities/constants/theme';
import { createTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';

const App = (props) => {

    const mainSectionDiv = useRef();

    // const [showEcatMigrationError, setShowEcatMigrationError] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [isFeedbackAndReportOpen, setIsFeedbackAndReportOpen] = useState(false);
    const [isSupportOpen, setIsSupportOpen] = useState(false);
    const [role, setRole] = useState("");
    const [permissions, setPermissions] = useState("");

    const [translations, setTranslations] = useState({});

    const closeTour = () => setIsTourOpen(false);

    const setShowWebTour = (val) => setIsTourOpen(val);

    useEffect(() => {
        // props.getMessages(props.lang);
        loadProgressBar({ easing: 'ease-in-out', speed: 100, showSpinner: false }, axios);
    }, [])

    const authCallback = (data) => props.updatePermissionsAndLocale({ data, userRoles: props.userRoles });

    const redirectToOriginal = (deleteSearch = false) => {
        const { location } = props;
        delete location.hash;
        if (deleteSearch) {
            delete location.search;
        }
        props.history.push(location);
    }

    const isEcatMigrated = (oldname) => {
        ApiService(`${appConfig.api.cssMicroService}${API.GET_MIGRATION_STATUS}${oldname}`, 'get')
        // .then(res => {
        //     setShowEcatMigrationError(!res.data)
        // })
        // .catch(err => {
        //     setShowEcatMigrationError(true)
        // })
    }

    const appPermissionsWithRoles = (role, permissions) => {
        setRole(role);
        setPermissions(permissions);
        redirectToOriginal();
    }

    const handleFeedbackAndReportClick = (value) => setIsFeedbackAndReportOpen(value);

    const handleSupportClick = (value) => setIsSupportOpen(value);

    useEffect(() => {
        setTranslations({ ...props.message, ...props.equipMessage });
    }, [props.message, props.equipMessage])

    const onError = (err) => {
        if (process.env.NODE_ENV === 'development') {
            return;
        } else {
            console.error(err);
        }
    }

    const renderMainApp = () => {

        return (
            <IntlProvider locale={props.lang} messages={translations} onError={onError}>
                <>
                    <Header appPermissionsWithRoles={appPermissionsWithRoles} isEcatMigrated={isEcatMigrated} />
                    {!props.userPreferencesLoading ?
                        <section className="section-wrapper" id="mainsection" ref={mainSectionDiv}>
                            <div className="container" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                <RouterConfig role={role} permissions={permissions} />
                                <Tour isOpen={isTourOpen} closeTour={closeTour} />
                                <FeedbackAndReport isFeedbackAndReportOpen={isFeedbackAndReportOpen} handleFeedbackAndReportClick={handleFeedbackAndReportClick} />
                                <Support supportClick={isSupportOpen} handleSupportClick={handleSupportClick} />
                            </div>
                            <LeftDrawer isShowWebTour={setShowWebTour} handleFeedbackAndReportClick={handleFeedbackAndReportClick} handleSupportClick={handleSupportClick}></LeftDrawer>
                            <Footer />
                        </section> : null
                    }
                </>
            </IntlProvider>
        )
    }

    const themeWithLocale = React.useMemo(
        () => {
            return createTheme(theme, locales[props.fullLangCode.replace("-", "")]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.fullLangCode, theme]
    );

    return (
        <ThemeProvider theme={themeWithLocale}>
            <ReactAuthWrapper
                appConfig={appConfig.api.loginConfig}
                getPermissions={authCallback}
                errorScreenLogo={createSvg}
                noPermissionText={noPermissionText}
            >
                {renderMainApp()}

            </ReactAuthWrapper>
        </ThemeProvider>
    );

}

const mapStateToProps = state => {
    return {
        userRoles: state.locale.userRoles,
        lang: state.locale.lang,
        fullLangCode: state.locale.fullLangCode,
        message: state.locale.message,
        equipMessage: state.locale.equipMessage,
        userPreferencesLoading: state.locale.isLoading
    };
};


export default withRouter(connect(mapStateToProps, { updatePermissionsAndLocale, getMessages, updateMessages })(App));