import { UPDATE_USER_PROFILE } from '../constants/constant';

const initialState = {
    firstName: '',
    lastName: '',
    fullName: '',
    initials: '',
    emailAddress: '',
    oldEmailAddress: '',
    groups: [],
    appAccess: false,
    permissions: []
};

export default function userProfileReducer(state = initialState, action){
    switch(action.type){
        case UPDATE_USER_PROFILE:
            const { firstName, lastName } = action.data;
            return {
                ...state, ...action.data,  fullName:`${firstName} ${lastName}`
            }
        default:
            return state;
    }
}