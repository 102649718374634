import {
    LOCALE_SET_START,
    LOCALE_SET_FULFILLED,
    LOCALE_SET_FAILED,
    UPDATE_USER_PROFILE, LOCALE_UPDATE_USER_ROLES, LOCALE_UPDATE_DIAPASON_ROLES, UPDATE_MESSAGES,LOCALE_SET_VERSION_FULFILLED,
} from '../constants/constant';
import { API } from '../constants/apiContants';
import { LangOpt, getLanguageDetails } from '../../utilities/languagesutils';
import appConfig from '../../Environment/environments';
import { ApiService, sharedConstants, endPoints } from "@ccr-main/diapason-sharedlibrary-ui";
import { checkAndUpdateUserPerference } from './userRolesAndPermissionUpdate';

export const getLocale = (callbackdata) => (dispatch, getState) => {
    const { locale: { userRoles, diapasonrole, message, lang } } = getState();
    dispatch({ type: LOCALE_SET_START });
    ApiService(`${appConfig.api.cssMicroService}${API.GET_USER_PREF}`, 'get').then(async (res) => {

        callbackdata && callbackdata(res.data);

        let preferedLang;
        LangOpt.forEach(opt => {
            if (opt.name.toUpperCase() === res.data.Language.toUpperCase()) {
                preferedLang = opt;
                //return;
            }
        });

        let newMessage;
        if (lang !== preferedLang.lang || Object.keys(message).length === 0) {
            const { data } = await ApiService(`${appConfig.api.translationApi}${endPoints.TRANSLATIONS_METHOD}${sharedConstants.TRANSLATION_FRAMEWORK_PROJECT_ID}${preferedLang.lang}`, 'GET');
            newMessage = { ...data.result };
        } else {
            newMessage = message;
        }

        dispatch({
            type: LOCALE_SET_FULFILLED,
            data: { lang: preferedLang.lang, UnitSystem: res.data.UnitSystem, Language: preferedLang.name, fullLangCode: preferedLang.fullLangCode, Role: res.data.Role, diapasonrole: diapasonrole, message: newMessage }
        });
        if (!!userRoles.length && userRoles[0] !== "Anonymous") {
            checkAndUpdateUserPerference({ dispatch, name: res.data.Language, role: res.data.Role, unit: res.data.UnitSystem, userRoles, diapasonrole: diapasonrole })
        }
    }).catch(err => {
        dispatch({
            type: LOCALE_SET_FAILED,
            error: err
        });
    })
}
export const updateUserRoles = (userRoles) => (dispatch) => {
    dispatch({
        type: LOCALE_UPDATE_USER_ROLES,
        data: { userRoles: userRoles }
    })
}
export const updatediapasonRole = (Roles) => (dispatch) => {
    dispatch({
        type: LOCALE_UPDATE_DIAPASON_ROLES,
        data: { diapasonrole: Roles }
    })
}
export const updatediapasonVersion = (version) => (dispatch) => {
    dispatch({
        type: LOCALE_SET_VERSION_FULFILLED,
        data: { diapasonVersion: version }
    })
}
export const UserPerference = (props) => (dispatch) => {
    const { opt, unit, role, isUnitChange, succesCallback = "", failureCallback = "", diapasonrole } = props;
    var jsonData = {
        "UnitSystem": unit,
        "Language": opt.name,
        "SalesEntity": "",
        "IsCacheClear": "",
        "Role": role
    }
    Promise.all([
        ApiService(`${appConfig.api.cssMicroService}${API.POST_UPDATE_LANGUAGE}`, 'POST', jsonData)
        ,ApiService(`${appConfig.api.translationApi}${endPoints.TRANSLATIONS_METHOD}${sharedConstants.TRANSLATION_FRAMEWORK_PROJECT_ID}${opt.lang}`, 'GET')
    ]).then(([result, translationMsgs]) => {
        sessionStorage.ecatLang = JSON.stringify({ lang: opt.lang, Language: result.data.Language });
        const { fullLangCode, lang } = getLanguageDetails(result.data.Language);
        dispatch({
            type: LOCALE_SET_FULFILLED,
            data: { ...result.data, Role: result.data.Role, fullLangCode, lang: lang || opt.lang, diapasonrole: diapasonrole, message: { ...translationMsgs.data.result } }
        })
        isUnitChange && succesCallback();
    }).catch(() => {
        isUnitChange && failureCallback();
    });
};

export const getMessages = (lang) => async (dispatch) => {
    let url = `${appConfig.api.translationApi}${endPoints.TRANSLATIONS_METHOD}${sharedConstants.TRANSLATION_FRAMEWORK_PROJECT_ID}${lang}`
    const { data } = await ApiService(url, 'GET');
    dispatch({ type: UPDATE_MESSAGES, data: { ...data.result } })
};
export const updateMessages = (message) => async (dispatch) => {
    dispatch({ type: UPDATE_MESSAGES, data: message })
};
export const updateUserProfile = (data) => (dispatch) => {
    const initials = `${data.lastName.substring(0, 6).toLowerCase()}${data.firstName[0].toLowerCase()}`;
    const updatedData = { ...data, initials };
    dispatch({ type: UPDATE_USER_PROFILE, data: updatedData });
};