// import deImg from './../assets/images/lang/NL.png';
import enImg from './../assets/images/lang/EN-US.png';
import frImg from './../assets/images/lang/FR.png';
import geImg from './../assets/images/lang/DE.png';
// import itImg from './../assets/images/lang/IT.png';
// import ruImg from './../assets/images/lang/RU.png';
// import plImg from './../assets/images/lang/pl-PL.png';
// import trImg from './../assets/images/lang/tr-TR.png';
import chImg from './../assets/images/lang/china.png';
// import esImg from './../assets/images/lang/ES.png';

export const LangOpt = [
    // {
    //     name: "Dutch",
    //     lang: "nl",
    //     fullLangCode: "nl-NL",
    //     img: deImg
    // },
    {
        name: "English",
        lang: "en",
        fullLangCode: "en-US",
        img: enImg
    },
    {
        name: "French",
        lang: "fr",
        fullLangCode: "fr-FR",
        img: frImg
    },
    {
        name: "German",
        lang: "de",
        fullLangCode: "de-DE",
        img: geImg
    },
    // {
    //     name: "Italian",
    //     lang: "it",
    //     fullLangCode: "it-IT",
    //     img: itImg
    // },
    // {
    //     name: "Russian",
    //     lang: "ru",
    //     fullLangCode: "ru-RU",
    //     img: ruImg
    // },
    // {
    //     name: "Polish",
    //     lang: "pl",
    //     fullLangCode: "pl-PL",
    //     img: plImg
    // },
    // {
    //     name: "Turkish",
    //     lang: "tr",
    //     fullLangCode: "tr-TR",
    //     img: trImg
    // },
    {
        name: "Simplified chinese",
        lang: "zh-Hans",
        fullLangCode: "zh-CN",
        img: chImg
    },
    // {
    //     name: "Spanish",
    //     lang: "es",
    //     fullLangCode: "es-ES",
    //     img: esImg
    // },
    // {
    //     name: "Traditional chinese",
    //     lang: "zh-Hant",
    //     fullLangCode: "zh-TW",
    //     img: chImg
    // },
];

export const getLanguageDetails = (language) => {
    let languageDetails = {};
    if (!language || (typeof language !== "string")) { return languageDetails };

    for (const opt of LangOpt) {
        if (opt.name.toUpperCase() === language.toUpperCase()) {
            languageDetails = opt;
            break;
        }
    };
    return languageDetails;
}