import { FETCH_HELPCONTROLLER_START, FETCH_HELPCONTROLLER_FULFILLED, FETCH_HELPCONTROLLER_FAILED } from '../constants/constant.js';
import { API } from '../constants/apiContants';
import { ApiService } from '@ccr-main/diapason-sharedlibrary-ui';
import appConfig from '../../Environment/environments';
import { getBrowserInfo } from '../../components/common/Utilities.js';

export const postFeedback = (payload) => (dispatch) => {
    const browserInfo = getBrowserInfo();

    const {
        emailTemplate,
        images,
        agreeToShareImage,
        hasAttachement,
        rating,
        categorySelection,
        isBug,
        isIdea,
        comment,
        UserGroupName,
        PlatformVersion,
        CondenserProductVersion,
        EvaporatorProductVersion,
        DryCoolerProductVersion,
        BrineCoolerProductVersion,
        GasCoolerProductVersion,
        RackProductVersion,
        ElectricPanelVersion,
        CDUVersion
    } = payload;

    images.push({
        content: Buffer.from(JSON.stringify(browserInfo)).toString("base64"),
        disposition: "attachment",
        filename: "BrowserInfo.txt",
        type: "text",
        contentId: "BrowserInfo.txt"
    })

    const jsonData = {
        "emailTemplate": emailTemplate,
        "images": images || [],
        "agreeToShareImage": agreeToShareImage || false,
        'hasAttachement': hasAttachement || false,
        "rating": rating || 0,
        "isBug": isBug || false,
        "isIdea": isIdea || false,
        "categorySelection": categorySelection,
        "comment": comment || "",
        "UserGroupName": UserGroupName,
        "PlatformVersion": PlatformVersion,
        "condenserProductVersion": CondenserProductVersion,
        "evaporatorProductVersion": EvaporatorProductVersion,
        "dryCoolerProductVersion": DryCoolerProductVersion,
        "brineCoolerProductVersion": BrineCoolerProductVersion,
        "gasCoolerProductVersion": GasCoolerProductVersion,
        "rackProductVersion": RackProductVersion,
        "electricPanelVersion": ElectricPanelVersion,
        "condensingUnitVersion": CDUVersion,
        "BrowserName": browserInfo?.applicationName,
        "BrowserLanguage": browserInfo?.browserLanguage,
        "BrowserVersion": browserInfo?.browserVersion1a,
        "BrowserPlatform": browserInfo?.browserPlatform,
    };

    dispatch({ type: FETCH_HELPCONTROLLER_START });

    return new Promise((resolve, reject) => {
        ApiService(`${appConfig.api.helpcontroler}${API.SAVE_EMAIL_TO_SUPPORT}`, 'POST', jsonData).then(result => {
            dispatch({
                type: FETCH_HELPCONTROLLER_FULFILLED,
                data: result.status
            });

            if (result.status === 200) resolve();
            else reject();

        }).catch(request => {
            dispatch({
                type: FETCH_HELPCONTROLLER_FAILED,
                data: request.response.status
            });

            reject();
        });
    })
}