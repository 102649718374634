import { FETCH_ADDPROJECT_START, FETCH_ADDPROJECT_FULFILLED, FETCH_ADDPROJECT_FAILED, GET_PROJECT_TYPE_LIST, GET_APPLICATION_TYPE_LIST, GET_PROJECT_STATUS_TYPE_LIST, GET_PROJECT_OPPURTUNITY_STAGES_LIST, GET_PROJECT_ACCOUNT_AREA_LIST, GET_PFI_USER_CHECK } from '../constants/constant';

const initialState = {
    records: [],
    isLoading: false,
    error: '',
    projectTypeOptions: [],
    applicationTypeOptions: [],
    projectStatusOptions: [],
    areaDetails: [],
    isPFIUser: false
};

export default function createNewProjectReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADDPROJECT_START:
            return {
                ...state,
                isLoading: true,
                error: ''
            }
        case FETCH_ADDPROJECT_FULFILLED:
            return {
                ...state,
                isLoading: false,
                records: action.data,
                error: ''
            }
        case FETCH_ADDPROJECT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action && action.data,

            }
        case GET_PROJECT_TYPE_LIST:
            return {
                ...state,
                projectTypeOptions: action.data
            }
        case GET_APPLICATION_TYPE_LIST:
            return {
                ...state,
                applicationTypeOptions: action.data
            }
        case GET_PROJECT_STATUS_TYPE_LIST:
            return {
                ...state,
                projectStatusOptions: action.data
            }
        case GET_PROJECT_OPPURTUNITY_STAGES_LIST:
            return {
                ...state,
                opportunityStages: action.data
            }
        case GET_PROJECT_ACCOUNT_AREA_LIST:
            return {
                ...state,
                areaDetails: action.data
            }
        case GET_PFI_USER_CHECK:
            return {
                ...state,
                isPFIUser: action.data
            }
        default:
            return state;
    }
}