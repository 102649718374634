import React, { useEffect} from "react";
import Versions from "../../../utilities/versions";
import { useDispatch } from 'react-redux';
import { injectIntlTranslation, translation, ConfirmDialog } from "@ccr-main/diapason-sharedlibrary-ui";
import { updatediapasonVersion } from '../../../redux/Actions/userPerference';
const AboutCSS = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatediapasonVersion({
            diapasonVersion: Versions.DiapasonVersion
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const modalContent = (
        <div className="">
            <div className="">
                <div className="">
                    <div style={{ display: "flex" }}>
                        <p style={{ width: "250px" }}><strong>{translation("DIAPASONVersion")}: </strong></p>
                        <p> {Versions.DiapasonVersion} </p>
                    </div>
                    <div>
                        <dl>
                            <dt><strong>{translation("ProductBuilderDetails")} </strong></dt>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "Racks", "Racks")} ({Versions.ProductBuilderDetails.Racks}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "CDU", "CDU")} ({Versions.ProductBuilderDetails.CDU}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "Condenser", "Condenser")} ({Versions.ProductBuilderDetails.Condensers}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "Evaporator", "Air Cooler")} ({Versions.ProductBuilderDetails.Evaporator}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "Dry Cooler", "Dry Cooler")} ({Versions.ProductBuilderDetails.DryCooler}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "Brine Cooler", "Brine Cooler")} ({Versions.ProductBuilderDetails.BrineAirCooler}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "Gas Cooler", "Gas Cooler")} ({Versions.ProductBuilderDetails.GasCooler}) </dd>
                            <dd className="builder-version">{injectIntlTranslation(props.intl, "ElectricPanel", "Electric Panel")} ({Versions.ProductBuilderDetails.ElectricPanel}) </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <ConfirmDialog
            title={translation("AboutDiapason")}
            content={modalContent}
            action={"ABOUT"}
            openDialog={props.open}
            closeDialog={props.toggleDialog}
            hidePrimaryAction={true}
        />
    )
}

export default AboutCSS