import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    characteristics: {
        Characteristics: {
            Sections: {}
        }
    }
};

export default function cabinetmanualResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_CHARSTCS_RESPONSE_CABINETMANUAL:
            return {
                ...state,
                characteristics: action.payload
            }
        case actionConstants.RESET_CABINETMANUAL_RESPONSE_OBJ:
            return { ...initialState }

        default:
            return state;
    }
}