import React, { useState, useRef } from "react";
import { withRouter } from "react-router";
import { enqueueSnackbar } from 'notistack';

const LangDropdown = (props) => {
    const { selectedItem, handleChangeLang } = props;
    const node = useRef();
    const [open, setOpen] = useState(false);

    const handleChange = (selectedObj) => {
        setOpen(false);
        if ((props.history.location.pathname === "/Naapp" || props.history.location.pathname === "/NAConfiguration") && selectedObj.lang !== "en") {
            enqueueSnackbar("The selected language is not supported for this Product line. Language defaulted to English.", { variant: 'info' });
            handleChangeLang(selectedObj);
        } else {
            handleChangeLang(selectedObj);
        }
    };

    return (
        <li ref={node} className='dropdown-lang-wrapper' onClick={() => setOpen(!open)} data-tut="reactour__language">
            <span role='button' className='dropdown-toggler'>
                <img src={selectedItem.img} alt={selectedItem.name} />
                {selectedItem.name}
                <span className='caret' />
            </span>

            {open && (
                <ul className='dropdown-lang lang-dropdown-menu'>
                    {props.options.map((opt, idx) => (
                        <li
                            className={opt.name === selectedItem.name ? 'active' : ''}
                            key={idx}
                            onClick={(e) => handleChange(opt)}
                        >
                            <img src={opt.img} alt='' /> {opt.name}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    )
}

export default withRouter(LangDropdown);