import { SET_PROJECT_MASTER_DATA, SET_PROJECT_MASTER_DATA_TREESTRUCTURE } from "../constants/constant";

const initialState = {
    generalSettings: {
        TreeStructure: [],
        TreeViewData: [
            {
                Inclusions: [],
                Visibility: []
            }
        ]
    }
};

const projectMasterDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_MASTER_DATA:
            return { ...state, ...action.payload }
        case SET_PROJECT_MASTER_DATA_TREESTRUCTURE:
            return {
                ...state,
                generalSettings: {
                    ...state.generalSettings,
                    TreeStructure: action.payload
                }
            }
        default:
            return state;
    }
}

export default projectMasterDataReducer