import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    factory: ''
};

export default function drycoolerGlobalReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionConstants.UPDATE_FACTORYSELECTION_DRYCOOLER:
            return {
                ...state,
                factory: action.payload
            }
        default:
            return state;
    }
}