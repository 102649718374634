export const launchSelectionFlow = ({ workflow = {}, childWorkflow = {}, history, projectDetails = {}, fromBuilderId = "" }) => (dispatch) => {
    const launchUrl = childWorkflow.launchUrl || workflow.launchUrl;

    if (launchUrl) {
        const { selection } = launchUrl;
        if (selection.url) {
            history.push(selection.url, {
                Id: childWorkflow.id || '',
                Builder: workflow.id,
                FullName: getFullName(workflow, childWorkflow),
                Name: childWorkflow.displayName || '',
                fromBuilderId: fromBuilderId,
                ...projectDetails
            });
        }
    }
};

export const getFullName = (workflow, childWorkflow) => {
    if (childWorkflow.displayName) {
        return `${workflow.displayName} (${childWorkflow.displayName})`
    }
    return workflow.displayName;
}