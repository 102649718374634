import React, { Suspense, lazy, useMemo, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import authcallback from './Shared/Authcallback/authcallback';
import PrivateRoute from "./PrivateRoute";
import Error from "./Error";
import { NotFound } from "./common/NotFound";
import workFlowsConfig from './WorkflowsConfig';
import { updateAllBuilderRoutes } from '../redux/Actions/getAllProducts';

const NewDashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './NewDashboard/NewDashboard'));
const ProjectSummary = lazy(() => import(/* webpackChunkName: "ProjectSummary" */ './ProjectSummary/ProjectSummary'));
const DataManagement = lazy(() => import(/* webpackChunkName: "DataManagement" */ './DataManagement/DataManagement'));

const RouterConfig = (props) => {
    const { role, permissions, updateAllBuilderRoutes } = props;
    const [privateConfigProps, setPrivateConfigProps] = useState({
        permissions: permissions,
        role: role
    });

    useEffect(() => {
        setPrivateConfigProps({
            permissions: permissions,
            role: role
        });
    }, [role, permissions])

    const getAllWorkflowRoutes = (workFlowsConfig) => {
        const allWorkFlowRoutes = [];
        workFlowsConfig.forEach((workFlow) => {
            const { launchUrl, childrenWorkflow } = workFlow;
            addAllRoute(allWorkFlowRoutes, launchUrl);
            addChildrenWorkflowRoute(allWorkFlowRoutes, childrenWorkflow);
        });
        updateAllBuilderRoutes([...allWorkFlowRoutes,
        { url: '/ProjectSummary' },
        { url: '/DataManagement' }
        ]);
        return allWorkFlowRoutes;
    }

    const addAllRoute = (allWorkFlowRoutes, launchUrl) => {
        if (launchUrl) {
            const { selection, configuration, additional } = launchUrl;
            addRoute(allWorkFlowRoutes, selection);
            addRoute(allWorkFlowRoutes, configuration);
            addAdditionalRoute(allWorkFlowRoutes, additional);
        }
    }

    const addChildrenWorkflowRoute = (allWorkFlowRoutes, childrenWorkflow) => {
        if (childrenWorkflow && !!childrenWorkflow.length) {
            childrenWorkflow.forEach((childFlow) => {
                addAllRoute(allWorkFlowRoutes, childFlow.launchUrl);
            });
        }
    }

    const addRoute = async (allWorkFlowRoutes, route) => {
        if (route) {
            allWorkFlowRoutes.push(route);
        }
    }

    const addAdditionalRoute = (allWorkFlowRoutes, additionalRoute) => {
        if (additionalRoute && !!additionalRoute.length) {
            additionalRoute.forEach((route) => addRoute(allWorkFlowRoutes, route));
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedAllWorkflowRoutes = useMemo(() => getAllWorkflowRoutes(workFlowsConfig), [workFlowsConfig]);

    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                <PrivateRoute exact path='/' component={NewDashboard} props={privateConfigProps} />
                <PrivateRoute path='/ProjectSummary' component={ProjectSummary} props={privateConfigProps} />
                {memoizedAllWorkflowRoutes.map((route) => <PrivateRoute key={route.url} path={route.url} component={route.component} props={privateConfigProps} />)}
                <PrivateRoute path="/authcallback" component={authcallback} props={privateConfigProps} />
                {props.adminPagePermission ? <PrivateRoute exact path='/DataManagement' component={DataManagement} props={privateConfigProps} /> : null}
                <Route exact path='/ErrorPage' component={Error} role={role} permissions={permissions} />
                <Route path="*" component={NotFound} />
            </Switch>
        </Suspense>
    )
}
const mapStateToProps = (state) => ({
    permissions: state.userProfile,
    role: state.locale.role,
    adminPagePermission: state.dataManagment.adminPagePermission,
    langloading: state.locale.isLoading,
});
export default connect(mapStateToProps, { updateAllBuilderRoutes })(RouterConfig);;
