import DiapasonCondenserConfig from "@ccr-main/diapason-condenser-ui/diapasonCondenserConfig";
import DiapasonEvaporatorConfig from "@ccr-main/diapason-evaporator-ui/diapasonEvaporatorConfig";
import DiapasonDryCoolerConfig from "@ccr-main/diapason-drycooler-ui/diapasonDryCoolerConfig";
import DiapasonBrineAirCoolerConfig from "@ccr-main/diapason-brineaircooler-ui/diapasonBrineAirCoolerConfig";
import DiapasonGasCoolerConfig from "@ccr-main/diapason-gascooler-ui/diapasonGasCoolerConfig";
import DiapasonRacksConfig from "@ccr-main/diapason-racks-ui/diapasonRacksConfig";
import DiapasonCDUConfig from "@ccr-main/diapason-cdu-ui/diapasonCDUConfig";
import DiapasonElectricalPanelConfig from "@ccr-main/diapason-electricalpanel-ui/diapasonElectricalPanelConfig";
import DiapasonCoolingDemandConfig from "@ccr-main/diapason-coolingdemand-ui/diapasonCoolingDemandConfig";
import DiapasonColdRoomConfig from "@ccr-main/diapason-coldroom-ui/diapasonColdRoomConfig";
import DiapasonPipingConfig from "@ccr-main/diapason-piping-ui/diapasonPipingConfig";

export const workflowsConfig = [
    { ...DiapasonRacksConfig },
    { ...DiapasonCDUConfig },
    { ...DiapasonElectricalPanelConfig },
    { ...DiapasonCondenserConfig },
    { ...DiapasonEvaporatorConfig },
    { ...DiapasonDryCoolerConfig },
    { ...DiapasonBrineAirCoolerConfig },
    { ...DiapasonGasCoolerConfig },
    { ...DiapasonCoolingDemandConfig },
    { ...DiapasonColdRoomConfig },
    { ...DiapasonPipingConfig },
];

export default workflowsConfig;
