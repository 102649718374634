import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    isDefaultSettings: false,
    isEditMode: false,
    defaultSettingsObj: {},
    editSelectionObj: {},
    currentSelectionObj: {},
    calculationResults: {}
};

export default function refregirationsupplySelectionObjReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionConstants.TEXTFIELD_UPDATED_REFRIGERATION:
            return {
                ...state,
                isTextFieldUpdated: action.payload
            }

        case actionConstants.SET_CURRENT_SELECTION_OBJ_REFREGIRATIONSUPPLY:
            return {
                ...state,
                currentSelectionObj: action.payload
            }
        case actionConstants.UPDATE_DEFAULT_OBJ_REFREGIRATIONSUPPLY:
            return {
                ...state,
                defaultSettingsObj: action.payload
            }
        case actionConstants.UPDATE_CURRENT_SELECTION_OBJ_REFREGIRATIONSUPPLY:
            if (action.payload.stage === "RuleCandidates") {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        RuleCandidates: [
                            {
                                ...state.currentSelectionObj.RuleCandidates[0],
                                [action.payload.id]: action.payload.value
                            }
                        ]
                    }
                }
            }
            else {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        [action.payload.stage]: {
                            ...state.currentSelectionObj[action.payload.stage],
                            [action.payload.id]: action.payload.value
                        }
                    }
                }
            }

        case actionConstants.SET_REFREGIRATION_SUPPLY_CALC_RESULTS:
            return {
                ...state,
                calculationResults: action.payload
            }

        case "RESET_CURRENT_SELECTION_OBJ_REFREGIRATIONSUPPLY":
            return {
                state: initialState
            }

        default:
            return state;
    }
}