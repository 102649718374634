import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import { enqueueSnackbar } from 'notistack';

import {
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    FormLabel,
    FormControl,
    CircularProgress,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {
    translation,
    injectIntlTranslation
} from "@ccr-main/diapason-sharedlibrary-ui";
import { postFeedback } from "../../../../redux/Actions/AssistanceActions";
import Versions from "../../../../utilities/versions";

import IconButton from '@mui/material/IconButton';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField'
// import InputAdornment from '@mui/material/InputAdornment'


const useSupportStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontSize: '24px !important',
    },
    formControl: {
        marginTop: "9px !important",
        marginLeft: "24px !important",
    },
    // supportbutton: {
    //     margin: "24px !important",
    //     height: "38px !important",
    //     background: "#263B7A !important",
    //     color: '#ffffff !important',
    //     borderRadius: "4px !important",
    //     padding: "6px 16px !important",
    //     "&.Mui-disabled": {
    //         background: "rgba(0, 0, 0, 0.12) !important",
    //     }
    // },
    // cancelbutton: {
    //     margin: "24px !important",
    //     height: "38px !important",
    //     color: "#3f51b5 !important",
    //     border: "1px solid rgba(63, 81, 181, 0.5) !important",
    //     borderRadius: "4px !important",
    //     padding: "6px 16px !important",
    // },
    margin: {
        marginLeft: "10px !important"
    },
    marginnolabel: {
        marginLeft: "10px !important",
        marginTop: "22px !important"
    },
    textareaBox: {
        height: "100% !important",
        width: "80% !important",
        marginLeft: "43px",
        fontSize: '16px'
    },
    title: {
        fontSize: "18px !important",
        color: "#000000 !important"
    },
    label: {
        fontSize: '17px !important',
        color: "#000000 !important"
    },
    // dialogContent: {
    //     marginBottom : "0px !important"
    // }
    dialogTitle: {
        padding: "5px 10px !important",
        backgroundColor: "lightgray"
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: "space-between"
    },
    dialogHeaderText: {
        paddingTop: "4px"
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: "15px 15px !important",
    },
    dialogActions: {
        padding: "5px 10px !important",
    }
}));

const Support = ({ supportClick, handleSupportClick, intl }) => {
    const classes = useSupportStyles();
    const dispatch = useDispatch()
    const user = useSelector(state => state.userProfile);
    const [generalquestionselect, setGeneralquestionselect] = useState([]);
    const [featureselect, setFeatureselect] = useState([]);
    const [accountselect, setAccountselect] = useState([]);
    const [otherSupportselect, setotherSupportselect] = useState([]);
    const [textareaselect, setTextareaselect] = useState("");
    const [loader, setloader] = useState(false);

    const handleClose = () => {
        handleSupportClick(false);
        setGeneralquestionselect([]);
        setFeatureselect([]);
        setAccountselect([]);
        setotherSupportselect([]);
        setTextareaselect("");
        setloader(false)
    };

    const GetChecked = (name) => {
        if (name === "GeneralQuestion") {
            return generalquestionselect.indexOf(name) > -1
        } else if (name === "Feature") {
            return featureselect.indexOf(name) > -1
        }
        else if (name === "Account") {
            return accountselect.indexOf(name) > -1
        }
        else if (name === "Other") {
            return otherSupportselect.indexOf(name) > -1
        }
        else return false
    }

    const handleChangeSupport = (event, name) => {
        if (name === "generalquestion") {
            if (event.target.checked) {
                setGeneralquestionselect(event.target.value)
            } else {
                setGeneralquestionselect([])
            }
        } else if (name === "feature") {
            if (event.target.checked) {
                setFeatureselect(event.target.value)
            } else {
                setFeatureselect([])
            }
        }
        else if (name === "account") {
            if (event.target.checked) {
                setAccountselect(event.target.value)
            } else {
                setAccountselect([])
            }
        }
        else if (name === "other") {
            if (event.target.checked) {
                setotherSupportselect(event.target.value)
            } else {
                setotherSupportselect([])
            }
        }
    };

    const handleTextareaChange = (event) => {
        setTextareaselect(event.target.value);
    }

    const supportOnClick = () => {
        setloader(true)
        let emailTemplate = "Support";
        const category = [];
        let groupName = [];
        if (generalquestionselect.length > 0 || featureselect.length > 0 || accountselect.length > 0 || otherSupportselect.length > 0) {
            category.push(generalquestionselect, featureselect, accountselect, otherSupportselect);
        }
        let newArray = category.filter(value => JSON.stringify(value) !== '[]');
        if (user) {
            user.groups.forEach((val) => {
                groupName.push(val.groupName);
            })
        }
        let PlatformVersion = Versions.DiapasonVersion;
        let CondenserProductVersion = Versions.ProductBuilderDetails.Condensers;
        let EvaporatorProductVersion = Versions.ProductBuilderDetails.Evaporator;
        let DryCoolerProductVersion = Versions.ProductBuilderDetails.DryCooler;
        let BrineCoolerProductVersion = Versions.ProductBuilderDetails.BrineAirCooler;
        let GasCoolerProductVersion = Versions.ProductBuilderDetails.GasCooler;
        let RackProductVersion = Versions.ProductBuilderDetails.Racks;
        let ElectricPanelVersion = Versions.ProductBuilderDetails.ElectricPanel
        let CDUVersion = Versions.ProductBuilderDetails.CDU

        const payload = {
            emailTemplate,
            images: [],
            agreeToShareImage: false,
            hasAttachement: false,
            rating: 0,
            categorySelection: newArray.toString(),
            isBug: false,
            isIdea: false,
            comment: textareaselect,
            UserGroupName: groupName.toString(),
            PlatformVersion,
            CondenserProductVersion,
            EvaporatorProductVersion,
            DryCoolerProductVersion,
            BrineCoolerProductVersion,
            GasCoolerProductVersion,
            RackProductVersion,
            ElectricPanelVersion,
            CDUVersion
        }

        dispatch(postFeedback(payload)).then(() => {
            enqueueSnackbar(injectIntlTranslation(intl, "SubmitSuccessfully"), { variant: 'success' });
        }).finally(() => {
            handleClose();
        })
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={supportClick}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-Supporttitle"
                aria-describedby="scroll-dialog-Supportdescription"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle className={classes.dialogTitle} id="scroll-dialog-Supporttitle" onClose={handleClose}>
                    <div className={classes.dialogHeader}>
                        <div className={classes.dialogHeaderText}>{translation("Support")}</div>
                        <div><IconButton id="support-close-icon" className={classes.closeButton} onClick={handleClose}><CloseRoundedIcon /></IconButton></div>
                    </div>
                </DialogTitle>

                <DialogContent id="scroll-dialog-Supportdescription" className={classes.dialogContent} dividers>
                    {/* <DialogContentText
                        id="scroll-dialog-Supportdescription"
                        tabIndex={-1}
                        className={classes.dialogContent}
                    > */}
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend" className={classes.title}>{translation("Whatcanwehelpyouwith")} ?</FormLabel>
                        <FormGroup className={classes.margin}>
                            <FormControlLabel
                                disabled={loader}
                                control={<Checkbox checked={GetChecked("GeneralQuestion")} onChange={(e) => handleChangeSupport(e, "generalquestion")} name="generalquestion" color="secondary" value="GeneralQuestion" />}
                                label={<Typography className={classes.label}>{translation("Generalquestion")}</Typography>}
                            />
                            <FormControlLabel
                                disabled={loader}
                                control={<Checkbox checked={GetChecked("Feature")} onChange={(e) => handleChangeSupport(e, "feature")} name="feature" color="secondary" value="Feature" />}
                                label={<Typography className={classes.label}>{translation("Feature")}</Typography>}
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend" className={classes.title}></FormLabel>
                        <FormGroup className={classes.marginnolabel}>
                            <FormControlLabel
                                disabled={loader}
                                control={<Checkbox checked={GetChecked("Account")} onChange={(e) => handleChangeSupport(e, "account")} name="account" color="secondary" value="Account" />}
                                label={<Typography className={classes.label}>{translation("Account")}</Typography>}
                            />
                            <FormControlLabel
                                disabled={loader}
                                control={<Checkbox checked={GetChecked("Other")} onChange={(e) => handleChangeSupport(e, "other")} name="other" color="secondary" value="Other" />}
                                label={<Typography className={classes.label}>{translation("Other")}</Typography>}
                            />
                        </FormGroup>
                    </FormControl>
                    <div className={classes.formControl}>
                        <p className={classes.title}>{translation("Yourquestion")} :</p>
                        <textarea disabled={loader} name="message" id="txtareaYourquestion" rows="5" cols="10" maxLength="1000" className={classes.textareaBox} onChange={handleTextareaChange} value={textareaselect}></textarea>
                    </div>
                    {/* </DialogContentText> */}
                </DialogContent>
                <DialogActions>

                    <Button
                        id="support-close-btn"
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                        startIcon={<CloseRoundedIcon />}
                        disableElevation
                        disabled={loader}
                    >
                        {injectIntlTranslation(intl, "ButtonTextClose", "Close")}
                    </Button>
                    <Button
                        id="support-send-btn"
                        variant="contained"
                        color="secondary"
                        className={classes.supportbutton}
                        startIcon={!loader && <SendRoundedIcon />}
                        onClick={supportOnClick}
                        disabled={(loader || !generalquestionselect.length) && !featureselect.length && !accountselect.length && !otherSupportselect.length}
                    >
                        {loader ? (
                            <>
                                <CircularProgress size={20} color="inherit" />
                                &nbsp;&nbsp;{translation("SubmittingBtn")}
                            </>
                        ) : (
                            translation("SubmitBtn")
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default injectIntl(Support);