import { combineReducers } from 'redux';
import cduResponseReducer from './cduResponseReducer';
import cduSelectionObjReducer from './cduSelectionObjReducer';
import cduGlobalReducer from './cduGlobalReducer';

const cduReducer = combineReducers({
    cduResponse: cduResponseReducer,
    cduSelectionObj: cduSelectionObjReducer,
    cduGlobalSelectionObj: cduGlobalReducer
});

export default cduReducer;