import {
    FETCH_COMMON_MODEL_START,
    FETCH_COMMON_MODEL_FULFILLED,
    FETCH_COMMON_MODEL_FAILED,
    FETCH_COMMON_MODEL_VALUE,
    FETCH_COMMON_MODEL_REFRESH,
    RESET_COMMON_MODEL_RECORDS,
    FETCH_COMMON_MODEL_STOP,
} from '../constants/constant';

const initialState = {
    commonModelRecords: [],
    isLoading: false,
    needRefresh: false,
    error: '',
    isSuccess: false,
    isFailed: false,
};
const getCommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMMON_MODEL_START:
            return {
                ...state,
                needRefresh: false,
                isLoading: true
            }
        case FETCH_COMMON_MODEL_STOP:
            return {
                ...state,
                isLoading: false
            }
        case FETCH_COMMON_MODEL_FULFILLED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                isTurn: action.isTurn,
                commonModelRecords: action.commonmodeldata,
                isFailed: false,
            }
        case FETCH_COMMON_MODEL_VALUE:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                isTurn: action.isTurn,
                isSuccess: true
            }
        case FETCH_COMMON_MODEL_FAILED:
            return {
                ...state,
                isLoading: true,
                needRefresh: false,
                error: action.commonmodeldata,
                isFailed: true,
            }
        case FETCH_COMMON_MODEL_REFRESH:
            return {
                ...state,
                needRefresh: false,
                isLoading: true,
                commonModelRecords: action.commonmodeldata,
                isSuccess: false,
                isFailed: false,
            }
        case RESET_COMMON_MODEL_RECORDS:
            return { ...JSON.parse(JSON.stringify(initialState)) }
        // return {
        //     ...state,
        //     commonModelRecords: [],
        //     isFailed: false,
        // }

        default:
            return state;
    }
};

export default getCommonReducer