import { combineReducers } from 'redux';
import gascoolerResponseReducer from './gascoolerResponseReducer';
import gascoolerSelectionObjReducer from './gascoolerSelectionObjReducer';
import gascoolerGlobalReducer from './gascoolerGlobalReducer';

const gascoolerReducer = combineReducers({
    gascoolerResponse: gascoolerResponseReducer,
    gascoolerSelectionObj: gascoolerSelectionObjReducer,
    gascoolerGlobalSelectionObj: gascoolerGlobalReducer,
});

export default gascoolerReducer;