
import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";
const initialState = {
    NodesInfo: [],
    PipeLineInfo: [],
    PipeLineSequenceOrder: [],
    successResponse: false
};

export default function pipeLinesDataReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.GET_PIPELINES_RESPONSE:
            return {
                ...state,
                NodesInfo: action.payload.NodesInfo,
                PipeLineInfo: action.payload.PipeLineInfo,
                PipeLineSequenceOrder: action.payload.PipeLineSequenceOrder
            }
        case actionConstants.RESET_PIPELINES_RESPONSE: {
            return { ...initialState }
        }
        case actionConstants.UPDATE_PIPELINES_RESPONSE_STATE: {
            return {
                ...state,
                successResponse: action.payload
            }
        }
        default:
            return state;
    }
}