import { combineReducers } from 'redux';
import brinecoolerResponseReducer from './brinecoolerResponseReducer';
import brinecoolerSelectionObjReducer from './brinecoolerSelectionObjReducer';
import brinecoolerGlobalReducer from './brinecoolerGlobalReducer';

const brinecoolerReducer = combineReducers({
    brinecoolerResponse: brinecoolerResponseReducer,
    brinecoolerSelectionObj: brinecoolerSelectionObjReducer,
    brinecoolerGlobalSelectionObj: brinecoolerGlobalReducer,
});

export default brinecoolerReducer;