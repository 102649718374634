import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    Circuits: {},
    // Cascade: {},
    // Circuit: {},
    // LTSystem: {},
    LiquidReceiver: {},
    // MTSystem: {},
    SystemTypes: {},
    tagTemplates: [],
    conceptTemplates: [],
    Inclusions: [],
    Visibility: [],
    DefaultSelection: []
};

export default function refregirationsupplyResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_RESPONSE_REFREGIRATIONSUPPLY:
            return {
                ...state,
                Circuits: action.payload.Circuits,
                // Cascade: action.payload.Circuits.Cascade,
                // Circuit: action.payload.Circuits.Circuit,
                // LTSystem: action.payload.SystemTypes.LTSystem,
                LiquidReceiver: action.payload.LiquidReceiver,
                // MTSystem: action.payload.SystemTypes.MTSystem,
                SystemTypes: action.payload.SystemTypes,
                Inclusions: action.payload.Inclusions,
                Visibility: action.payload.Visibility,
                DefaultSelection: action.payload.DefaultSelection,
            }
        case actionConstants.UPDATE_TAG_TEMPLATES_REFREGIRATIONSUPPLY:
            return {
                ...state,
                tagTemplates: action.payload
            }
        case actionConstants.RESET_REFREGIRATIONSUPPLY_RESPONSE:
            return { ...initialState }

        default:
            return state;
    }
}