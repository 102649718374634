import React, { useEffect, useState } from 'react';
import './header.scss';
import Logo from './../../../assets/images/CoolSystemsSelect_New.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { UserPerference } from '../../../redux/Actions/userPerference';
import { LangOpt, getLanguageDetails } from '../../../utilities/languagesutils';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from "react-intl";
import { enqueueSnackbar } from 'notistack';

import { injectIntlTranslation, getProjectList, sortingOrder, projectListColumn, projectType } from '@ccr-main/diapason-sharedlibrary-ui';
import SnackbarNotification from '../../Notifications/SnackbarNotification';
import CircularLoader from '../../Loaders/CircularLoader';
import PropagateLoader from '../../Loaders/PropagateLoader';
import { updateAdminPagePermission } from '../../../redux/Actions/dataManagementActions';

import ProfileDropdown from './profileDropdown';
import BreadcrumbsNavigation from '../controls/BreadcrumbsNavigation/BreadcrumbsNavigation';
import { updateProjectGridState } from '../../../redux/Actions/updateProjectListState';
import { OPEN_OPPORTUNITY_ID } from '../../../redux/constants/constant';

const Header = (props) => {

    const { appPermissionsWithRoles, isEcatMigrated, intl } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const langName = useSelector(state => state.locale.name);
    const locale = useSelector(state => state.locale);
    // const projectInfo = useSelector(state => state.getProjectList)
    const { permissions, fullName, oldEmailAddress } = useSelector(state => state.userProfile);

    const [lang, setLang] = useState();
    const [showAdminPage, setShowAdminPage] = useState(false);

    const diapasonrole = locale.diapasonrole;

    useEffect(() => {
        isEcatMigrated(oldEmailAddress);
        if (permissions.length) {
            appPermissionsWithRoles(locale.role, permissions);
            const hasAdminPagePermission = permissions.filter((x) => x.settingName === "Admin Page");
            setShowAdminPage(hasAdminPagePermission.length ? true : false);
            dispatch(updateAdminPagePermission(hasAdminPagePermission.length ? true : false));
        }
        else {
            appPermissionsWithRoles([], []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLang(getLanguageDetails(langName));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [langName])

    const homeClick = () => {
        dispatch(updateProjectGridState({
            searchText: "",
            searchColumn: "",
            page: 0,
            rowsPerPage: 10,
            order: sortingOrder.descending,
            orderBy: projectListColumn.LastModifiedDate,
            selectedFilter: "DisplayAll",
            opportunityStageId: OPEN_OPPORTUNITY_ID,
            projectType: projectType.AllProjects,
        }));
        dispatch(getProjectList(
            1,
            10,
            `${projectListColumn.LastModifiedDate}_${sortingOrder.descending}`,
            "",
            "",
            projectType.AllProjects,
            OPEN_OPPORTUNITY_ID
        ));
        history.push(`/`);
    }

    const succesCallback = () => {
        enqueueSnackbar(injectIntlTranslation(intl, "SettingsSaveSuccessMessage"), { variant: 'success' });
    }

    const failureCallback = () => {
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }

    const handleChangeLang = (opt) => {
        let unit = (locale.unit === "English") ? "English" : "Metric";
        dispatch(UserPerference({
            opt: opt,
            unit: unit,
            role: locale.role,
            succesCallback: succesCallback,
            failureCallback: failureCallback,
            diapasonrole: diapasonrole
        }))
        setLang(opt);
    }

    return (
        <>
            <header className='header'>
                <div className="container">
                    <div className="header-wrapper">

                        <div className="brand-logo">
                            <Link to='/' onClick={homeClick}><img src={Logo} alt="Cool Systems Select" /></Link>
                        </div>

                        <div className='separator'>
                            |
                        </div>

                        <BreadcrumbsNavigation />

                        <nav className="dropdown-right" style={{ marginLeft: "auto", marginRight: "20px" }}>
                            <ProfileDropdown
                                fullName={fullName}
                                selectedLanguage={lang}
                                languageOptions={LangOpt}
                                showAdminPage={showAdminPage}
                                handleChangeLang={handleChangeLang}
                            />
                        </nav>

                    </div>
                </div>
            </header>
            <div className="container"><SnackbarNotification /></div>
            <div className="container"><CircularLoader /></div>
            <div className="container"><PropagateLoader /></div>
        </>
    )
}

Header.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(withRouter(Header));
