import { actionConstants } from '@ccr-main/diapason-sharedlibrary-ui';

const initialState = {
    locationState: {},
    navigationData: {}
};

export default function breadcrumbsNavigationReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.LOCATION_STATE_UPDATE:
            return {
                ...state,
                locationState: action.payload
            }
        case actionConstants.NAVIGATION_DATA_UPDATE:
            return {
                ...state,
                navigationData: action.payload
            }
        default:
            return state;
    }
}
