import CryptoJS from 'crypto-js';
import appConfig from '../../Environment/environments';
import { API } from "../../redux/constants/apiContants";
import { ApiService } from "@ccr-main/diapason-sharedlibrary-ui"

export const encryptData = (data) => {
    let key = CryptoJS.enc.Utf8.parse(appConfig.api.encryption.Key);
    let iv = {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(appConfig.api.encryption.IV),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };

    return encodeURIComponent(CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, iv));
}

export const TagEditDetails = (ids) => {
    return new Promise((resolve, reject) => {
        ApiService(`${appConfig.api.cssMicroService}${API.GET_TAG_RESULT_DETAILS}`, 'POST',ids).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error)
        });
    })
}

export const getBrowserInfo = () => {
    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) browserName = "Chrome";
    else if (userAgent.match(/firefox|fxios/i)) browserName = "Firefox";
    else if (userAgent.match(/safari/i)) browserName = "Safari";
    else if (userAgent.match(/opr\//i)) browserName = "Opera";
    else if (userAgent.match(/edg/i)) browserName = "Edge";
    else browserName="No browser detection";
    
    return ({
      timeOpened: new Date(),
      timezone: new Date().getTimezoneOffset() / 60,
      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: window.history.length,
      applicationName: browserName,
      browserName: window.navigator.appName,
      browserEngine: window.navigator.product,
      browserVersion1a: window.navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      connectionType: window.navigator.connection.effectiveType,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies1: document.cookie,
      dataCookies2: decodeURIComponent(document.cookie.split(';')),
      dataStorage: localStorage,
      sizeScreenW: window.screen.width,
      sizeScreenH: window.screen.height,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      sizeAvailW: window.screen.availWidth,
      sizeAvailH: window.screen.availHeight,
      scrColorDepth: window.screen.colorDepth,
      scrPixelDepth: window.screen.pixelDepth,
    })
}
