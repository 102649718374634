import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { enqueueSnackbar } from 'notistack';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Checkbox,
    FormGroup,
    FormControlLabel,
    CircularProgress,
    IconButton
} from "@mui/material";
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import SentimentVeryDissatisfiedRoundedIcon from '@mui/icons-material/SentimentVeryDissatisfiedRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SentimentNeutralRoundedIcon from '@mui/icons-material/SentimentNeutralRounded';
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import { makeStyles } from "@mui/styles";
import {
    translation,
    injectIntlTranslation,
} from "@ccr-main/diapason-sharedlibrary-ui";

import { postFeedback } from "../../../../redux/Actions/AssistanceActions";
import Versions from "../../../../utilities/versions";
import "./FeedbackAndReport.scss";

const useFeedBackStyles = makeStyles((theme) => ({
    // button: {
    //   margin: "10px 20px 10px 10px !important",
    //   height: "38px !important",
    //   background: "#263B7A !important",
    //   color: "#ffffff !important",
    //   borderRadius: "4px !important",
    //   // padding: "6px 16px !important",
    //   "&.Mui-disabled": {
    //     background: "rgba(0, 0, 0, 0.12) !important",
    //   },
    // },
    dialogTitle: {
        padding: "5px 10px !important",
        backgroundColor: "lightgray"
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: "space-between"
    },
    dialogHeaderText: {
        paddingTop: "4px"
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: "15px 15px !important",
    },
    dialogActions: {
        padding: "5px 10px !important",
    }
}));

const PRODUCT = "Product";
const CALCULATION = "Calculation";
const CONTENT = "Content";
const USERINTERFACE = "UserInterface";
const USABILITY = "Usability";
const OTHER = "Other";

const BUG = "Bug";
const IDEA = "Idea";
const VERY_DISSATISFIED = "Very Dissatisfied";
const DISSATISFIED = "Dissatisfied";
const NEUTRAL = "Neutral";
const SATISFIED = "Satisfied";
const VERY_SATISFIED = "Very Satisfied";

const emojiesIcons = [
    {
        id: "VERY_DISSATISFIED",
        type: VERY_DISSATISFIED,
        text: "VeryDissatisfied",
        emojiComponent: SentimentVeryDissatisfiedRoundedIcon,
    },
    {
        id: "DISSATISFIED",
        type: DISSATISFIED,
        text: "Dissatisfied",
        emojiComponent: SentimentDissatisfiedRoundedIcon,
    },
    {
        id: "NEUTRAL",
        type: NEUTRAL,
        text: "Neutral",
        emojiComponent: SentimentNeutralRoundedIcon,
    },
    {
        id: "SATISFIED",
        type: SATISFIED,
        text: "Satisfied",
        emojiComponent: SentimentSatisfiedRoundedIcon,
    },
    {
        id: "VERY_SATISFIED",
        type: VERY_SATISFIED,
        text: "VerySatisfied",
        emojiComponent: SentimentVerySatisfiedRoundedIcon,
    },
];

const FeedbackAndReport = ({ isFeedbackAndReportOpen, handleFeedbackAndReportClick, intl }) => {
    const classes = useFeedBackStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.userProfile);
    const inputFileRef = useRef(null);
    const [feedbackType, setFeedbackType] = useState(NEUTRAL);
    const [comments, setComments] = useState("");

    const [checkboxGroup, setCheckboxGroup] = useState([
        { name: PRODUCT, value: PRODUCT, isChecked: false },
        { name: CALCULATION, value: CALCULATION, isChecked: false },
        { name: CONTENT, value: CONTENT, isChecked: false },
        { name: USERINTERFACE, value: USERINTERFACE, isChecked: false },
        { name: USABILITY, value: USABILITY, isChecked: false },
        { name: OTHER, value: OTHER, isChecked: false },
    ]);

    const [images, setImages] = useState([]);
    const [imagesFileName, setImagesFileName] = useState([]);
    const [loader, setLoader] = useState(false);

    const handleClose = () => {
        handleFeedbackAndReportClick(false);
        setFeedbackType(NEUTRAL);
        setCheckboxGroup([
            { name: PRODUCT, value: PRODUCT, isChecked: false },
            { name: CALCULATION, value: CALCULATION, isChecked: false },
            { name: CONTENT, value: CONTENT, isChecked: false },
            { name: USERINTERFACE, value: USERINTERFACE, isChecked: false },
            { name: USABILITY, value: USABILITY, isChecked: false },
            { name: OTHER, value: OTHER, isChecked: false },
        ]);
        setComments("");
        setImages([]);
        setImagesFileName([]);
        setLoader(false);
    };

    const handleCheckboxSelections = (event, value) => {
        let checkboxGroupTemp = [...checkboxGroup];
        checkboxGroupTemp.forEach((item) => {
            if (item.value === value) item.isChecked = event.target.checked;
        });
        setCheckboxGroup(checkboxGroupTemp);
    };

    const handleFeelingSelection = (event, feelingType) => {
        setFeedbackType(feelingType);
    };

    const AddScreenShot = () => {
        inputFileRef.current.click();
    };

    const fileToDataUri = (image) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result.split(";base64,")[1]);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(image);
        });
    };

    const handleFileRead = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const newImagesPromises = [];
            const imageFileNameArr = [];
            const imageTypearr = [];
            for (let i = 0; i < e.target.files.length; i++) {
                if (!e.target.files[i].name.match(/\.(jpg|JPG|jpeg|JPEG)$/)) {
                    enqueueSnackbar(injectIntlTranslation(intl, "FileNotSupported", "Only files with the following extensions are allowed") + ": jpg, jpeg.", { variant: 'error' });
                    return false;
                } else {
                    newImagesPromises.push(fileToDataUri(e.target.files[i]));
                    imageFileNameArr.push(e.target.files[i].name);
                    imageTypearr.push(e.target.files[i].type);
                    setImagesFileName(imageFileNameArr);
                }
            }
            const newImages = await Promise.all(newImagesPromises);

            if (newImages && newImages.length) {
                let ImagearrObj = newImages.map((value, index) => {
                    return ({
                        content: value,
                        disposition: "attachment",
                        filename: imageFileNameArr[index],
                        type: imageTypearr[index],
                        contentId: imageFileNameArr[index],
                    });
                });
                setImages(ImagearrObj);
            }
        }
    };

    const disableSubmitButton = (feedbackType, comments) => {
        let disable = true;

        const anyCheckboxChecked = checkboxGroup.some((item) => item.isChecked);

        if (anyCheckboxChecked) {
            if (
                (feedbackType === BUG ||
                    feedbackType === IDEA ||
                    feedbackType === VERY_DISSATISFIED ||
                    feedbackType === DISSATISFIED) &&
                comments.toString() !== ""
            ) {
                disable = false;
            }
            if (
                feedbackType === NEUTRAL ||
                feedbackType === SATISFIED ||
                feedbackType === VERY_SATISFIED
            ) {
                disable = false;
            }
        } else {
            disable = true;
        }
        return disable;
    };

    const handleSubmit = () => {
        setLoader(true);
        let emailTemplate = "Feedback";
        let hasAttachement = Boolean(images.length);

        let selectedFeedbackType;
        let isBug, isIdea;

        switch (feedbackType) {
            case BUG:
                selectedFeedbackType = 0;
                isBug = true;
                break;
            case IDEA:
                selectedFeedbackType = 0;
                isIdea = true;
                break;
            case VERY_DISSATISFIED:
                selectedFeedbackType = 1;
                break;
            case DISSATISFIED:
                selectedFeedbackType = 2;
                break;
            case NEUTRAL:
                selectedFeedbackType = 3;
                break;
            case SATISFIED:
                selectedFeedbackType = 4;
                break;
            case VERY_SATISFIED:
                selectedFeedbackType = 5;
                break;
            default:
                selectedFeedbackType = 3;
                break;
        }

        const categorySelection = checkboxGroup.reduce(
            (acc, item) => (item.isChecked ? [...acc, item.value] : acc),
            []
        );

        const groupName = user && user.groups && user.groups.length
            ? user.groups.map((val) => val.groupName)
            : [];

        const payload = {
            emailTemplate,
            images,
            agreeToShareImage: false,
            hasAttachement,
            rating: selectedFeedbackType,
            categorySelection: categorySelection.join(),
            isBug,
            isIdea,
            comment: comments,
            UserGroupName: groupName.toString(),
            PlatformVersion: Versions.DiapasonVersion,
            CondenserProductVersion: Versions.ProductBuilderDetails.Condensers,
            EvaporatorProductVersion: Versions.ProductBuilderDetails.Evaporator,
            DryCoolerProductVersion: Versions.ProductBuilderDetails.DryCooler,
            BrineCoolerProductVersion: Versions.ProductBuilderDetails.BrineAirCooler,
            GasCoolerProductVersion: Versions.ProductBuilderDetails.GasCooler,
            RackProductVersion: Versions.ProductBuilderDetails.Racks,
            ElectricPanelVersion: Versions.ProductBuilderDetails.ElectricPanel,
            CDUVersion: Versions.ProductBuilderDetails.CDU
        }

        dispatch(postFeedback(payload)).then(() => {
            enqueueSnackbar(injectIntlTranslation(intl, "SubmitSuccessfully"), { variant: 'success' });
        }).finally(() => {
            handleClose();
        })
    };

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={false}
            open={isFeedbackAndReportOpen}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-FeedBacktitle"
            aria-describedby="scroll-dialog-FeedBackdescription"
        >

            <DialogTitle className={classes.dialogTitle} id="scroll-dialog-FeedBacktitle" onClose={handleClose}>
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogHeaderText}>{translation("Sendusyourfeedback")}</div>
                    <div><IconButton id="feedback-close-icon" className={classes.closeButton} onClick={handleClose}><CloseRoundedIcon /></IconButton></div>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent} dividers>
                <div>
                    <span className="bold-font">{translation("ShareWithUs")} ?</span>
                    <div className="feedback-group">
                        <div className="single-icon">
                            <div className="bold-font">{translation("Bug")}</div>
                            <div className="divider">
                                <div className="tooltip icon-hover">
                                    <BugReportRoundedIcon
                                        id="Feedback_Bug"
                                        onClick={(event) => {
                                            !loader && handleFeelingSelection(event, BUG);
                                        }}
                                        className={
                                            feedbackType === BUG
                                                ? "selected-button icon-hover icon-size"
                                                : "button-black icon-hover icon-size"
                                        }
                                    />
                                    <span className="tooltiptext">{translation("Bug")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="single-icon">
                            <div className="bold-font">{translation("Idea")}</div>
                            <div className="divider">
                                <div className="tooltip">
                                    <EmojiObjectsRoundedIcon
                                        id="Feedback_Idea"
                                        onClick={(event) => {
                                            !loader && handleFeelingSelection(event, IDEA);
                                        }}
                                        className={
                                            feedbackType === IDEA
                                                ? "selected-button icon-hover icon-size"
                                                : "button-black icon-hover icon-size"
                                        }
                                    />
                                    <span className="tooltiptext">{translation("Idea")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="multiple-icons">
                            <div className="bold-font">{translation("Feeling")}</div>
                            <div>
                                {emojiesIcons.map((emoj) => {
                                    const IconComponent = emoj.emojiComponent;
                                    return (
                                        <div className="tooltip" key={emoj.id}>
                                            <IconComponent
                                                id={"Feedback_Feeling_ID_" + emoj.id}
                                                onClick={(event) =>
                                                    !loader && handleFeelingSelection(event, emoj.type)
                                                }
                                                className={
                                                    feedbackType === emoj.type
                                                        ? "selected-button icon-hover icon-size feeling-width"
                                                        : "button-black icon-hover icon-size feeling-width"
                                                }
                                            />
                                            <span className="tooltiptext">
                                                {translation(emoj.text)}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <span className="bold-font">
                        {translation("Whatdoesthisfeelisrelateto")} ?
                    </span>

                    <div className="checkbox-group">
                        <FormGroup row>
                            {checkboxGroup.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        id={"chk" + item.name}
                                        key={item.name}
                                        disabled={loader}
                                        control={
                                            <Checkbox
                                                name={item.name}
                                                color="secondary"
                                                value={item.value}
                                                checked={item.isChecked}
                                                onChange={(e) =>
                                                    handleCheckboxSelections(e, item.value)
                                                }
                                            />
                                        }
                                        label={translation(item.value)}
                                        labelPlacement="end"
                                        classes={{
                                            root:
                                                (index + 1) % 3 === 0
                                                    ? "checkbox-width-end"
                                                    : "checkbox-width",
                                        }}
                                    />
                                );
                            })}
                        </FormGroup>
                    </div>
                </div>

                <div>
                    <span className="bold-font">
                        {translation("WouldYouAddScreenshot")} ?
                    </span>

                    <div className="screenshot-group">
                        <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            disabled={loader}
                            ref={inputFileRef}
                            onChange={handleFileRead}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                id="feedback-add-screenshot"
                                variant="contained"
                                color="secondary"
                                className="photo-button"
                                disabled={loader}
                                startIcon={<AddAPhotoRoundedIcon />}
                                onClick={AddScreenShot}
                            >
                                {translation("Addscreenshot")}
                            </Button>
                        </label>
                        {images.length > 0 && (
                            <label className="filename">{imagesFileName.toString()}</label>
                        )}
                    </div>
                </div>

                <div>
                    <div className="bold-font">{injectIntlTranslation(intl, "AddComment", "Would you like to add a comment")} ?</div>
                    <div className="comments-group">
                        <textarea
                            disabled={loader}
                            name="message"
                            rows="5"
                            cols="69"
                            maxLength="1000"
                            placeholder={injectIntlTranslation(intl, "SuggestionPlaceholder", "Any suggestions are really welcome...")}
                            onChange={handleCommentsChange}
                            value={comments}
                        ></textarea>
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    id="feedback-close-btn"
                    variant="outlined"
                    color="secondary"
                    className="cancelbutton"
                    startIcon={<CloseRoundedIcon />}
                    disabled={loader}
                    onClick={handleClose}
                >
                    {injectIntlTranslation(intl, "ButtonTextClose", "Close")}
                </Button>
                <Button
                    id="feedback-send-btn"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={!loader && <SendRoundedIcon />}
                    disabled={loader || disableSubmitButton(feedbackType, comments)}
                    onClick={handleSubmit}
                >
                    {loader ? (
                        <>
                            <CircularProgress size={20} color="inherit" />
                            &nbsp;&nbsp;{translation("SubmittingBtn")}
                        </>
                    ) : (
                        translation("SubmitBtn")
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default injectIntl(FeedbackAndReport);
