import { combineReducers } from 'redux';
import condenserResponseReducer from './condenserResponseReducer';
import condenserSelectionObjReducer from './condenserSelectionObjReducer';
import condenserGlobalReducer from './condenserGlobalReducer';

const condenserReducer = combineReducers({
    condenserResponse: condenserResponseReducer,
    condenserSelectionObj: condenserSelectionObjReducer,
    condenserGlobalSelectionObj: condenserGlobalReducer,
});

export default condenserReducer;