import { actionConstants } from '@ccr-main/diapason-sharedlibrary-ui';

const initialState = {
  records: [],
  isLoading: false,
  error: ''
};

export function getTagTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case actionConstants.GET_TAG_TEMPLATE_START:
      return {
        ...state,
        isLoading: false
      }
    case actionConstants.GET_TAG_TEMPLATE_FULFILLED:
      return {
        ...state,
        isLoading: true,
        records: action.data,
      }
    case actionConstants.GET_TAG_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: true,
        error: action.data
      }
    default:
      return state;
  }
}

export function updateTagTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case actionConstants.UPDATE_TAG_TEMPLATE_START:
      return {
        ...state,
        isLoading: false
      }
    case actionConstants.UPDATE_TAG_TEMPLATE_FULFILLED:
      return {
        ...state,
        isLoading: true,
        records: action.data,
      }
    case actionConstants.UPDATE_TAG_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: true,
        error: action.data
      }
    default:
      return state;
  }
}


export function deleteTagTemplateReducer(state = initialState, action) {
  if (action.type === actionConstants.DELETE_TAGTEMPLATE_FULFILLED) {
    return {
      ...state,
      isLoading: false,
      records: action.data
    }
  }
  else {
    return state;
  }
}

export function saveTagTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case actionConstants.SAVE_TAG_TEMPLATE_START:
      return {
        ...state,
        isLoading: false
      }
    case actionConstants.SAVE_TAG_TEMPLATE_FULFILLED:
      return {
        ...state,
        isLoading: true,
        records: action.data,
      }
    case actionConstants.SAVE_TAG_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: true,
        error: action.data
      }
    default:
      return state;
  }
}
