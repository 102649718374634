import axios from 'axios';
import { API } from '../constants/apiContants';
import { enqueueSnackbar } from 'notistack';

import appConfig from '../../Environment/environments';
import { GET, POST, STATUS_OK, DM_UPDATE_TABLES_LIST, DM_SELECTED_TABLE_NAME, DM_UPDATE_PRODUCT_DROPDOWN_LIST, SHOW_PUSH_TO_PROD_BTN, DM_MODAL_STATE, DM_MODAL_TITLE, DM_MODAL_CONTENT, DM_MODAL_TYPE, DM_ADMIN_PAGE_PERMISSION, DM_TABLE_VERSION_HISTORY, DM_REQUEST_VERSION_DETAILS } from '../constants/constant';
import { ApiService, injectIntlTranslation, showCircularLoader, hideCircularLoader, showPropagateLoader, hidePropagateLoader } from "@ccr-main/diapason-sharedlibrary-ui";
import { showProgressBar, progressBarOperationName, updateProgressPercentage } from '../Actions/progressBarActions';

export const getMasterDataTables = (type, typeId, intl) => async (dispatch) => {
    let url = ""
    try {
        dispatch(showPropagateLoader());
        if (type === "Report Data") {
            url = `${appConfig.api.commonModelServices}${API.GET_REPORT_EXPORT_TABLELIST}`
        }
        else {
            url = `${appConfig.api.commonModelServices}${API.GET_MASTER_DATATABLES}?typeId=${typeId}`

        }

        const response = await ApiService(url, GET)
        let parsedTablesList = JSON.parse(response.data)
        dispatch({ type: DM_UPDATE_TABLES_LIST, payload: parsedTablesList });
        dispatch(hidePropagateLoader());
        dispatch(updateSelectedTableName(parsedTablesList[0].MasterTables[0].TableName))
    }
    catch (error) {
        dispatch(hidePropagateLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}

export const getProductDropDownItems = (intl) => async (dispatch) => {
    try {
        dispatch(showPropagateLoader());
        const url = `${appConfig.api.commonModelServices}${API.GET_MASTER_TABLEDATA}`
        const response = await ApiService(url, GET)
        dispatch(hidePropagateLoader());
        dispatch(updateProductDropdownList(response.data));
    }
    catch (error) {
        dispatch(hidePropagateLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}

export const getHeadersForDM = () => {
    const token = localStorage.getItem("msal.idtoken");
    const res = {
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*'
    }
    if (token) {
        res['Authorization'] = `Bearer ${token}`
    }
    return res
}

export const ApiServiceDM = (path, method = 'get', payload = null, type = null, timeout = 0, params = null, onUploadProgress = null, onDownloadProgress = null) => {
    const headers = getHeadersForDM();
    return axios({
        method: method,
        url: `${path}`,
        headers,
        data: payload,
        responseType: type,
        timeout: timeout,
        params: params,
        onUploadProgress: onUploadProgress,
        onDownloadProgress: onDownloadProgress
    })
}



export const uploadTableData = (tableName, file, intl, description) => {

    return async function action(dispatch) {
        const onUploadProgress = (progressEvent) => {
            let elem = document.getElementById("dmProgressBar");
            let width = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
            elem.style.width = width + "%";
            dispatch(updateProgressPercentage(width));
        }

        dispatch(showCircularLoader());
        dispatch(showProgressBar(true));
        dispatch(progressBarOperationName(injectIntlTranslation(intl, "Uploaded", "Uploaded")));

        try {
            let formData = new FormData();
            formData.append("File", file);
            formData.append("Description", description);
            const url = `${appConfig.api.commonModelServices}${API.UPLOAD_TABLEDATA_DETAILS}${tableName}`
            const { data } = await ApiService(url, POST, formData, null, 0, null, onUploadProgress)

            dispatch(hideCircularLoader());
            dispatch(showProgressBar(false));
            data && data.succeeded
                ? enqueueSnackbar(injectIntlTranslation(intl, data.messages[0], data.messages[0]), { variant: 'success' })
                : enqueueSnackbar(injectIntlTranslation(intl, data.messages[0]), { variant: 'error' })
            dispatch(updateProgressPercentage(0));
        }
        catch (error) {
            dispatch(hideCircularLoader());
            dispatch(showProgressBar(false));
            enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
        }
    }
}

export const downloadTableData = (tableName, isButtonVisible, intl) => {

    return async function action(dispatch) {
        const onDownloadProgress = (progressEvent) => {
            let elem = document.getElementById("dmProgressBar");
            let width = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
            elem.style.width = width + "%";
            dispatch(updateProgressPercentage(width));
        }

        dispatch(showCircularLoader());
        dispatch(showProgressBar(true));
        dispatch(progressBarOperationName(injectIntlTranslation(intl, "Downloaded", "Downloaded")));
        let fileName = tableName + ".xlsx"
        let url = ""

        try {
            if (!isButtonVisible) {
                url = `${appConfig.api.commonModelServices}${API.DOWNLOAD_TABLE_REPORTDATA}${tableName}&exportToCsv=false`
            }
            else {
                url = `${appConfig.api.commonModelServices}${API.DOWNLOAD_TABLEDATA_DETAILS}${tableName}`
            }


            const { data } = await ApiServiceDM(url, GET, null, 'blob', 0, null, null, onDownloadProgress)

            let bytes = data;
            let objUrl = "";
            const blobdata = new Blob([bytes]);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blobdata, fileName);
            } else {
                objUrl = URL.createObjectURL(blobdata);
            }
            const link = document.createElement('a');
            link.href = objUrl;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();

            dispatch(showProgressBar(false));
            dispatch(hideCircularLoader());
            enqueueSnackbar(injectIntlTranslation(intl, "MSG_DOWNLOAD_SUCCESS", "File downloaded successfully."), { variant: 'success' });
            dispatch(updateProgressPercentage(0));

        }
        catch (error) {
            dispatch(hideCircularLoader());
            dispatch(showProgressBar(false));
            error && error.data && error.data.messages
                ? enqueueSnackbar(injectIntlTranslation(intl, error.data.messages[0]), { variant: 'error' })
                : enqueueSnackbar(injectIntlTranslation(intl, "MSG_DOWNLOAD_ERROR"), { variant: 'error' });
        }
    }
}

export const downloadHistoryNew = (data, intl) => {
    // const url = `${appConfig.api.commonModelServices}/api/admin/DownloadVersion?pushRequestId=${data.id}&pushRequestDetailId=${data&&data.pushRequestDetailId}`
    // const response = await ApiService(url, "GET");
    // let fileName = data&&data.tableName + ".xlsx"
    // let bytes = response?.data;
    // let objUrl = "";
    // const blobdata = new Blob([bytes]);
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveOrOpenBlob(blobdata, fileName);
    // } else {
    //     objUrl = URL.createObjectURL(blobdata);
    // }
    // const link = document.createElement('a');
    // link.href = objUrl;
    // link.setAttribute('download', `${fileName}`);
    // document.body.appendChild(link);
    // link.click();

    return async function actionGrid(dispatch) {
        const onDownloadProgressGrid = (progressEvent) => {
            let elem = document.getElementById("dmProgressBar");
            let width = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
            elem.style.width = width + "%";
            dispatch(updateProgressPercentage(width));
        }

        dispatch(showCircularLoader());
        dispatch(showProgressBar(true));
        dispatch(progressBarOperationName(injectIntlTranslation(intl, "Downloaded", "Downloaded")));
        let fileName = data && data.tableName + ".xlsx"
        let idpayload = data.id;
        let pushRequestDetailIdpayload = data && data.pushRequestDetailId;

        try {
            const url = `${appConfig.api.commonModelServices}api/admin/DownloadVersion?pushRequestId=${idpayload.toString()}&pushRequestDetailId=${pushRequestDetailIdpayload.toString()}`
            const { data } = await ApiServiceDM(url, GET, null, 'blob', 0, null, null, onDownloadProgressGrid)

            let bytes = data;
            let objUrl = "";
            const blobdata = new Blob([bytes]);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blobdata, fileName);
            } else {
                objUrl = URL.createObjectURL(blobdata);
            }
            const link = document.createElement('a');
            link.href = objUrl;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();

            dispatch(showProgressBar(false));
            dispatch(hideCircularLoader());
            enqueueSnackbar(injectIntlTranslation(intl, "MSG_DOWNLOAD_SUCCESS", "File downloaded successfully."), { variant: 'success' });
            dispatch(updateProgressPercentage(0));

        }
        catch (error) {
            dispatch(hideCircularLoader());
            dispatch(showProgressBar(false));
            error && error.data && data.messages
                ? enqueueSnackbar(injectIntlTranslation(intl, error.data.messages[0]), { variant: 'error' })
                : enqueueSnackbar(injectIntlTranslation(intl, "MSG_DOWNLOAD_ERROR"), { variant: 'error' });
        }
    }
}

export const restoreBackup = (tableName, intl, tableData) => async (dispatch) => {
    dispatch(showCircularLoader());
    try {
        let url = `${appConfig.api.commonModelServices}${API.RESTORE_TABLEDATA_DETAILS}${tableName}`;
        if (tableData && tableData.id) {
            url = `${appConfig.api.commonModelServices}/api/admin/RestoreFromVersion?pushRequestId=${tableData && tableData.id}&pushRequestDetailsId=${tableData && tableData.pushRequestDetailId}`;
        }
        let { status } = await ApiService(url, POST)
        status === STATUS_OK
            ? enqueueSnackbar(injectIntlTranslation(intl, "MSG_SUCCESS_RESTORE", "Data restored successfully."), { variant: 'success' })
            : enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage"), { variant: 'error' });
        dispatch(hideCircularLoader());
    }
    catch (error) {
        dispatch(hideCircularLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}

export const pushToDevelopment = (tableName, intl, textBoxValue, tableVersion) => async (dispatch) => {
    dispatch(showCircularLoader());
    try {
        let formData = new FormData();
        formData.append("Version", tableVersion);
        formData.append("Description", textBoxValue);
        const url = `${appConfig.api.commonModelServices}${API.PUSH_TO_DEV}${tableName}`
        const { data } = await ApiService(url, POST, formData)
        data.succeeded
            ? enqueueSnackbar(injectIntlTranslation(intl, "MSG_PUSH_TO_DEV_SUCCESS", "Pushed data to development database."), { variant: 'success' })
            : enqueueSnackbar(injectIntlTranslation(intl, data.messages[0]), { variant: 'error' });
        dispatch(hideCircularLoader());
    }
    catch (error) {
        dispatch(hideCircularLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "MSG_PUSH_TO_DEV_ERROR", "Error in Push to Dev"), { variant: 'error' });
    }
}

export const pushToProduction = (tableName, intl, textBoxValue, tableVersion) => async (dispatch) => {
    dispatch(showCircularLoader());
    try {
        let formData = new FormData();
        formData.append("Version", tableVersion);
        formData.append("Description", textBoxValue);
        const url = `${appConfig.api.commonModelServices}${API.PUSH_TO_PROD}${tableName}`
        const { data } = await ApiService(url, POST, formData)
        data.succeeded
            ? enqueueSnackbar(injectIntlTranslation(intl, data.messages[0]), { variant: 'success' })
            : enqueueSnackbar(injectIntlTranslation(intl, data.messages[0]), { variant: 'error' });
        dispatch(hideCircularLoader());
    }
    catch (error) {
        dispatch(hideCircularLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}

export const requestToEnvironment = (tableName, intl, textBoxValue, tableVersion) => async (dispatch) => {
    dispatch(showCircularLoader());
    try {
        let formData = new FormData();
        formData.append("Version", tableVersion);
        formData.append("Description", textBoxValue);
        const url = `${appConfig.api.commonModelServices}${API.REQ_TO_PROD}${tableName}`
        const { data } = await ApiService(url, POST, formData)
        data.succeeded
            ? enqueueSnackbar(injectIntlTranslation(intl, "MSG_REQ_TO_PROD_SUCCESS", "Requested for production deployment successfully."), { variant: 'success' })
            : enqueueSnackbar(injectIntlTranslation(intl, data.messages[0]), { variant: 'error' });
        dispatch(hideCircularLoader());
    }
    catch (error) {
        dispatch(hideCircularLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}

export const onPushToStaging = (tableName, intl, textBoxValue, tableVersion) => async (dispatch) => {
    dispatch(showCircularLoader());
    try {
        let formData = new FormData();
        formData.append("Version", tableVersion);
        formData.append("Description", textBoxValue);
        const url = `${appConfig.api.commonModelServices}${API.PUSH_TO_STAGE}${tableName}`
        const { data } = await ApiService(url, POST, formData)
        data.succeeded
            ? enqueueSnackbar(injectIntlTranslation(intl, "MSG_PUSH_TO_STAGE_SUCCESS", "Pushed data to staging database."), { variant: 'success' })
            : enqueueSnackbar(injectIntlTranslation(intl, data.messages[0]), { variant: 'error' });
        dispatch(hideCircularLoader());
    }
    catch (error) {
        dispatch(hideCircularLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}

export const updateShowPushToProdBtn = (value) => (dispatch) => {
    dispatch({ type: SHOW_PUSH_TO_PROD_BTN, payload: value });
}

export const updateSelectedTableName = (value) => (dispatch) => {
    dispatch({ type: DM_SELECTED_TABLE_NAME, payload: value });
}

export const updateProductDropdownList = (value) => (dispatch) => {
    dispatch({ type: DM_UPDATE_PRODUCT_DROPDOWN_LIST, payload: value });
}

export const updateTablesList = (value) => (dispatch) => {
    dispatch({ type: DM_UPDATE_TABLES_LIST, payload: value });
}

export const updatedDMModalState = (value) => (dispatch) => {
    dispatch({ type: DM_MODAL_STATE, payload: value });
}

export const updateDMModalTitle = (value) => (dispatch) => {
    dispatch({ type: DM_MODAL_TITLE, payload: value });
}

export const updateDMModalContent = (value) => (dispatch) => {
    dispatch({ type: DM_MODAL_CONTENT, payload: value });
}

export const updateDMModalType = (value) => (dispatch) => {
    dispatch({ type: DM_MODAL_TYPE, payload: value });
}

export const updateAdminPagePermission = (value) => (dispatch) => {
    dispatch({ type: DM_ADMIN_PAGE_PERMISSION, payload: value });
}

export const getTableVersionHistory = (tableName, intl) => async (dispatch) => {
    try {
        dispatch(showPropagateLoader());
        const url = `${appConfig.api.commonModelServices}${API.GET_TABLE_VERSION_HISTORY}${tableName}`
        const response = await ApiService(url, GET)
        dispatch({ type: DM_TABLE_VERSION_HISTORY, payload: response.data });
        dispatch(hidePropagateLoader());
    }
    catch (error) {
        dispatch(hidePropagateLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}
export const getRequestVersionSelectionDetails = (tableName, intl) => async (dispatch) => {
    try {
        dispatch(showPropagateLoader());
        const url = `${appConfig.api.commonModelServices}${API.GET_REQUEST_VERSION_SELECTION_DETAILS}${tableName}`
        const response = await ApiService(url, GET)
        dispatch({ type: DM_REQUEST_VERSION_DETAILS, payload: response.data });
        dispatch(hidePropagateLoader());
    }
    catch (error) {
        dispatch(hidePropagateLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}
export const getPushVersionSelectionDetails = (tableName, intl) => async (dispatch) => {
    try {
        dispatch(showPropagateLoader());
        const url = `${appConfig.api.commonModelServices}${API.GET_PUSH_VERSION_SELECTION_DETAILS}${tableName}`
        const response = await ApiService(url, GET)
        dispatch({ type: DM_REQUEST_VERSION_DETAILS, payload: response.data });
        dispatch(hidePropagateLoader());
    }
    catch (error) {
        dispatch(hidePropagateLoader());
        enqueueSnackbar(injectIntlTranslation(intl, "GenericErrorMessage", "Error occurred please try again"), { variant: 'error' });
    }
}
