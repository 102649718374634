// const env = process.env.REACT_APP_ENV
const tenant = 'carriertools.onmicrosoft.com';

export const appConfig = {
    api: {
        userManagement: 'https://api-appshub.coolsystemsselect.com/', //'http://carrierappshubapi-env-dotnet.us-east-2.elasticbeanstalk.com/', //'https://api.carrierappshub.com/',
        cssMicroService: 'https://3jnyryb1ji.execute-api.eu-central-1.amazonaws.com/dev/projectmanagement/',
        translationApi: 'https://api-translation.coolsystemsselect.com/api/v1/translations/',
        qatranslationApi: 'https://qaapi-translation.coolsystemsselect.com/api/v1/translations/',
        commonModelServices: 'https://commonmodelapi-dev.coolsystemsselect.com/',  //https://3gcv2d0at7.execute-api.eu-central-1.amazonaws.com/dev/
        umDashboardNavigation: "https://apps.coolsystemsselect.com/",
        jsReport: "https://f88qeafgx2.execute-api.eu-central-1.amazonaws.com/qa/", // https://apim-carrier-qa.azure-api.net/
        clientID: 'c85c51f4-fb1e-4b81-8add-756ac5f2c107', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
        authority: 'https://CarrierTools.b2clogin.com/' + tenant + '/B2C_1_CSS_SUSI_POL', // Sign up and Sign In Policy
        forgotpasswordAuthority: 'https://CarrierTools.b2clogin.com/' + tenant + '/B2C_1_ResetPassword/', // Forget password policy name
        profileEditing: 'https://login.microsoftonline.com/tfp/' + tenant + '/B2C_1_PROEDIT/', // Profile Edit
        clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
        clientScope2: 'https://' + tenant + '/umdevapi/read',
        clientScope3: 'https://' + tenant + '/umdevapi/write',
        postLogoutRedirectUri: 'https://dev.coolsystemsselect.com/',
        encryption: {
            IV: "8080808080808080",
            Key: "8080808080808080"
        },
        appAcessURl: 'https://dev.coolsystemsselect.com/',
        version: "V 1.0",
        // blobStorage: "https://commonmodelblobstorage.blob.core.windows.net",
        cloudFrontStorageURL: "https://d79rnkletyc63.cloudfront.net/",
        sas: "p%2BHVCT7Asy4NuMEMB7Op%2BeofGD5Sh7Z3SwGorfnxQOoUZVqt0ncU%2FkFAY7MI%2BaK4OI3K%2BIS7dElS%2FQ0UO1XZsZoSgW7a8tTBhCfT0HrZjjz5%2Fhwrrgd0FXB0I0vXvEdxTbKodh%2BFBs2wfV3i5WE1geOCR3H111DwJzmhA5FzXw%2BP%2FrGjWqUzF0NPcCnKiJyxpYTRfbOpFVnTbvQXNC826g%3D%3D",//SAS key
        containerName: "drawings",
        helpcontroler: 'https://3jnyryb1ji.execute-api.eu-central-1.amazonaws.com/dev/userpreferences/',
        loginConfig: {
            // authority: 'https://CarrierTools.b2clogin.com/' + tenant + '/B2C_1_CSS_SUSI_POL', // Sign up and Sign In Policy
            // clientID: 'c85c51f4-fb1e-4b81-8add-756ac5f2c107', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
            // clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
            apiUrl: 'https://api-appshub.coolsystemsselect.com/', //'http://carrierappshubapi-env-dotnet.us-east-2.elasticbeanstalk.com/', //'https://api.carrierappshub.com/',
            uiUrl: 'https://dev.coolsystemsselect.com/',
            postLogoutRedirectUri: 'https://dev.coolsystemsselect.com/',
            appType: 'CSS',
            redirectAppUri: 'https://auth-appshub.coolsystemsselect.com',
        }
    },
    Environment: "DEV"
}

export default appConfig;