import { setBuilderPermissions } from "./setBuilderPermissions";
import { updateBuilderPermission } from "./getAllProducts";
import { UserPerference, updateUserProfile, updateUserRoles, getLocale,updatediapasonRole } from './userPerference';
import { getLanguageDetails } from '../../utilities/languagesutils';

const createBuilderPermissions = (data, builderPermissions, isChildern = false) => {
  data.childSettings.forEach((builder) => {
    // if (builder.settingName === "Platform Permissions") {
    //   builderPermissions[builder.settingName] = true;
    // }
    // else {
      const isChildernAvailable = (builder.childSettings && (builder.childSettings.length > 0));
      let shouldShowBuilder = false;
      if (isChildernAvailable || isChildern) {
        shouldShowBuilder = true;
      }
      builderPermissions[builder.settingName] = builder.settingName === "ElectricPanel" ? true : shouldShowBuilder;
      if (isChildernAvailable) {
        createBuilderPermissions(builder, builderPermissions, true);
      }
    // }
  });
  return builderPermissions;
}

export const updatePermissionsAndLocale = ({data, userRoles}) => (dispatch, getState) => {
  const { permissions } = data;
  let builderPermissions = {};

  dispatch(updateUserProfile(data));

  permissions.forEach((value) => {
    if (value.settingName === "Platform Permissions") {
      value.childSettings.forEach((item) => {
        if (item.settingName === "User Roles") {
          userRoles = item.childSettings.map(userole => userole.settingName)
          dispatch(updateUserRoles(userRoles));
        }
      })
    }
    else if (value.settingName === "Product") {
      builderPermissions = createBuilderPermissions(value, builderPermissions);
      dispatch(setBuilderPermissions(builderPermissions));
      dispatch(updateBuilderPermission(value));
    }
    if (value.settingName === "Roles") {
      var roles = [];
      value.childSettings.forEach((item, index) => {
        roles.push(item.settingName)
      });
      dispatch(updatediapasonRole(roles));
    }
  })
  const { userProfile: { oldEmailAddress }  } = getState();
  oldEmailAddress && dispatch(getLocale())
}


export const checkAndUpdateUserPerference = ({dispatch, name, role, unit, userRoles}) => {
  const language = getLanguageDetails(name);
  if ((!role && !!userRoles.length) || (role && userRoles.indexOf(role) <= -1)) {
    dispatch(UserPerference({
      opt: language,
      unit: unit,
      role: userRoles[0],
      isUnitChange: false
    }));
  } else {
    if (!(language && language.name && unit && role)) {
      dispatch(UserPerference({
        opt: language,
        unit: unit,
        role: role,
        isUnitChange: false
      }));
    }
  }
}