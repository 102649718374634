//production
//  export const protocol_http = window.location.protocol+'//';
//  export const domain_credmgr = window.location.host;
module.exports = {
  Project_Name: "projectName",
  Project_Customer: "projectCustomer",
  Project_Owner: "ProjectOwner",
  Last_Modified_Date: "lastModifiedDate",
  Project: "Project",
  Customer: "Customer",
  Sales_Person: "Sales Person",
  Last_Reviewed_On: "Last Reviewed On",
  Project_ID: "projectID",
  LOCALE_SET_START: "LOCALE_SET_START",
  LOCALE_SET_FULFILLED: "LOCALE_SET_FULFILLED",
  LOCALE_SET_FAILED: "LOCALE_SET_FAILED",
  TagNameExist: "Tag Name already exists",
  EMEA_PRODUCTBUILDER: "5efe13ce-a89d-4f43-919b-b0f91ca6d25b",
  NAO_PRODUCTBUILDER: "536f27d2-3a57-49f8-8701-d0891116e089",
  EMPTY_GUID: "00000000-0000-0000-0000-000000000000",

  TRANSLATION_API_PROJECT_ID: 2,

  STATUS_OK: 200,

  //Request Type
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",

  //File Type
  EXCEL_FILE_XSLX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

  //Dashboard default values
  OPEN_OPPORTUNITY_ID: '5E2D7DEF-3CE0-4AFC-8141-0F754891E58B',
  PROJECT_TYPE: 0,
  PROJECT_SEARCH_TYPE: 'all',

  //Dashboard action types
  UPDATE_DASHBOARD_OPPORTUNITY_ID: 'UPDATE_DASHBOARD_OPPORTUNITY_ID',
  UPDATE_DASHBOARD_PROJECT_TYPE: 'UPDATE_DASHBOARD_PROJECT_TYPE',
  UPDATE_DASHBOARD_PROJECT_SEARCH_FILTER: 'UPDATE_DASHBOARD_PROJECT_SEARCH_FILTER',
  UPDATE_DASHBOARD_PROJECT_SEARCH_COLUMN: 'UPDATE_DASHBOARD_PROJECT_SEARCH_COLUMN',

  //Notifications action types
  SHOW_SUCCESS_NOTIFICATION: "SHOW_SUCCESS_NOTIFICATION",
  SHOW_ERROR_NOTIFICATION: "SHOW_ERROR_NOTIFICATION",
  SHOW_WARNING_NOTIFICATION: "SHOW_WARNING_NOTIFICATION",
  SHOW_INFO_NOTIFICATION: "SHOW_INFO_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",

  //Save tree structure
  SET_PROJECT_MASTER_DATA_TREESTRUCTURE: 'SET_PROJECT_MASTER_DATA_TREESTRUCTURE ',

  //Loaders
  SHOW_CIRCULAR_LOADER: "SHOW_CIRCULAR_LOADER",
  HIDE_CIRCULAR_LOADER: "HIDE_CIRCULAR_LOADER",
  SHOW_PROPAGATE_LOADER: "SHOW_PROPAGATE_LOADER",
  HIDE_PROPAGATE_LOADER: "HIDE_PROPAGATE_LOADER",

  //ProgressBar
  SHOW_PROGRESS_BAR: "SHOW_PROGRESS_BAR",
  PROGRESS_BAR_OPERATION_NAME: "PROGRESS_BAR_OPERATION_NAME",
  UPDATE_PROGRESS_PERCENTAGE: "UPDATE_PROGRESS_PERCENTAGE",

  //project list action types
  FETCH_PROJECTLIST_START: "FETCH_PROJECTLIST_START",
  FETCH_PROJECTLIST_FULFILLED: "FETCH_PROJECTLIST_FULFILLED",
  FETCH_PROJECTLIST_FAILED: "FETCH_PROJECTLIST_FAILED",
  NEED_PROJECTLIST_REFRESH: "NEED_PROJECTLIST_REFRESH",
  SAVE_DROPDOWN_VALUE: "SAVE_DROPDOWN_VALUE",

  //get GetCustomerListForNewProject action types
  FETCH_ADDPROJECT_START: "FETCH_ADDPROJECT_START",
  FETCH_ADDPROJECT_FULFILLED: "FETCH_ADDPROJECT_FULFILLED",
  FETCH_ADDPROJECT_FAILED: "FETCH_ADDPROJECT_FAILED",

  //Add selection dropdown action types
  GET_ALL_PRODUCTS_START: "GET_ALL_PRODUCTS_START",
  GET_ALL_PRODUCTS_FULFILLED: "GET_ALL_PRODUCTS_FULFILLED",
  GET_ALL_PRODUCTS_FAILED: "GET_ALL_PRODUCTS_FAILED",
  UPDATE_BUILDER_LIST_WITH_PERMISSION: "UPDATE_BUILDER_LIST_WITH_PERMISSION",
  UPDATE_ALL_BUILDER_ROUTES: "UPDATE_ALL_BUILDER_ROUTES",

  //Create customer action types

  CREATE_CUSTOMER_START: "CREATE_CUSTOMER_START",
  CREATE_CUSTOMER_FULFILLED: "CREATE_CUSTOMER_FULFILLED",
  CREATE_CUSTOMER_FAILED: "CREATE_CUSTOMER_FAILED",

  CUSTOMER_DETAILS_LOADING: "CUSTOMER_DETAILS_LOADING",
  CUSTOMER_DETAILS_LOADED: "CUSTOMER_DETAILS_LOADED",
  CUSTOMER_DETAILS_LOADING_FAILED: "CUSTOMER_DETAILS_LOADING_FAILED",

  GET_CUSTOMER_LIST_START: " GET_CUSTOMER_LIST_START",
  GET_CUSTOMER_LIST_FULFILLED: " GET_CUSTOMER_LIST_FULFILLED",
  GET_CUSTOMER_LIST_FAILED: " GET_CUSTOMER_LIST_FAILED",

  //project detial list action types
  FETCH_PROJECTDETAILLIST_START: "FETCH_PROJECTDETAILLIST_START",
  FETCH_PROJECTDETAILLIST_FULFILLED: "FETCH_PROJECTDETAILLIST_FULFILLED",
  RESET_PROJECTDETAILLIST: "RESET_PROJECTDETAILLIST",
  FETCH_PROJECTDETAILLIST_FAILED: "FETCH_PROJECTDETAILLIST_FAILED",

  //project detial list action types
  SAVETAG_START: 'SAVETAG_START',
  SAVETAG_FULFILLED: 'SAVETAG_FULFILLED',
  SAVETAG_FAILED: 'SAVETAG_FAILED',

  //Get template list
  GET_TEMPLATE_LIST: "GET_TEMPLATE_LIST",
  GET_TEMPLATE_LIST_FULFILLED: "GET_TEMPLATE_LIST_FULFILLED",
  GET_TEMPLATE_LIST_FAILED: "GET_TEMPLATE_LIST_FAILED",

  //UPGRADE
  UPGRADE_TAGS_SUCCESS: "UPGRADE_TAGS_SUCCESS",

  //edit project details
  EDITTAG_START: 'EDITTAG_START',
  EDITTAG_FULFILLED: 'EDITTAG_FULFILLED',
  EDITTAG_FAILED: 'EDITTAG_FAILED',

  //Update tag templates
  UPDATE_TAG_TEMPLATE_FULFILLED: "UPDATE_TAG_TEMPLATE_FULFILLED",
  UPDATE_TAG_TEMPLATE_START: "UPDATE_TAG_TEMPLATE_START",
  UPDATE_TAG_TEMPLATE_FAILED: "UPDATE_TAG_TEMPLATE_FAILED",

  //Get concept templates
  GET_CONCEPT_TEMPLATE_START: 'GET_CONCEPT_TEMPLATE_START',
  GET_CONCEPT_TEMPLATE_FULFILLED: 'GET_CONCEPT_TEMPLATE_FULFILLED',
  GET_CONCEPT_TEMPLATE_FAILED: 'GET_CONCEPT_TEMPLATE_FAILED',

  //Save concept templates
  SAVE_CONCEPT_TEMPLATE_START: 'SAVE_CONCEPT_TEMPLATE_START',
  SAVE_CONCEPT_TEMPLATE_FULFILLED: 'SAVE_CONCEPT_TEMPLATE_FULFILLED',
  SAVE_CONCEPT_TEMPLATE_FAILED: 'SAVE_CONCEPT_TEMPLATE_FAILED',

  //Update concept templates
  UPDATE_CONCEPT_TEMPLATE_START: 'UPDATE_CONCEPT_TEMPLATE_START',
  UPDATE_CONCEPT_TEMPLATE_FULFILLED: 'UPDATE_CONCEPT_TEMPLATE_FULFILLED',
  UPDATE_CONCEPT_TEMPLATE_FAILED: 'UPDATE_CONCEPT_TEMPLATE_FAILED',

  //DELETE concept templates
  DELETE_CONCEPT_TEMPLATE_START: 'DELETE_CONCEPT_TEMPLATE_START',
  DELETE_CONCEPT_TEMPLATE_FULFILLED: 'DELETE_CONCEPT_TEMPLATE_FULFILLED',
  DELETE_CONCEPT_TEMPLATE_FAILED: 'DELETE_CONCEPT_TEMPLATE_FAILED',

  //TagTemplate dialouge constants
  OPEN_SAVE_POPUP: "OPEN_SAVE_POPUP",
  OPEN_DIALOUGE: "OPEN_DIALOUGE",
  OPEN_DELETE_DIALOUGE: "OPEN_DELETE_DIALOUGE",
  OPEN_REPLACE_DIALOUGE: "OPEN_REPLACE_DIALOUGE",
  TAG_NAME: "TAG_NAME",
  TAG_DATA: "TAG_DATA",

  //Set Builder Permission
  BUILDER_PERMISSIONS_SET: "BUILDER_PERMISSIONS_SET",

  //Update User Profile
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  LOCALE_UPDATE_USER_ROLES: 'LOCALE_UPDATE_USER_ROLES',
  LOCALE_UPDATE_DIAPASON_ROLES: 'LOCALE_UPDATE_DIAPASON_ROLES',
  UPDATE_MESSAGES: 'UPDATE_MESSAGES',
  UPDATE_EQUIP_MESSAGES: 'UPDATE_EQUIP_MESSAGES',
  UPDATE_EQUIP_LANG: 'UPDATE_EQUIP_LANG',


  //Product Load Diapason Condenser
  FETCH_CHAR_PRODUCT_START: "INITIAL_CHAR_PRODUCT_START",
  FETCH_CHAR_PRODUCT_FULFILLED: "CHAR_INITIALDATA_FULFILLED",
  CHAR_PRODUCT_REFRESH: "CHAR_PRODUCT_REFRESH",
  FETCH_CHAR_PRODUCT_FAILED: "CHAR_PRODUCT_INITIALDATA_FAILED",

  FETCH_COMMON_MODEL_START: "FETCH_COMMON_MODEL_START",
  FETCH_COMMON_MODEL_STOP: "FETCH_COMMON_MODEL_STOP",
  FETCH_COMMON_MODEL_FULFILLED: "FETCH_COMMON_MODEL_FULFILLED",
  FETCH_COMMON_MODEL_VALUE: "FETCH_COMMON_MODEL_VALUE",
  FETCH_COMMON_MODEL_FAILED: "FETCH_COMMON_MODEL_FAILED",

  //Get New Product list 
  FETCH_PRODUCT_LIST_START: "FETCH_PRODUCT_LIST_START",
  FETCH_PRODUCT_LIST_FULFILLED: "FETCH_PRODUCT_LIST_FULFILLED",
  FETCH_PRODUCT_SUCCESS_DATA: "FETCH_PRODUCT_SUCCESS_DATA",
  PRODUCT_LIST_REFRESH: "PRODUCT_LIST_REFRESH",
  FETCH_PRODUCT_LIST_FAILED: "FETCH_PRODUCT_LIST_FAILED",
  FETCH_COMMON_MODEL_REFRESH: "FETCH_COMMON_MODEL_REFRESH",
  RESET_COMMON_MODEL_RECORDS: "RESET_COMMON_MODEL_RECORDS",

  //Get Characteristics Controller
  FETCH_CHARACTERISTICS_CONTROLLER_START: "FETCH_CHARACTERISTICS_CONTROLLER_START",
  FETCH_CHARACTERISTICS_CONTROLLER_FULFILLED: "FETCH_CHARACTERISTICS_CONTROLLER_FULFILLED",
  FETCH_CHARACTERISTICS_CONTROLLER_SUCCESS_DATA: "FETCH_CHARACTERISTICS_CONTROLLER_SUCCESS_DATA",
  CHARACTERISTICS_CONTROLLER_REFRESH: "CHARACTERISTICS_CONTROLLER_REFRESH",
  FETCH_CHARACTERISTICS_CONTROLLER_FAILED: "FETCH_CHARACTERISTICS_CONTROLLER_FAILED",

  FETCH_SELECTEDPRODUCTDETAILS_START: 'FETCH_SELECTEDPRODUCTDETAILS_START',
  FETCH_SELECTEDPRODUCTDETAILS_FULFILLED: 'FETCH_SELECTEDPRODUCTDETAILS_FULFILLED',
  FETCH_SELECTEDPRODUCTDETAILS_SUCCESS_DATA: "FETCH_SELECTEDPRODUCTDETAILS_SUCCESS_DATA",
  FETCH_SELECTEDPRODUCTDETAILS_FAILED: 'FETCH_SELECTEDPRODUCTDETAILS_FAILED',

  //Help Controller
  FETCH_HELPCONTROLLER_START: 'FETCH_HELPCONTROLLER_START',
  FETCH_HELPCONTROLLER_FULFILLED: 'FETCH_HELPCONTROLLER_FULFILLED',
  FETCH_HELPCONTROLLER_FAILED: 'FETCH_HELPCONTROLLER_FAILED',

  //Selection Summary
  UPDATE_TAG_SELECTION: "UPDATE_TAG_SELECTION",
  SELECTION_SUMMARY_LOADING: "SELECTION_SUMMARY_LOADING",

  //Project Info
  GET_PROJECT_TYPE_LIST: 'GET_PROJECT_TYPE_LIST',
  GET_APPLICATION_TYPE_LIST: 'GET_APPLICATION_TYPE_LIST',
  GET_PROJECT_STATUS_TYPE_LIST: 'GET_PROJECT_STATUS_TYPE_LIST',
  GET_PROJECT_OPPURTUNITY_STAGES_LIST: 'GET_PROJECT_OPPURTUNITY_STAGES_LIST',
  GET_PROJECT_ACCOUNT_AREA_LIST: 'GET_PROJECT_ACCOUNT_AREA_LIST',
  GET_PFI_USER_CHECK: 'GET_PFI_USER_CHECK',

  //Data Management - Admin Page
  DM_UPDATE_TABLES_LIST: 'DM_UPDATE_TABLES_LIST',
  DM_UPDATE_PRODUCT_DROPDOWN_LIST: 'DM_UPDATE_PRODUCT_DROPDOWN_LIST',
  DM_SELECTED_TABLE_NAME: 'DM_SELECTED_TABLE_NAME',
  SHOW_PUSH_TO_PROD_BTN: 'SHOW_PUSH_TO_PROD_BTN',
  DM_MODAL_STATE: 'DM_MODAL_STATE',
  DM_MODAL_TITLE: "DM_MODAL_TITLE",
  DM_MODAL_CONTENT: "DM_MODAL_CONTENT",
  DM_MODAL_TYPE: "DM_MODAL_TYPE",
  DM_ADMIN_PAGE_PERMISSION: 'DM_ADMIN_PAGE_PERMISSION',
  DM_TABLE_VERSION_HISTORY: "DM_TABLE_VERSION_HISTORY",
  DM_REQUEST_VERSION_DETAILS: "DM_REQUEST_VERSION_DETAILS",
  DM_PUSH_VERSION_DETAILS: "DM_PUSH_VERSION_DETAILS",

  //Project Summary - New Selection Summary
  FETCH_PROJECT_SELECTION_SUMMARY_LOADING: 'FETCH_PROJECT_SELECTION_SUMMARY_LOADING',
  FETCH_PROJECT_SELECTION_SUMMARY_RECORDS: 'FETCH_PROJECT_SELECTION_SUMMARY_RECORDS',
  UPDATE_SUMMARY_TABS_MAPPINGS: 'UPDATE_SUMMARY_TABS_MAPPINGS',
  GET_QUOTE_REPORT_IMAGES: 'GET_QUOTE_REPORT_IMAGES',
  GET_REPORT_CONTACT_DETAILS: 'GET_REPORT_CONTACT_DETAILS',

  // Project Master Data actionTypes
  SET_PROJECT_MASTER_DATA: 'SET_PROJECT_MASTER_DATA',
  SET_PROJECT_MASTER_DATAET: 'SET_PROJECT_MASTER_DATAET',
  GET_COLLING_DEMAN: 'GET_COLLING_DEMAN',
  //New Dashboard
  UPDATE_PROJECT_GRID_STATE: "UPDATE_PROJECT_GRID_STATE",
  UPDATE_PROJECT_GRID_TAB: "UPDATE_PROJECT_GRID_TAB",
  SAVE_CABINET_DATA: 'SAVE_CABINET_DATA',
  GET_PRODUCT_RESUTLS: 'GET_PRODUCT_RESUTLS',
  RESET_PRODUCT_RESUTLS: 'RESET_PRODUCT_RESUTLS',
  GET_PRODUCT_RESULTS_BY_ID: 'GET_PRODUCT_RESULTS_BY_ID',
  GET_PRODUCTOPTIONS: 'GET_PRODUCTOPTIONS',
  GET_CHARACTERISTICS_DETAILS_REFRIGERATION_SUPPLY: "GET_CHARACTERISTICS_DETAILS_REFRIGERATION_SUPPLY",
  GET_PRICE_RESULTS: 'GET_PRICE_RESULTS',
  UPGRADE_TAG_VERSION: 'UPGRADE_TAG_VERSION',

  //Diapason Version Update
  LOCALE_SET_VERSION_FULFILLED: "LOCALE_SET_VERSION_FULFILLED"
};