import {
    FETCH_CHARACTERISTICS_CONTROLLER_START,
    FETCH_CHARACTERISTICS_CONTROLLER_FULFILLED,
    CHARACTERISTICS_CONTROLLER_REFRESH,
    FETCH_CHARACTERISTICS_CONTROLLER_FAILED
} from '../constants/constant';
import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    records: [],
    isLoading: false,
    needRefresh: false,
    error: ''
};

const getCharacteristicsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHARACTERISTICS_CONTROLLER_START:
            return {
                ...state,
                needRefresh: false,
                isLoading: true
            }
        case FETCH_CHARACTERISTICS_CONTROLLER_FULFILLED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                records: action.data
            }
        case FETCH_CHARACTERISTICS_CONTROLLER_FAILED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                error: action.data
            }
        case CHARACTERISTICS_CONTROLLER_REFRESH:
            return {
                ...state,
                needRefresh: true
            }
        case actionConstants.RESET_CHARACTERISTICS_CONTROLLER_RESPONSE:
            return { ...initialState }
        default:
            return state;
    }
}

export default getCharacteristicsReducer
