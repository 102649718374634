import { DM_UPDATE_TABLES_LIST, DM_SELECTED_TABLE_NAME, DM_UPDATE_PRODUCT_DROPDOWN_LIST,SHOW_PUSH_TO_PROD_BTN, DM_MODAL_STATE, DM_MODAL_TITLE, DM_MODAL_CONTENT, DM_MODAL_TYPE, DM_ADMIN_PAGE_PERMISSION, DM_TABLE_VERSION_HISTORY,DM_REQUEST_VERSION_DETAILS } from "../constants/constant";

const initialState = {
    tablesList: [],
    dropdownList:[],
    selectedTableName: "",
    modalState: false,
    modalTitle: "",
    modalContent: "",
    modalType: "",
    showPushToProdBtn: false,
    adminPagePermission: false,
    tableVersionHistory: []
};

export default function dataManagementReducer(state = initialState, action) {
    switch (action.type) {
        case DM_UPDATE_TABLES_LIST:
            return {
                ...state,
                tablesList: action.payload
            }
        case DM_SELECTED_TABLE_NAME:
            return {
                ...state,
                selectedTableName: action.payload
            }
            case DM_UPDATE_PRODUCT_DROPDOWN_LIST:
                return {
                    ...state,
                    dropdownList: action.payload
                }
        case SHOW_PUSH_TO_PROD_BTN:
            return {
                ...state,
                showPushToProdBtn: action.payload
            }
        case DM_MODAL_STATE:
            return {
                ...state,
                modalState: action.payload
            }
        case DM_MODAL_TITLE:
            return {
                ...state,
                modalTitle: action.payload
            }
        case DM_MODAL_CONTENT:
            return {
                ...state,
                modalContent: action.payload
            }
        case DM_MODAL_TYPE:
            return {
                ...state,
                modalType: action.payload
            }
        case DM_ADMIN_PAGE_PERMISSION:
            return {
                ...state,
                adminPagePermission: action.payload
            }
        case DM_TABLE_VERSION_HISTORY:
            return {
                ...state,
                tableVersionHistory: action.payload
            }
        case DM_REQUEST_VERSION_DETAILS:
            return{
                ...state,
                requestVersionDetails: action.payload
            }
        default:
            return state;
    }
}