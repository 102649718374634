import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    characteristics: {},
    rackModelInformation: [],
    hxModelInformation: [],
    cduModelInformation: [],
    productModelNavigation: "",
    productModelTagIdNavigation: "",
    resultsGridSelections: [],
    tagTemplates: [],
    conceptTemplates: []
};

export default function electricResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_CHARSTCS_RESPONSE_ELECTRIC:
            return {
                ...state,
                characteristics: action.payload
            }
        case actionConstants.UPDATE_RACKS_MODEL_RESPONSE_ELECTRIC:
            return {
                ...state,
                rackModelInformation: action.payload
            }
        case actionConstants.UPDATE_HX_MODEL_RESPONSE_ELECTRIC:
            return {
                ...state,
                hxModelInformation: action.payload
            }
        case actionConstants.UPDATE_CDU_MODEL_RESPONSE_ELECTRIC:
            return {
                ...state,
                cduModelInformation: action.payload
            }
        case actionConstants.UPDATE_TAG_TEMPLATES_ELECTRIC:
            return {
                ...state,
                tagTemplates: action.payload
            }
        case actionConstants.UPDATE_PRODUCT_MODEL_NAVIGATION_ELECTRIC:
            return {
                ...state,
                productModelNavigation: action.payload
            }
        case actionConstants.UPDATE_PRODUCT_MODEL_TAGID_NAVIGATION_ELECTRIC:
            return {
                ...state,
                productModelTagIdNavigation: action.payload
            }
        case actionConstants.RESET_ELECTRIC_SELECTION_OBJ:
            return { ...initialState }
        default:
            return state;
    }
}