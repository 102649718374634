import { SHOW_PROGRESS_BAR, PROGRESS_BAR_OPERATION_NAME, UPDATE_PROGRESS_PERCENTAGE } from "../constants/constant";

export const showProgressBar = ( bool ) => (dispatch) => {
    dispatch({ type: SHOW_PROGRESS_BAR, payload: bool });
};

export const progressBarOperationName = ( operation ) => (dispatch) => {
    dispatch({ type: PROGRESS_BAR_OPERATION_NAME, payload: operation });
};

export const updateProgressPercentage = ( value ) => (dispatch) => {
    dispatch({ type: UPDATE_PROGRESS_PERCENTAGE, payload: value });
};