import appConfig from './Environment/environments';
import axios from 'axios';
// import * as Msal from 'msal';
import {logout as authLogout} from '@ccr-main/reactauthwrapper';


export const logout = () => {
    // var msalConfig = {
    //     auth: {
    //       clientId: appConfig.api.clientID,
    //       authority: appConfig.api.authority,
    //       postLogoutRedirectUri: appConfig.api.postLogoutRedirectUri, 
    //       navigateToLoginRequestUrl: false,
    //       validateAuthority: false       
    //     },
    //     cache: {        
    //       cacheLocation: "localStorage",
    //       storeAuthStateInCookie: true
    //     }
    //   };   
    // var userAgentApplication = new Msal.UserAgentApplication(msalConfig);
    // userAgentApplication.handleRedirectCallback((error, response) => {});
    // userAgentApplication.logout();
    authLogout(appConfig.api.loginConfig);

}

axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error && error.response){
      if(error.response.status === 401) {
        logout();
        return;
      }
    }
    return Promise.reject(error);
});