import React from 'react';

const UseNotification = ({ message }) => {

    return (
        <div class="marquee">
        <div>
          <span>{message}</span>
        </div>
      </div>
    );
};

export default UseNotification;
