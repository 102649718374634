
import { FETCH_SELECTEDPRODUCTDETAILS_START, FETCH_SELECTEDPRODUCTDETAILS_FULFILLED, FETCH_SELECTEDPRODUCTDETAILS_SUCCESS_DATA, FETCH_SELECTEDPRODUCTDETAILS_FAILED } from '../constants/constant';
import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    records: [],
    isLoading: false,
    isSuccess: false,
    needRefresh: false,
    error: '',
    module: ''
};

const getSelectedProductDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SELECTEDPRODUCTDETAILS_START:
            return {
                ...state,
                needRefresh: false,
                isLoading: true,
                module: action.module
            }
        case FETCH_SELECTEDPRODUCTDETAILS_FULFILLED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                records: action.data,
                module: action.module,
                isSuccess: action.isSuccess,
            }
        case FETCH_SELECTEDPRODUCTDETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                error: action.data,
                module: action.module
            }
        case FETCH_SELECTEDPRODUCTDETAILS_SUCCESS_DATA:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                isSuccess: action.isSuccess,
                records: action.data,
                module: action.module
            }
        case actionConstants.RESET_SELECTEDPRODUCTDETAILS:
            return { ...initialState }
        default:
            return state;
    }
}

export default getSelectedProductDetailsReducer
