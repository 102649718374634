import {GET_CUSTOMER_LIST_START,GET_CUSTOMER_LIST_FULFILLED,GET_CUSTOMER_LIST_FAILED} from '../constants/constant.js';

const CustomerListinitialState={
    records: {},
    isLoading: false,
    error: ''
}

export default function getCustomersListReducer(state = CustomerListinitialState, action){
    switch(action.type){
        case   GET_CUSTOMER_LIST_START:
            return {
                ...state,
                isLoading: true
            }
        case GET_CUSTOMER_LIST_FULFILLED:
            return {
                ...state,
                isLoading: false,
                records: action.data
            }
        case GET_CUSTOMER_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.data
            }
        default:
        return state;
    }
}