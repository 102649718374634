import { actionConstants } from '@ccr-main/diapason-sharedlibrary-ui';

const initialState = {
    recoverydata: {},
    modalState: false,
    racksPayload: {
        "T_water_in_HR1": "",
        "T_water_out_HR1": "",
        "Q_HR1": "",
        "EnableHR2": "",
        "T_water_in_HR2": "",
        "T_water_out_HR2": "",
        "Q_HR2": "",
        "InitialMode": "True"
    },
};

export default function heatRecoveryReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.SET_HEAT_RECOVERY_CALC:
            return {
                ...state,
                recoverydata: action.paylod.data
            }
        case actionConstants.SHOW_HEAT_RECOVERY_MODAL:
            return {
                ...state,
                modalState: true
            }
        case actionConstants.HIDE_HEAT_RECOVERY_MODAL:
            return {
                ...state,
                modalState: false
            }
        case actionConstants.UPDATE_HEAT_RECOVERY_PAYLOAD_RACKS:
            return {
                ...state,
                racksPayload: {
                    ...state.racksPayload,
                    [action.payload.id]: action.payload.value
                }
            }
        case actionConstants.RESET_HEAT_RECOVERY_DATA:
            return { ...initialState }

        default:
            return state;
    }
}
