import React, { useState } from "react";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import './Drawer.css'
import { injectIntlTranslation } from '@ccr-main/diapason-sharedlibrary-ui';
import touricon from '../../../assets/images/play_circle_outline-24px.svg';
// import reporticon from '../../../assets/images/pest_control-24px.svg';
import feedbackicon from '../../../assets/images/campaign-24px.svg';
import supproticon from '../../../assets/images/local_post_office-24px.svg';

const LeftDrawer=(props)=> {
    const [hover, sethover] = useState(false);
    
    function onMouseEnterHandler() {
      sethover(true);
    }
    function onMouseLeaveHandler() {     
      sethover(false);
    }

    const handleWebtourClick = (e) => {
      props.isShowWebTour(true)
    }
    const handleFeedbackAndReportClick = (value)=>{
      props.handleFeedbackAndReportClick(value);
     }
    const handleSupportClick = (value)=>{
      props.handleSupportClick(value);
    }
    return (
      <div id='drawerApplay'
        className={classNames(
          "getACallBox",         
          hover ? "getCallActive" : ""
        )}
        onMouseOver={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        
      >
        <span className="callback" id="Assistance">         
         {injectIntlTranslation(props.intl, 'Assistance', "Assistance")}
        </span>
        <div className="callbackCharm">
          <ul>
            <li onClick={e => handleWebtourClick()}>
              <div className="li_line_margin_space">
              <img src={touricon} className='svgclass' alt="" />  {injectIntlTranslation(props.intl, "WebTour", "Play web tour")}
              </div>
            </li>
            <li onClick={e => handleFeedbackAndReportClick(true)}><div className="li_line_margin_space"><img src={feedbackicon} className='svgclass' alt="" /> {injectIntlTranslation(props.intl, "FeedbackAndReport", "Feedback & Report")}</div></li> 
            <li onClick={e => handleSupportClick(true)}><div className="li_line_margin_space"><img src={supproticon}className='svgclass' alt="" /> {injectIntlTranslation(props.intl, "Support", "Support")}</div></li>
            <li style={{ height: "30px"}}><div className="li_line_margin_space"></div></li>
           
          </ul>
        </div>
      </div>
    );
  }
  export default injectIntl(LeftDrawer)