import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import zh from 'react-intl/locale-data/zh';
import zht from 'react-intl/locale-data/zh';
import Grow from './NotiStackComponents/NotiStackGrow';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

addLocaleData([...en, ...fr, ...de, ...zh, ...zht]);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
    <Router>
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={8000}
            TransitionComponent={Grow}
            action={(snackbarId) => (
                <CloseRoundedIcon style={{ cursor: 'pointer' }} onClick={() => closeSnackbar(snackbarId)} />
            )}
        >
            <App />
        </SnackbarProvider>
    </Router>
</Provider>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
