import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    SelectedTree: {}
};

export default function treeStructureResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.SAVE_TREE_DATA:
            return {
                ...state,
                SelectedTree: action.payload
            }

        default:
            return state;
    }
}