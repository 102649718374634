import { FETCH_HELPCONTROLLER_START, FETCH_HELPCONTROLLER_FULFILLED, FETCH_HELPCONTROLLER_FAILED } from '../constants/constant';

const initialState = {
    records: [],
    isLoading: false,
    error: ''
};

export default function HelperControlerReducer(state = initialState, action){
    switch(action.type){
        case FETCH_HELPCONTROLLER_START:
            return {
                ...state,
                isLoading: true,
                error:''
            }
        case FETCH_HELPCONTROLLER_FULFILLED:
            return {
                ...state,
                isLoading: false,
                records: action.data,
                error:''
            }
        case FETCH_HELPCONTROLLER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action && action.data && action.data.Message,
               
            }
        default:
        return state;
    }
}