
import { FETCH_CHAR_PRODUCT_START, FETCH_CHAR_PRODUCT_FULFILLED, CHAR_PRODUCT_REFRESH, FETCH_CHAR_PRODUCT_FAILED } from '../constants/constant';

const initialState = {
    records: [],
    isLoading: false,
    needRefresh: false,
    error: ''
};

const getProductCharacteristicsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHAR_PRODUCT_START:
            return {
                ...state,
                needRefresh: false,
                isLoading: true
            }
        case FETCH_CHAR_PRODUCT_FULFILLED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                records: action.data
            }
        case FETCH_CHAR_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                error: action.data
            }
        case CHAR_PRODUCT_REFRESH:
            return {
                ...state,
                needRefresh: true
            }
        default:
            return state;
    }
}

export default getProductCharacteristicsReducer