import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    callCM: false,
    isDefaultSettings: false,
    isEditMode: false,
    hasCurrProj: false,
    selectedTemplateName: "",
    selectedTemplateNameCopy: "",
    IsFlatfileOpen: false,
    IsSaveOpen: false,
    IsGeneratePDFOpen: false,
    isSoundModalOpen: false,
    IsDisplayChooseColumns: false,
    isTextFieldUpdated: false,
    showModelProductSearch: false,
    UpdateResultGridFilter: "",
    userModidfiedFields: ["Range"],
    charErrorFields: {},
    showCharWarningIcon: false,
    paramErrorFields: {},
    showParamWarningIcon: false,
    filteredResultsGrid: [],
    defaultSettingsObj: {},
    editSelectionObj: {},
    currentSelectionObj: {}
};

export default function cabinetmanualSelectionObjReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionConstants.SET_CURRENT_SELECTION_OBJ_CABINETMANUAL:
            return {
                ...state,
                currentSelectionObj: action.payload
            }
        default:
            return state;
    }
}