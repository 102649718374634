import { combineReducers } from 'redux';
import drycoolerResponseReducer from './drycoolerResponseReducer';
import drycoolerSelectionObjReducer from './drycoolerSelectionObjReducer';
import drycoolerGlobalReducer from './drycoolerGlobalReducer';

const drycoolerReducer = combineReducers({
    drycoolerResponse: drycoolerResponseReducer,
    drycoolerSelectionObj: drycoolerSelectionObjReducer,
    drycoolerGlobalSelectionObj: drycoolerGlobalReducer,
});

export default drycoolerReducer;