import {CREATE_CUSTOMER_START, CREATE_CUSTOMER_FULFILLED, CREATE_CUSTOMER_FAILED } from '../constants/constant';

const initialState = {
    records: {},
    isLoading: false,
    error: ''
};

export default function createCustomerReducer(state = initialState, action){
    switch(action.type){
        case CREATE_CUSTOMER_START:
            return {
                ...state,
                isLoading: true
            }
        case CREATE_CUSTOMER_FULFILLED:
            return {
                ...state,
                isLoading: false,
                records: action.data
            }
        case CREATE_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.data
            }
        default:
        return state;
    }
}
