import { combineReducers } from 'redux';
import evaporatorResponseReducer from './evaporatorResponseReducer';
import evaporatorSelectionObjReducer from './evaporatorSelectionObjReducer';
import evaporatorGlobalReducer from './evaporatorGlobalReducer';

const evaporatorReducer = combineReducers({
    evaporatorResponse: evaporatorResponseReducer,
    evaporatorSelectionObj: evaporatorSelectionObjReducer,
    evaporatorGlobalSelectionObj: evaporatorGlobalReducer,
});

export default evaporatorReducer;