import { LOCALE_SET_START, LOCALE_SET_FULFILLED, LOCALE_SET_FAILED, LOCALE_UPDATE_USER_ROLES, LOCALE_UPDATE_DIAPASON_ROLES, LOCALE_SET_VERSION_FULFILLED,UPDATE_MESSAGES, UPDATE_EQUIP_MESSAGES, UPDATE_EQUIP_LANG } from '../constants/constant';

const initialState = {
    lang: 'en',
    name: 'English',
    unit: 'Metric',
    // error: '',
    fullLangCode: 'en-US',
    message: {},
    equipLang: 'en',
    equipMessage: {},
    role: "Anonymous",
    userRoles: ["Anonymous"],
    isLoading: false,
    diapasonVersion: '2.1'
};

export default function localeReducer(state = initialState, action) {
    switch (action.type) {
        case LOCALE_SET_START:
            return {
                ...state,
                isLoading: true
            }
        case LOCALE_SET_FULFILLED:
            return {
                ...state,
                lang: action.data.lang,
                name: action.data.Language,
                unit: action.data.UnitSystem,
                role: action.data.Role,
                diapasonrole: action.data.diapasonrole,
                fullLangCode: action.data.fullLangCode,
                message: action.data.message,
                isLoading: false
            }
        case LOCALE_SET_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case LOCALE_UPDATE_USER_ROLES:
            return {
                ...state,
                userRoles: action.data.userRoles
            }
        case LOCALE_UPDATE_DIAPASON_ROLES:
            return {
                ...state,
                diapasonrole: action.data.diapasonrole
            }
        case LOCALE_SET_VERSION_FULFILLED:
            return{
                ...state,
                diapasonVersion: action.data.diapasonVersion
            }
        case UPDATE_MESSAGES:
            return {
                ...state,
                message: action.data
            }
        case UPDATE_EQUIP_MESSAGES:
            return {
                ...state,
                equipMessage: action.data
            }
        case UPDATE_EQUIP_LANG:
            return {
                ...state,
                equipLang: action.data
            }
        default:
            return state;
    }
}