import { combineReducers } from 'redux';
import cabinetotherconsumerResponseReducer from './cabinetotherconsumerResponseReducer';
import cabinetotherconsumerSelectionObjReducer from './cabinetotherconsumerSelectionObjReducer';
// import condenserSelectionObjReducer from './condenserSelectionObjReducer';

const cabinetotherconsumerReducer = combineReducers({
    cabinetotherconsumerResponse: cabinetotherconsumerResponseReducer,
    cabinetotherconsumerSelectionObj: cabinetotherconsumerSelectionObjReducer,
});

export default cabinetotherconsumerReducer;