import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0075F5',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#152c73',
      // dark: will be calculated from palette.secondary.main,
    },
    // info: {
    //   main: "#E6E6E6"
    // }
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  typography: {
    subtitle1: {
      fontWeight: "bold"
    },
    subtitle2: {
      fontWeight: "bold"
    },
    caption: {
      fontSize: "0.675rem"
    }
  }
});

export default theme