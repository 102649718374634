import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    GeneralData: {},
    Products: {},
    WallTransmission_New: {},
    Doors: {},
    Machines: {},
    Infiltration: {},
    Internal: {},
    tagTemplates: [],
    conceptTemplates: [],
    Inclusions: [],
    Visibility: [],
    DefaultSelection: []
};

export default function coldroomResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_RESPONSE_COLDROOM:
            return {
                ...state,
                GeneralData: action.payload.GeneralData,
                Products: action.payload.Products,
                WallTransmission_New: action.payload.WallTransmission_New,
                Doors: action.payload.Doors,
                Machines: action.payload.Machines,
                Infiltration: action.payload.Infiltration,
                Internal: action.payload.Internal,
                Inclusions: action.payload.Inclusions,
                Visibility: action.payload.Visibility,
                DefaultSelection: action.payload.DefaultSelection,
            }
        case actionConstants.UPDATE_TAG_TEMPLATES_COLDROOM:
            return {
                ...state,
                tagTemplates: action.payload
            }
        case actionConstants.RESET_COLDROOM_RESPONSE:
            return { ...initialState }

        default:
            return state;
    }
}