import React from 'react';
import { connect } from "react-redux";
import { PropagateLoader as Loader } from 'react-spinners';
import { css } from '@emotion/core';

const PropagateLoader = (props) => {
    const { propagateLoader } = props;
    
    if(propagateLoader){
        return(
            <Loader
                css={css`
                    margin: 100px 600px 0 600px;
                    height: 100vh
                    `}
                color={"#3f51b5"}
            />
        )
    } else {
        return null
    }
}

const mapStateToProps = (state) => ({
    propagateLoader: state.loader.propagateLoader
});

export default connect(mapStateToProps)(PropagateLoader);