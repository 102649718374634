import React from 'react';
import { translation } from '@ccr-main/diapason-sharedlibrary-ui';
import appConfig from "../Environment/environments";

export default function Error() {
  const styles = {
    textDecoration: "none",
    padding: "13px 20px",
    backgroundColor: "#262c43",
    color: "#ffffff",
    fontSize: "16px"
  }
  const idToken = localStorage.getItem("msal.idtoken");
  return (
    <div>
      <p>{translation("ApplicationPermission")}</p>
      <br />
      <br />
      <a style = {styles} href={appConfig.api.umDashboardNavigation + "?umtoken=" + idToken}>{translation("OpenCAH")}</a>
    </div>
  )
}
