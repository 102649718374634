import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    callCM: false,
    isDefaultSettings: false,
    isEditMode: false,
    hasCurrProj: false,
    selectedTemplateName: "",
    selectedTemplateNameCopy: "",
    IsFlatfileOpen: false,
    IsSaveOpen: false,
    IsGeneratePDFOpen: false,
    IsCapacityControlOpen: false,
    IsSuperHeatControlOpen: false,
    IsHighPressureModalOpen: false,
    IsDisplayChooseColumns: false,
    isTextFieldUpdated: false,
    showModelProductSearch: false,
    CapacityControlValidationError: [],
    UpdateResultGridFilter: "",
    userModidfiedFields: ["Range"],
    charErrorFields: {},
    showCharWarningIcon: false,
    paramErrorFields: {},
    showParamWarningIcon: false,
    filteredResultsGrid: [],
    defaultSettingsObj: {},
    editSelectionObj: {},
    currentSelectionObj: {}
};

export default function racksSelectionObjReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionConstants.UPDATE_CALL_COMMOM_MODEL_RACKS:
            return {
                ...state,
                callCM: action.payload
            }
        case actionConstants.UPDATE_DEFAULT_SETTINGS_RACKS:
            return {
                ...state,
                isDefaultSettings: action.payload
            }
        case actionConstants.SET_EDIT_MODE_RACKS:
            return {
                ...state,
                isEditMode: action.payload
            }
        case actionConstants.SET_CURR_PROJ_AVAILABILITY_RACKS:
            return {
                ...state,
                hasCurrProj: action.payload
            }
        case actionConstants.UPDATE_TEMPLATE_NAME_RACKS:
            return {
                ...state,
                selectedTemplateName: action.payload,
                selectedTemplateNameCopy: action.payload
            }
        case actionConstants.UPDATE_FLAT_FILE_MODAL_STATE_RACKS:
            return {
                ...state,
                IsFlatfileOpen: action.payload
            }
        case actionConstants.UPDATE_SAVE_MODAL_STATE_RACKS:
            return {
                ...state,
                IsSaveOpen: action.payload
            }
        case actionConstants.UPDATE_GENERATE_PDF_MODAL_STATE_RACKS:
            return {
                ...state,
                IsGeneratePDFOpen: action.payload
            }
        case actionConstants.UPDATE_CAPACITY_CONTROL_MODAL_STATE_RACKS:
            return {
                ...state,
                IsCapacityControlOpen: action.payload
            }
        case actionConstants.UPDATE_SUPER_HEAT_CONTROL_MODAL_STATE_RACKS:
            return {
                ...state,
                IsSuperHeatControlOpen: action.payload
            }
        case actionConstants.UPDATE_HIGH_PRESSURE_MODAL_STATE_RACKS:
            return {
                ...state,
                IsHighPressureModalOpen: action.payload
            }
        case actionConstants.UPDATE_CHOOSE_COLS_MODAL_STATE_RACKS:
            return {
                ...state,
                IsDisplayChooseColumns: action.payload
            }
        case actionConstants.APPLY_CLIENT_SIDE_RESULTS_FILTER_RACKS:
            return {
                ...state,
                UpdateResultGridFilter: Math.random()
            }
        case actionConstants.UPDATE_USER_MODIFIED_FIELDS_RACKS:
            return {
                ...state,
                userModidfiedFields: [...new Set(state.userModidfiedFields.concat(action.payload))]
            }
        case actionConstants.RESET_USER_MODIFIED_FIELDS_RACKS:
            return {
                ...state,
                userModidfiedFields: ["Range"]
            }
        case actionConstants.UPDATE_CHAR_ERROR_FIELDS_RACKS:
            return {
                ...state,
                charErrorFields: {
                    ...state.charErrorFields,
                    [action.payload.id]: action.payload.value
                }
            }
        case actionConstants.UPDATE_SHOW_CHAR_ERROR_RACKS:
            return {
                ...state,
                showCharWarningIcon: action.payload
            }
        case actionConstants.UPDATE_PARAM_ERROR_FIELDS_RACKS:
            return {
                ...state,
                paramErrorFields: {
                    ...state.paramErrorFields,
                    [action.payload.id]: action.payload.value
                }
            }
        case actionConstants.UPDATE_SHOW_PARAM_ERROR_RACKS:
            return {
                ...state,
                showParamWarningIcon: action.payload
            }
        case actionConstants.UPDATE_FILTERED_RESULTS_GRID_RACKS:
            return {
                ...state,
                filteredResultsGrid: action.payload
            }
        case actionConstants.RESET_RACKS_SELECTION_OBJ:
            return { ...initialState }

        case actionConstants.UPDATE_DEFAULT_OBJ_RACKS:
            return {
                ...state,
                defaultSettingsObj: action.payload
            }
        case actionConstants.SET_EDIT_SELECTION_OBJ_RACKS:
            return {
                ...state,
                editSelectionObj: action.payload
            }
        case actionConstants.SET_CURRENT_SELECTION_OBJ_RACKS:
            return {
                ...state,
                currentSelectionObj: action.payload
            }
        case actionConstants.TEXTFIELD_UPDATED_RACKS:
            return {
                ...state,
                isTextFieldUpdated: action.payload
            }
        case actionConstants.PRODUCT_SEARCH_MODEL_RACKS:
            return {
                ...state,
                showModelProductSearch: action.payload
            }
        case actionConstants.CAPACITY_CONTROL_VALIDATION_ERROR_RACKS:
            return {
                ...state,
                CapacityControlValidationError: action.payload
            }
        case actionConstants.RESET_RACKS_RESULT_GRID_DEPENDENTS:
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        IsPerformanceImpactUpdated: false,
                        SelectedCandidateIds: [],
                        UserSelectedCandidates: [],
                        row: {},
                        Options: [],
                        UserSelectedProductCodes: []
                        // ComparativeModeCandidates: [],
                    }
                }
            }
        case actionConstants.UPDATE_CURRENT_SELECTION_OBJ_RACKS:
            if (action.payload.stage === "RuleCandidates") {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        RuleCandidates: [
                            {
                                ...state.currentSelectionObj.RuleCandidates[0],
                                [action.payload.id]: action.payload.value
                            }
                        ]
                    }
                }
            }
            else {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        [action.payload.stage]: {
                            ...state.currentSelectionObj[action.payload.stage],
                            [action.payload.id]: action.payload.value
                        }
                    }
                }
            }
        case actionConstants.UPDATE_UI_RULES_INCLUSIONS_RACKS: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                BLACKFont: action.payload.blackFont
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_EXCLUSIONS_RACKS: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                Disabled: action.payload.exclude
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_API_FILTERED_COMP_RACKS: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                APIFilteredResponse: action.payload.data
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_SECTION_VISIBILITY_RACKS: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            SectionsVisibility: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules.SectionsVisibility,
                                [action.payload.id]: action.payload.value
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_FIELDS_VISIBILITY_RACKS: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                Visible: action.payload.visible
                            }
                        }
                    }
                }
            }
        }
        default:
            return state;
    }
}