export const API = {
  GET_ORDER_REPORT: `api/Report/GetOrderReport`,
  GET_MIGRATION_STATUS: `api/User/UpdateMigratedUser?userName=`,
  LASTSIGNEDIN: `/api/users/UpdateUserLastSignedIn`,
  IS_EXIST: `api/users/existsEncrypted?useremail=`,
  CREATEUSER: `api/users/create`,
  GET_USER_PREF: `api/User/GetUserPreferences`,
  POST_CREATE_LANGUAGE: `api/User/CreateUserPreference`,
  POST_UPDATE_LANGUAGE: `api/User/UpdateUserPreference`,
  GET_PROJECTLIST: `api/Project/GetUserProjects?`,
  POST_ADDPROJECT: `api/Project/CreateProjectCustomer`,
  GET_ALL_PRODUCTS_BUILDER: `api/MasterData/GetAllProductBuilderModels`,
  POST_CREATE_CUSTOMER: `api/Customer/CreateCustomer`,
  POST_UPDATE_CUSTOMER: `api/Project/UpdateProjectCustomer`,
  POST_UPDATE_PROJECT: `api/Project/UpdateProjectName`,
  GET_USER_CUSTOMERS: `api/Customer/GetUserCustomerList`,
  GET_PROJECT_GROUPS_AND_TAGS: `api/tag/GetProjectCustomerandGroupingDetailsByProjectId?`,
  GET_CUSTOMER_LIST: `api/Customer/GetCustomerAddressList?CustomerID=`,
  POST_UPDATE_PROJECTNAME: `api/Project/UpdateProjectName`,
  EXPORT_PROJECT_DETAILS: `api/ImportExport/ExportProject?projectId=`,
  UPDATE_PROJECT_CUSTOMER: `api/Project/UpdateProjectCustomer?`,
  // POST_PROJECT_IMPORT: `api/ProjectDetail/ProjectImport?projectName=`,
  IMPORT_PROJECT_DETAILS: `api/ImportExport/ImportProject?projectName=`,
  // GET_PROJECTDETAILLIST: `api/Tag/GetProjectTags`,
  GET_SELECTION_SUMMARY_DETAILS: `api/Tag/GetTagList`,
  GET_USER_ROLES: `api/groupUser/GetUserRoles`,
  DELETE_TEMPLATE: `api/Tag/DeleteTemplate?`,
  POST_COPY_SELECTIONS: `api/Tag/CopyTagInfo?`,
  // POST_DELETE_SELECTIONS: `api/Tag/DeleteMultipleTags`,
  // POST_DELETE_SELECTION: `api/Tag/DeleteTag?tagId=`,
  DELETE_PRODUCT_BY_PROJECT_GROUP: `api/Tag/DeleteProductByGroup`,
  DELETE_SELECTED_TAGS: `api/Tag/DeleteTags`,
  UPDATE_TAG_RANK: `api/Tag/UpdateTagRank`,
  MOVE_SELECTION_TO_GROUP: `api/Tag/MoveTag`,
  COPY_SELECTED_TAGS: `api/Tag/CopyTagInfo?`,
  POST_UPGRADE_SELECTION: `api/Tag/UpgradeTag`,
  POST_SAVETAG: `api/Tag/SaveTagPackagedChiller`,
  UPDATE_RIGHT_DRAWER: `api/Tag/UpdateRightDrawer`,

  CREATE_OR_UPDATE_CIRCUIT: `api/Tag/CreateOrUpdateCircuit`,
  CREATE_OR_UPDATE_CIRCUIT_CATEGORY: `api/Tag/CreateOrUpdateProductCategoryForCircuit`,

  POST_SAVETEMPLATE: `api/UserDefaults/SaveTagTemplate`,
  GENERATE_REPORT: `api/Tag/CalculatePackageChiller`,
  GENERATE_PACKAGED_REPORT: `api/Tag/GeneratePackagedChillerReport`,
  GET_PACKAGE_CHILLER_REPORT: `api/Tag/GetPackagedChillerReport?`,
  CALLBACK: "/authcallback",
  UPDATE_TAG_NAME: `api/Tag/UpdateTagName?`,
  UPDATE_PROJECT_GROUP_NAME: `api/Tag/UpdateProjectGroupName?`,
  UPDATE_TAG_QUANTITY: `api/Tag/UpdateTagQty?`,
  GET_TAG_SELECTION_CONDITIONS: "api/Tag/GetTagSelectionConditions?",
  GET_TAG_COOLINGDEMAND: "api/Tag/GetCoolingDemand?",
  GET_TAG_CONFIGURATION: "api/Tag/GetTagConfiguration",
  UPDATE_TAG_SELLING_PRICE: "api/Tag/UpdateTagSellingPrice",
  GET_TAG_TEMPLATE_DETAILS: "api/Tag/GetTagTemplateDetails?tagTemplateId=",
  EDIT_PROJECT_TAG: "api/Tag/EditTagPackagedChiller",
  GET_APP_PERMISSIONS: `api/users/GetUserProfileWithAccessPermissionsEncrypted?`,
  GET_PRODUCTBUILDER_CALCULATION_TYPE: `api/MasterData/GetProductBuilderCalculationTypes?ProductBuilder=`,
  // SAVE_TAG_TEMPLATE: `api/ProjectDetail/SaveTagTemplate`,
  // GET_TAG_TEMPLATE: `api/ProjectDetail/GetTagTemplates?`,
  // DELETE_TAGTEMPLATE: `api/Tag/DeleteTemplate?templateId=`,
  // UPDATE_TAG_TEMPLATE: `api/ProjectDetail/UpdateTagTemplate`,
  GET_PACKAGE_CHILLER_CSO: `api/Tag/GetPackagedChillersCSO?requestId=`,
  GET_CONCEPT_TEMPLATE: `api/Tag/GetAllSubTagTemplate?`,
  SAVE_CONCEPT_TEMPLATE: `api/Tag/CreateSubTagTemplate`,
  UPDATE_CONCEPT_TEMPLATE: `api/Tag/UpdateSubTagTemplate`,
  GET_TAG_CONFIGURATION_INPUTJSON: `api/Tag/GetTagConfigurationInputJson?tagId=`,
  POST_SAVE_TAG_CONFIGURATION: `api/Tag/SaveTagConfiguration`,
  SET_CALC_SELECTION_ASYNC: `api/Tag/CalculateSelectionAsync`,
  SET_CALC_RESULT_ASYNC: `api/Tag/GetCalculationResult`,
  UPGRADETAG_STATUS: `api/Tag/GetUpgradeTagsStatus`,
  POST_EXPORT_RESULT: `api/report/GetExportToExcelData`,
  SAVE_TAG_SELECTION: `api/Tag/SaveTagSelection`,
  CREATE_OR_UPDATE_PIPING: `api/piping/CreateOrUpdatePipeLine`,
  GET_PRODUCT_LIBRARY: `api/MasterData/GetHelpLibraryDocuments`,
  GET_DOCUMENT_CONTENT: `api/MasterData/GetDocumentContent`,
  CALCULATE_SECONDARY_DATA: "api/Tag/CalculateSecondaryData",
  GET_SECONDARY_DATA: "api/Tag/GetSecondaryData",
  GET_PROJECT_OPTIONS: "api/Tag/GetProjectOptions?ProjectId=",
  POST_COMPARE_REPORT: `api/Report/GetSelectionCompareReports`,
  GET_TAG_INFO: `api/Tag/GetTagInformation?tagid=`,
  OPTIMIZE_SELECTION_REPORT: `api/report/GetSelectionOptimizationReport`,
  GET_REPORT_OPTION: `api/MasterData/GetProductBuilderReportTypes?`,
  POST_REPORT_CALCULATION: `api/Report/InitiateReportCalculation`,
  POST_REPORT_CALCULATIONSTATUS: `api/Report/GetReportCalculationStatus`,
  POST_REPORT_GETTAGREPORTLIST: `api/Report/GetTagReportList`,
  GET_LOADLINE_DATA: "api/Tag/GetLoadLineData?tagId=",
  GET_PROJECT_REPORT: "api/Report/GetProjectReport",
  COMPARE_REPORT: `api/Report/GetCompareReports`,
  EXPLICIT_CALCULATION_CHECK: `api/Tag/CheckExplicitCalculationRun`,
  EXPORT_TO_QB: `api/IntegrationAPI/GetSAPXML`,

  GET_CONDENSER_DATA: "api/common/GetProductMasterData/Condenser",
  GET_CHARACTERISTICS_DETAILS_CONDENSER_DATA:
    "api/common/GetProductDetailsMasterData/CondenserNew",
  GET_CHARACTERISTICS_DETAILS_CABINET_DATA:
    "api/common/GetProductDetailsMasterData/Cabinets",
  GET_CHARACTERISTICS_DETAILS_EVAPORATOR_DATA:
    "api/common/GetProductDetailsMasterData/EvaporatorNew",
  GET_CHARACTERISTICS_DETAILS_PIPING_DATA:
    "api/common/GetProductDetailsMasterData/Piping",
  GET_EVAPORATOR_DATA: "api/common/GetProductMasterData/Evaporator",

  GET_DRYCOOLER_DATA: "api/common/GetProductMasterData/DryCooler",
  GET_CHARACTERISTICS_DETAILS_DRYCOOLER_DATA:
    "api/common/GetProductDetailsMasterData/DryCoolerNew",

  GET_BRINECOOLER_DATA: "api/common/GetProductMasterData/BrineCooler",
  GET_CHARACTERISTICS_DETAILS_BRINECOOLER_DATA:
    "api/common/GetProductDetailsMasterData/BrineCoolerNew",

  GET_GASCOOLER_DATA: "api/common/GetProductMasterData/GasCooler",
  GET_CHARACTERISTICS_DETAILS_GASCOOLER_DATA:
    "api/common/GetProductDetailsMasterData/GasCoolerNew",

  GET_RACKS_DATA: "api/Common/GetProductMasterData/Racks",
  GET_CHARACTERISTICS_DETAILS_RACKS_DATA:
    "api/common/GetProductDetailsMasterData/Racks",

  GET_CDU_DATA: "api/Common/GetProductMasterData/CDU",
  GET_CHARACTERISTICS_DETAILS_CDU_DATA:
    "api/common/GetProductDetailsMasterData/CDU",

  // GET_ELECTRICPANEL_DATA: 'api/Common/GetProductMasterData/ElectricalPanel', // No master data for elec panel
  GET_CHARACTERISTICS_DETAILS_ELECTRICPANEL_DATA:
    "api/common/GetProductDetailsMasterData/ElectricalPanel",
  GET_CHARACTERISTICS_DETAILS_CABINETS_DATA:
    "api/common/GetProductDetailsMasterData/Cabinets",
  GET_CHARACTERISTICS_DETAILS_COLDROOM_DATA:
    "api/common/GetProductDetailsMasterData/coldstorage",
  GET_CHARACTERISTICS_DETAILS_CABINETMANUAL_DATA:
    "api/common/GetProductDetailsMasterData/CabinetManual?factoryName=EMEA%20-%20Aubagne",
  GET_CHARACTERISTICS_DETAILS_CABINETOTHERCONSUMER_DATA:
    "api/common/GetProductDetailsMasterData/OtherConsumer?factoryName=EMEA%20-%20Aubagne",
  GET_CHARACTERISTICS_DETAILS_CABINET_NEWSUMMARY_DATA:
    "api/common/GetProductDetailsMasterData/Cabinet?factoryName=EMEA%20-%20Aubagne",
  GET_CHARACTERISTICS_DETAILS_REFRIGERATION_SUPPLY:
    "api/common/GetRefrigerationSupplyMasterData/", //TODO: remove after implementing bottom api
  GET_CHARACTERISTICS_DETAILS_REFRIGERATIONSUPPLY_DATA:
    "api/common/GetRefrigerationSupplyMasterData",
  GET_REFRIGIRATIONSUPPLY_CALC_DATA: "/api/Tag/GetRefSupplyCalcData",
  GET_COLDROOMCALCRULES_DATA: "api/common/ColdRoomCalculationRules",

  SAVE_EMAIL_TO_SUPPORT: "api/HelperControler/SaveAndSendEmailToSupport",
  UPDATE_TAG_SELECTION: "api/Tag/UpdateTagSelection",
  GET_TAG_RESULT_DETAILS: "api/Tag/GetTagResultDetails",
  UPDATE_TAG_NOTE: "api/Tag/UpdateTagNote",
  SAVE_CABINET_PRODUCTS: "api/Project/SaveCabinetProducts",

  EXPORT_ALL_TAG_DETAILS: `api/Tag/ExportAllTags?projectId=`,
  EXPORT_TAG_DETAILS: `api/Tag/GetTagConfiguration?projectId=`,
  GET_PROJECT_DETAILS_BY_ID: `api/Project/GetProjectDetailsByID?ProjectID=`,
  GET_PROJECT_DETAILS_BY_ID_ET: `api/Project/v2/GetProjectDetailsByID?ProjectID=`,
  DELETE_PROJECT_GROUP: `api/Project/DeleteProjectGroupDetails?projectID=`,
  GET_TO_SALESFORCE: `api/CPQIntegration/GetSelectedProductResults`,
  UPDATE_PROJECT_DETAILS: `api/Project/CreateorUpdateProjectCustomerDetails`,
  GET_MASTER_DATATABLES: `api/Admin/GetMasterDataTables`,
  GET_REPORT_EXPORT_TABLELIST: `api/Admin/GetReportExportTableList`,
  DOWNLOAD_TABLE_REPORTDATA: `api/Admin/DownloadReportTableData?tableName=`,
  GET_MASTER_TABLEDATA: `api/Admin/GetProductDropDownItems`,
  UPLOAD_TABLEDATA_DETAILS: `api/Admin/Upload?tableName=`,
  DOWNLOAD_TABLEDATA_DETAILS: `api/Admin/Download?tableName=`,
  RESTORE_TABLEDATA_DETAILS: `api/Admin/RestoreBackup?tableName=`,
  PUSH_TO_DEV: `api/Admin/PushToDevelopment?tableName=`,
  PUSH_TO_PROD: `api/Admin/PushToProduction?tableName=`,
  PUSH_TO_STAGE: `api/Admin/PushToStaging?tableName=`,
  REQ_TO_PROD: `api/Admin/RequestToPush?tableName=`,
  REQ_TO_ENV: `api/Admin/GetUdatedTableList?environment=`,
  GET_TABLE_VERSION_HISTORY: `api/admin/GetAllUploadRequestData?tableName=`,
  GET_REQUEST_VERSION_SELECTION_DETAILS: `api/admin/GetRequestVersionSelectionDetails?tableName=`,
  GET_PUSH_VERSION_SELECTION_DETAILS: `api/admin/GetPushVersionSelectionDetails?tableName=`,
  DOWNLOAD_TABLE_VERSION_HISTORY: `api/admin/DownloadVersion?`,

  // ETMigration Tree structure
  UPDATE_TREESTRUCTURE: `api/TreeStructure/CreateOrUpdateTreeStructure`,
  DELETE_TREESTRUCTURE: `api/TreeStructure/DeleteCircuitDetails`,

  // Piping
  SAVE_PIPE_LINE: "api/Piping/CreateOrUpdatePipeLine",

  GET_DEPENDENT_PRJ_DETAILS: "api/Tag/GetTagModel?",
  GET_PRODUCT_RESULTS: "api/Common/GetProductResults",
  GET_PIPING_MATERIAL_GRID: "api/Common/GetMaterialSetData",
  GET_PIPE_CALCULATION_PAYLOAD: "api/Piping/GetPipingCalculationData",
  SAVE_PIPING_TRACKSELECTION: "api/Piping/SavePipeTrackSelection",
  DELETE_PIPELINE: "api/Piping/DeletePipeLine?pipeline=",
  GET_PRODUCT_RESULTS_BY_ID: "api/Common/GetProductResultsById",
  GET_PRODUCTOPTIONS: "api/Common/GetMultipleProductOptions?",
  UPGRADE_TAG_VERSION: "api/Tag/UpgardeTagVersion",

  //Selection Summary New
  ADD_NEW_CIRCUIT: "api/tag/addnewcircuit",
  ADD_PRODUCTCATEGOERY_CIRCUIT: "api/tag/AddProductCategoryForCircuit",
  DELETE_SELECTION_SUMMARY_CIRCUIT: 'api/Tag/DeleteCircuit',
  UPDATE_TAG_RESULT: 'api/Tag/UpdateTagResult',
  QUOTE_REPORT_IMAGES: 'api/Common/GetCssReportsData',
  PROJECT_REPORT_CONTACT_DETAILS: 'api/Tag/GetContactDetails?ProjectId=',
  GET_ALL_PRODUCT_IMAGES: 'api/Common/GetProductImages?productNames=',
};
