import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    isDefaultSettings: false,
    isEditMode: false,
    selectedTemplateName: "",
    selectedTemplateNameCopy: "",
    IsFlatfileOpen: false,
    IsSaveOpen: false,
    IsGeneratePDFOpen: false,
    IsDisplayChooseColumns: false,
    UpdateResultGridFilter: "",
    userModidfiedFields: ["Range"],
    charErrorFields: {},
    showCharWarningIcon: false,
    // paramErrorFields: {},
    // showParamWarningIcon: false,
    filteredResultsGrid: [],
    defaultSettingsObj: {},
    editSelectionObj: {},
    currentSelectionObj: {}
};

export default function CabinetSelectionObjReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionConstants.UPDATE_DEFAULT_SETTINGS_CABINET:
            return {
                ...state,
                isDefaultSettings: action.payload
            }
        case actionConstants.SET_EDIT_MODE_CABINET:
            return {
                ...state,
                isEditMode: action.payload
            }
        case actionConstants.UPDATE_TEMPLATE_NAME_CABINET:
            return {
                ...state,
                selectedTemplateName: action.payload,
                selectedTemplateNameCopy: action.payload
            }
        case actionConstants.UPDATE_FLAT_FILE_MODAL_STATE_CABINET:
            return {
                ...state,
                IsFlatfileOpen: action.payload
            }
        case actionConstants.UPDATE_SAVE_MODAL_STATE_CABINET:
            return {
                ...state,
                IsSaveOpen: action.payload
            }
        case actionConstants.UPDATE_GENERATE_PDF_MODAL_STATE_CABINET:
            return {
                ...state,
                IsGeneratePDFOpen: action.payload
            }
        case actionConstants.UPDATE_CHOOSE_COLS_MODAL_STATE_CABINET:
            return {
                ...state,
                IsDisplayChooseColumns: action.payload
            }
        case actionConstants.APPLY_CLIENT_SIDE_RESULTS_FILTER_CABINET:
            return {
                ...state,
                UpdateResultGridFilter: Math.random()
            }
        case actionConstants.UPDATE_USER_MODIFIED_FIELDS_CABINET:
            return {
                ...state,
                userModidfiedFields: [...new Set(state.userModidfiedFields.concat(action.payload))]
            }
        case actionConstants.RESET_USER_MODIFIED_FIELDS_CABINET:
            return {
                ...state,
                userModidfiedFields: ["Range"]
            }
        case actionConstants.UPDATE_CHAR_ERROR_FIELDS_CABINET:
            return {
                ...state,
                charErrorFields: {
                    ...state.charErrorFields,
                    [action.payload.id]: action.payload.value
                }
            }
        case actionConstants.UPDATE_SHOW_CHAR_ERROR_CABINET:
            return {
                ...state,
                showCharWarningIcon: action.payload
            }
        case actionConstants.UPDATE_PARAM_ERROR_FIELDS_CABINET:
            return {
                ...state,
                paramErrorFields: {
                    ...state.paramErrorFields,
                    [action.payload.id]: action.payload.value
                }
            }
        case actionConstants.UPDATE_SHOW_PARAM_ERROR_CABINET:
            return {
                ...state,
                showParamWarningIcon: action.payload
            }
        case actionConstants.UPDATE_FILTERED_RESULTS_GRID_CABINET:
            return {
                ...state,
                filteredResultsGrid: action.payload
            }
        case actionConstants.RESET_CABINET_SELECTION_OBJ:
            return { ...initialState }

        case actionConstants.UPDATE_DEFAULT_OBJ_CABINET:
            return {
                ...state,
                defaultSettingsObj: action.payload
            }
        case actionConstants.SET_EDIT_SELECTION_OBJ_CABINET:
            return {
                ...state,
                editSelectionObj: action.payload
            }
        case actionConstants.SET_CURRENT_SELECTION_OBJ_CABINET:
            return {
                ...state,
                currentSelectionObj: action.payload
            }
        case actionConstants.RESET_CABINET_RESULT_GRID_DEPENDENTS:
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        IsPerformanceImpactUpdated: false,
                        SelectedCandidateIds: [],
                        UserSelectedCandidates: [],
                        row: {},
                        Options: [],
                        ComparativeModeCandidates: [],
                    }
                }
            }
        case actionConstants.UPDATE_CURRENT_SELECTION_OBJ_CABINET:
            if (action.payload.stage === "RuleCandidates") {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        RuleCandidates: [
                            {
                                ...state.currentSelectionObj.RuleCandidates[0],
                                [action.payload.id]: action.payload.value
                            }
                        ]
                    }
                }
            }
            else {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        [action.payload.stage]: {
                            ...state.currentSelectionObj[action.payload.stage],
                            [action.payload.id]: action.payload.value
                        }
                    }
                }
            }
        case actionConstants.UPDATE_UI_RULES_INCLUSIONS_CABINET: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                BLACKFont: action.payload.blackFont
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_EXCLUSIONS_CABINET: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                Disabled: action.payload.exclude
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_SECTION_VISIBILITY_CABINET: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            SectionsVisibility: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules.SectionsVisibility,
                                [action.payload.id]: action.payload.value
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_FIELDS_VISIBILITY_CABINET: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                Visible: action.payload.visible
                            }
                        }
                    }
                }
            }
        }
        default:
            return state;
    }
}