import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

import { translation } from "@ccr-main/diapason-sharedlibrary-ui";

const dialogStyles = makeStyles((theme) => ({
    dialogTitle: {
        padding: "5px 10px !important",
        backgroundColor: "lightgray"
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: "space-between"
    },
    dialogHeaderText: {
        paddingTop: "4px"
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: "15px 15px !important",
        // height: "110px"
    },
    dialogActions: {
        padding: "5px 10px !important",
    }
}));

const ProjectImportModal = (props) => {

    const classes = dialogStyles();

    const { openProjectImportModal, closeProjectImportModal, title, projectNameError, importProject, projectName, updateProjectName,
        onFileSelect, fileError } = props;

    return (
        <Dialog onClose={closeProjectImportModal} aria-labelledby="project-upload-dialog" open={openProjectImportModal} maxWidth="sm" fullWidth={false}>
            <DialogTitle className={classes.dialogTitle} id="project-upload-dialog" onClose={closeProjectImportModal}>
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogHeaderText}>{title}</div>
                    <div><IconButton id="project-import-close-icon" className={classes.closeButton} onClick={closeProjectImportModal}><CloseIcon /></IconButton></div>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent} dividers>
                <Grid container rowSpacing={4} columnSpacing={4} columns={8}>
                    <Grid item xs={8}>
                        <TextField
                            id="import-project-name"
                            name="projectName"
                            label={translation("NewProjectProjectName")}
                            value={projectName}
                            onChange={updateProjectName}
                            inputProps={{ maxLength: 80 }}
                            error={typeof projectNameError !== "undefined" && projectNameError !== "" ? true : false}
                            helperText={projectNameError}
                            variant="standard"
                            fullWidth
                            autoFocus
                            sx={{ mb: 2, mt: 2 }}
                        />
                    </Grid>
                    <Grid item xs={8} style={{ display: "flex", flexDirection: "column", marginBottom: "16px", height: "75px" }}>
                        <input type="file" name="file" onChange={onFileSelect} />
                        <Typography variant="caption" sx={{ color: '#d32f2f' }}>{fileError}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    id="project-import-upload-btn"
                    variant="contained"
                    color="secondary"
                    startIcon={<FileUploadRoundedIcon />}
                    disabled={typeof projectNameError !== "undefined" && projectNameError !== "" ? true : false}
                    onClick={importProject}
                >
                    {translation("UploadNewProject")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProjectImportModal;