import { SHOW_PROGRESS_BAR, PROGRESS_BAR_OPERATION_NAME, UPDATE_PROGRESS_PERCENTAGE } from "../constants/constant";

const initialState = {
    showProgressBar: false,
    operationName: "",
    percentageCompleted: 0
};

export default function progressBarReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_PROGRESS_BAR:
            return {
                ...state,
                showProgressBar: action.payload
            }
        case PROGRESS_BAR_OPERATION_NAME:
            return {
                ...state,
                operationName: action.payload
            }
        case UPDATE_PROGRESS_PERCENTAGE:
            return {
                ...state,
                percentageCompleted: action.payload
            }
        default:
            return state;
    }
}