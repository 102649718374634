import React from "react";
import Text from './Text'
import { translation } from '@ccr-main/diapason-sharedlibrary-ui';
import Logo from './../../assets/images/CoolSystemsSelect.png';

import {
  withRouter
} from "react-router-dom";
import Reactour from "reactour";

export const Tour = withRouter(
  ({ isOpen, closeTour, location: { pathname }, history }) => {

    const steps = ([

      ...(
        pathname === "/"
          ? [
            {
              selector: '',
              content: () =>
                <div>
                  <img style={{ height: '65px', width: '170px' }} src={Logo} alt="Cool Systems Select" />
                  <Text color="#e5e5e5" size="20px" align="center">
                    {translation('webTour_mainpage1')}
                  </Text>
                  <Text color="#e5e5e5">
                    {translation('webTour_mainpage2')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }

            },
            {
              selector: '[data-tut="reactour__profile"]',
              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('webTour_multilanguage')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            },

            {
              selector: '.reactour__addproject',
              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('webTour_addproject')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            }
            ,
            {
              selector: '.reactour__importproject',
              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('webTour_importproject')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            },
            {
              selector: '.reactour__projecttable',
              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('webTour_projecttable')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            },
            {
              selector: '.reactour__projectdetails',
              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('webTour_projectdetail')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            },
            {
              selector: '.reactour__addselection',

              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('webTour_addselection')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            },
            {
              selector: '#dashboard-share-btn',

              content: () =>
                <div>
                  <Text color="#e5e5e5">
                    {translation('sharebutton')}
                  </Text>
                </div>,
              style: {
                backgroundColor: "#262c43",
                color: "white"
              }
            }
          ] :
           ( pathname === "/CondenserProductPage" 
            || pathname === "/RacksProductPage"
            || pathname === "/CDUProductPage"
            || pathname === "/EvaporatorProductPage"
            || pathname === "/DryCoolerProductPage"
            || pathname === "/BrineAirCoolerProductPage"
            || pathname === "/GasCoolerProductPage"
          ) ?
            [
              {
                selector: '#Card_0',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_productPageTile')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              },
              {
                selector: '#Btn_Back',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_productPageTile_backButton')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              },
              {
                selector: '#Btn_Next',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                    {translation('webTour_productPageTile_nextButton')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
            ]:
          pathname === "/CondenserProductDetailsPage" ?
            [
              {
                selector: '.Characteristics',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_characteristics')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
              , {
                selector: '[data-tut="reactour__HeatRejection"]',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_heatrejectionslider')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
              , {
                selector: '[data-tut="reactour__Soundbutton"]',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_accousticpressurebutton')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
              , {
                selector: '.Parameters',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_parameters')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
              , {
                selector: '.Savetemplate',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_savetemplate')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
              , {
                selector: '.Results',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_results')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
              , {
                selector: '.reactour__savebutton',
                content: () =>
                  <div>
                    <Text color="#e5e5e5">
                      {translation('webTour_savebutton')}
                    </Text>
                  </div>,
                style: {
                  backgroundColor: "#262c43",
                  color: "white"
                }
              }
            ]
            : pathname === "/EvaporatorProductDetailsPage" ?
              [
                {
                  selector: '.Characteristics',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_characteristics')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
                , {
                  selector: '[data-tut="reactour__HeatRejection"]',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_heatrejectionslider')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
                , {
                  selector: '[data-tut="reactour__Soundbutton"]',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_accousticpressurebutton')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
                , {
                  selector: '.Parameters',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_parameters')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
                , {
                  selector: '.Savetemplate',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_savetemplate')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
                , {
                  selector: '.Results',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_results')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
                , {
                  selector: '.reactour__savebutton',
                  content: () =>
                    <div>
                      <Text color="#e5e5e5">
                        {translation('webTour_savebutton')}
                      </Text>
                    </div>,
                  style: {
                    backgroundColor: "#262c43",
                    color: "white"
                  }
                }
              ] : pathname === "/DryCoolerProductDetailsPage" ?
                [
                  {
                    selector: '.Characteristics',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_characteristics')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                  , {
                    selector: '[data-tut="reactour__HeatRejection"]',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_heatrejectionslider')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                  , {
                    selector: '[data-tut="reactour__Soundbutton"]',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_accousticpressurebutton')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                  , {
                    selector: '.Parameters',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_parameters')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                  , {
                    selector: '.Savetemplate',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_savetemplate')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                  , {
                    selector: '.Results',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_results')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                  , {
                    selector: '.reactour__savebutton',
                    content: () =>
                      <div>
                        <Text color="#e5e5e5">
                          {translation('webTour_savebutton')}
                        </Text>
                      </div>,
                    style: {
                      backgroundColor: "#262c43",
                      color: "white"
                    }
                  }
                ] : pathname === "/BrineAirCoolerProductDetailsPage" ?
                  [
                    {
                      selector: '.Characteristics',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_characteristics')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '[data-tut="reactour__HeatRejection"]',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_heatrejectionslider')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '[data-tut="reactour__Soundbutton"]',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_accousticpressurebutton')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.Parameters',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_parameters')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.Savetemplate',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savetemplate')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.Results',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_results')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.reactour__savebutton',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savebutton')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                  ] : pathname === "/GasCoolerProductDetailsPage" ? [

                    {
                      selector: '.Characteristics',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_characteristics')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '[data-tut="reactour__HeatRejection"]',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_heatrejectionslider')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.reactour__Soundbutton',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_accousticpressurebutton')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.Parameters',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_parameters')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.Savetemplate',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savetemplate')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.Parameters',
                      // action:()=>
                      // {
                      //   document.getElementById("mainsection").scrollTop=0
                      // },
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_Parameters_tabs')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }

                    }
                    , {
                      selector: '.Results',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_results')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    , {
                      selector: '.reactour__savebutton',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savebutton')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                  ] : pathname === "/RacksProductDetailsPage" ? [

                    {
                      selector: '.Characteristics',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_characteristics')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    },
                    {
                      selector: '[data-tut="reactour__HeatRejection"]',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_heatrejectionslider')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    },
                    {
                      selector: '.Parameters',
                      // action:()=>
                      // {
                      //   document.getElementById("mainsection").scrollTop=0
                      // },
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_rack_Parameters_tabs')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }

                    },
                    {
                      selector: '.Savetemplate',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savetemplate')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    },
                    {
                      selector: '.Results',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_results')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }, {
                      selector: '.reactour__savebutton',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savebutton')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    ,


                  ] : pathname === "/CDUProductDetailsPage" ? [

                    {
                      selector: '.Characteristics',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_characteristics')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    },
                    {
                      selector: '[data-tut="reactour__HeatRejection"]',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_heatrejectionslider')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    },
                    {
                      selector: '.Parameters',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_rack_Parameters_tabs')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }

                    },
                    {
                      selector: '.Savetemplate',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savetemplate')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    },
                    {
                      selector: '.Results',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_results')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }, {
                      selector: '.reactour__savebutton',
                      content: () =>
                        <div>
                          <Text color="#e5e5e5">
                            {translation('webTour_savebutton')}
                          </Text>
                        </div>,
                      style: {
                        backgroundColor: "#262c43",
                        color: "white"
                      }
                    }
                    ,
                  ] : [
                    {
                      action: () => {
                        closeTour()
                      }
                    }

                  ])

    ]);

    const accentColor = "#5cb7b7";

    return (
      <Reactour
        steps={steps}
        isOpen={isOpen}
        onRequestClose={closeTour}
        accentColor={accentColor}
        update={pathname}
        rounded={5}
        startAt={0}
        // goToStep={step}
        className="helper"
        showNavigation={true}
        showButtons={true}
        badgeContent={(curr, tot) => `${curr}/${tot}`}
      />
    );
  }
);
