import { SHOW_CIRCULAR_LOADER, HIDE_CIRCULAR_LOADER, SHOW_PROPAGATE_LOADER, HIDE_PROPAGATE_LOADER } from '../constants/constant';

const initialState = {
    circularLoader: false,
    propagateLoader: false
};

export default function loaderReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_CIRCULAR_LOADER:
            return {
                ...state,
                circularLoader: true
            }
        case HIDE_CIRCULAR_LOADER:
            return {
                ...state,
                circularLoader: false
            }
        case SHOW_PROPAGATE_LOADER:
            return {
                ...state,
                propagateLoader: true
            }
        case HIDE_PROPAGATE_LOADER:
            return {
                ...state,
                propagateLoader: false
            }
        default:
            return state;
    }
}