import React, { useEffect, useState, useRef } from "react";
import { withRouter } from 'react-router';
import { injectIntl } from "react-intl";
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { logout } from "../../../auth-utils";
import { Button, Tooltip } from '@mui/material';
import { translation, injectIntlTranslation } from '@ccr-main/diapason-sharedlibrary-ui';
import AboutCSS from "./AboutCSS";

import '../../../App.scss';
import LangDropdown from "./langDropdown";

const ProfileDropdown = (props) => {
    const node = useRef();

    const [profileDialog, setProfileDialog] = useState(false);
    const [aboutDialog, setAboutDialog] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node.current != null && node.current.contains(e.target)) {
            return;
        }
        setProfileDialog(false);
    };

    const handleChange = selectedValue => {
        setAboutDialog(true);
        setProfileDialog(false)
    };

    const toggleDialog = () => {
        setAboutDialog(false);
    }

    const handleAdminPage = selectedValue => {
        props.history.push(`/DataManagement`);
        setProfileDialog(false);
    };

    const handleChangeLang = (lang) => {
        setProfileDialog(false);
        props.handleChangeLang(lang);
    }

    const handleSignOut = () => {
        logout();
    }

    const UserNameButton = () => {
        const userNameInitials = props.fullName
            .split(' ')
            .splice(0, 2)
            .map((i) => i.charAt(0))
            .join('');

        return (
            <Tooltip title={props.fullName}>
                <Button
                    role='button'
                    id="user-name-button"
                    className='user-name-button'
                    onClick={(e) => setProfileDialog(!profileDialog)}
                >
                    {userNameInitials}
                </Button>
            </Tooltip>
        );
    };

    return (
        <div ref={node} className="dropdown" id="profiledd" data-tut="reactour__profile">
            <span role="button" className="dropdown-toggler" onClick={e => setProfileDialog(!profileDialog)}>
                <UserNameButton />
            </span>
            {profileDialog && (
                <ul className="profile-dropdown-menu">
                    <p className='dropdown-switch-title'>{injectIntlTranslation(props.intl, "Welcome", "Welcome")} {props.fullName}</p>
                    <li onClick={e => handleChange()}><InfoRoundedIcon style={{ marginRight: "8px", fontSize: "15px", verticalAlign: "middle" }} /> <span>{translation("AboutDiapason")}</span></li>
                    <LangDropdown selectedItem={props.selectedLanguage} options={props.languageOptions} handleChangeLang={handleChangeLang} />
                    {props.showAdminPage && <li onClick={e => handleAdminPage()}><WorkRoundedIcon style={{ marginRight: "8px", fontSize: "15px", verticalAlign: "middle" }} /><span style={{ paddingLeft: 4 }}>{translation("DataManagementDropdownItem")}</span></li>}
                    <li onClick={e => handleSignOut()}><LogoutRoundedIcon style={{ marginRight: "8px", fontSize: "15px", verticalAlign: "middle" }} /> <span>{translation("LogOutDropdownItem")} </span></li>
                </ul>
            )}

            <AboutCSS
                toggleDialog={toggleDialog}
                open={aboutDialog}
                intl={props.intl}
            />


        </div>
    )

}

export default injectIntl(withRouter(ProfileDropdown));
