import React from 'react';
import { connect } from "react-redux";
import { CircularProgress, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 4,
        color: "#fff",
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.2)"
        // opacity: '0.5 !important'
    },
    circularSpinnerColor: {
        color: '#262c43'
    }
}));

const CircularLoader = (props) => {
    const classes = useStyles();

    return (
            <Backdrop className={classes.backdrop} open={props.circularLoader} id="backdrop-circular-loader" >
                <CircularProgress classes={{ colorPrimary: classes.circularSpinnerColor }} />
            </Backdrop>
    )
}

const mapStateToProps = (state) => ({
    circularLoader: state.loader.circularLoader
});

export default connect(mapStateToProps)(CircularLoader);