import { SHOW_SUCCESS_NOTIFICATION, SHOW_ERROR_NOTIFICATION, SHOW_WARNING_NOTIFICATION, SHOW_INFO_NOTIFICATION, HIDE_NOTIFICATION } from '../constants/constant';

const initialState = {
    visible: false,
    statusType: '',
    notificationText: '',
    isAutohide: true,
};

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_SUCCESS_NOTIFICATION:
            return getNextState(state, action.data, 'success');
        case SHOW_ERROR_NOTIFICATION:
            return getNextState(state, action.data, 'error');
        case SHOW_WARNING_NOTIFICATION:
            return getNextState(state, action.data, 'warning');
        case SHOW_INFO_NOTIFICATION:
            return getNextState(state, action.data, 'info');
        case HIDE_NOTIFICATION:
            return {
                ...state,
                visible: false
            }
        default:
            return state;
    }
}

const getNextState = (state, notificationData, statusType) => {
    return {
        ...state,
        visible: true,
        statusType,
        ...notificationData,
    }
}