import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import BuilderList from './BuilderList';
import DropdownMenu from '../dropdownMenu';
import { launchSelectionFlow } from '../../../../redux/Actions/launchSelectionFlow';
import workflowsConfig from '../../../WorkflowsConfig'

const BuilderMenu = (props) => {
    const {
        id,
        buttonProps,
        className,
        dropdownMenuClass,
        showHelpAndLibrary,
        filteredBy,
        //  builderList, userRole, 
        clickHandler,
        history,
        projectDetails,
        launchSelectionFlow,
        productPermissions
    } = props;

    const HasProductPermissions = (workflow) => {
        let showProduct = false;
        if (workflow.permissionGroups != null && workflow.permissionGroups.length > 0) {
            workflow.permissionGroups.forEach(permissionGroup => {
                if (productPermissions[permissionGroup] != null) {
                    showProduct = true;
                    if (showProduct)
                        return;
                }
            });
        }
        return showProduct;
    }

    // const hasRequiredPermission = (workflow, childWorkflow) => {
    //     let hasPermission = false;
    //     if (childWorkflow && childWorkflow.hasPermission) {
    //         hasPermission = true;
    //     }
    //     hasPermission = workflow.hasPermission;
    //     if (!hasPermission) {

    //     }
    //     else if (workflow.role && !workflow.role.includes(userRole)) {
    //         hasPermission = false;
    //     }
    //     return hasPermission;
    // }

    const helpOptionHandler = (isHelpRequired, workflow, childWorkflow, closeDropdown) => {
        navigationToUrl(isHelpRequired, 'ProductHelp', workflow, childWorkflow, closeDropdown);
    }

    const libraryOptionHandler = (isLibraryRequired, workflow, childWorkflow, closeDropdown) => {
        navigationToUrl(isLibraryRequired, 'ProductLibrary', workflow, childWorkflow, closeDropdown);
    }

    const navigationToUrl = (navigate, urlPath, workflow, childWorkflow, closeDropdown) => {
        if (navigate && HasProductPermissions(workflow, childWorkflow)) {
            closeDropdown();
            const url = `/${urlPath}?productBuilder=${workflow.id}&modelID=${childWorkflow.id}`;
            window.open(url, '_blank');
        }
    }

    const handleClick = (workflow, childWorkflow, closeDropdown) => {
        if (HasProductPermissions(workflow, childWorkflow) && !showHelpAndLibrary) {
            closeDropdown();
            if (clickHandler) {
                return clickHandler(workflow, childWorkflow, history, projectDetails);
            }
            launchSelectionFlow({ workflow, childWorkflow, history, projectDetails });
        }
    }

    return (
        <DropdownMenu
            id={id}
            buttonProps={buttonProps}
            className={className}
            dropdownMenuClass={dropdownMenuClass}
        >
            <BuilderList
                showHelpAndLibrary={showHelpAndLibrary}
                filteredBy={filteredBy}
                handleClick={handleClick}
                HasProductPermissions={HasProductPermissions}
                builderList={workflowsConfig}
                helpOptionHandler={helpOptionHandler}
                libraryOptionHandler={libraryOptionHandler}
            />
        </DropdownMenu>
    )
}

const mapStateToProps = (state) => ({
    builderList: state.getAllProductsReducer.builderList,
    userRole: state.locale.role,
    productPermissions: state.setBuilderPermissionsReducer.builderPermissions
})

export default withRouter(connect(mapStateToProps, { launchSelectionFlow })(BuilderMenu));