// export const fontFamily = `-apple-system, BlinkMacSystemFont,
//     'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
//     'Helvetical Neue', sans-serif;`;

export const fontFamily = 'Roboto';

export const headingSizes = [
  "2rem",
  "1.5rem",
  "1.25rem",
  "1rem",
  ".875rem",
  ".75rem"
];

export const themeColors = {
  dark: "#1c8f9e",
  light: "#5cb7b7",
  black: "#2d2323"
};
