import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    CabinetCharactristis: {},
    resultsGridSelections: [],
    tagTemplates: [],
    conceptTemplates: [],
    Inclusions: [],
    Visibility: [],
    DefaultSelection: []
};

export default function CabinetResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_RESPONSE_CABINET:
            return {
                ...state,
                BodyOverView: action.payload.BodyOverView,
                CabinetCharactristis: action.payload.CabinetCharactristis,
                Inclusions: action.payload.Inclusions,
                ProductConfiguration: action.payload.ProductConfiguration,
                Visibility: action.payload.Visibility,
                DefaultSelection: action.payload.DefaultSelection,
            }
        case actionConstants.UPDATE_CHARSTCS_RESPONSE_CABINET:
            return {
                ...state,
                CabinetCharactristis: action.payload
            }
        case actionConstants.UPDATE_INCLUSIONS_RESPONSE_CABINET:
            return {
                ...state,
                Inclusions: action.payload
            }
        case actionConstants.UPDATE_VISIBILITY_RESPONSE_CABINET:
            return {
                ...state,
                Visibility: action.payload
            }
        case actionConstants.UPDATE_DEFAULTSELECTION_RESPONSE_CABINET:
            return {
                ...state,
                DefaultSelection: action.payload
            }
        case actionConstants.UPDATE_TAG_TEMPLATES_CABINET:
            return {
                ...state,
                tagTemplates: action.payload
            }
        case actionConstants.RESET_CABINET_RESPONSE:
            return { ...initialState }

        default:
            return state;
    }
}