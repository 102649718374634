import { SET_CURRENT_ITEM, SET_CIRCUITS, TOGGLE_SIDE_DRAWER, SET_CURRENT_ITEM_OPTIONS, UPDATE_RESULT_INPUT_JSON_BY_TAGID, INSERT_RESULT_INPUT_JSON, UPDATE_SHOWHIDE_LIST_QUANTITY_SELLING_FIELDS, } from "../constants/selectionSummaryActionTypes";

const initialState = {
    circuits: [],
    currency: "€",
    openSideDrawer: false,
    resultInputJSONObj: {},
    selected: {
        tag: null,
        product: null,
        circuit: null,
        sideDrawer: {
            rowRightDrawer: null,
            initialData: null,
            includedEquipments: [],
            optionalEquipments: [],
        },
    },
    showListPriceField: true,
    showDiscountField: true,
    showSellingPriceField: true,
};

const newSelectionSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_ITEM:
            return {
                ...state,
                selected: { ...state.selected, ...action.payload },
            };
        case SET_CURRENT_ITEM_OPTIONS:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    sideDrawer: { ...state.selected.sideDrawer, ...action.payload },
                },
            };
        case TOGGLE_SIDE_DRAWER:
            return {
                ...state,
                openSideDrawer: action.payload,
            };

        case SET_CIRCUITS:
            return {
                ...state,
                circuits: action.payload,
            };

        case INSERT_RESULT_INPUT_JSON:
            return {
                ...state,
                resultInputJSONObj: action.payload
            }
        case UPDATE_RESULT_INPUT_JSON_BY_TAGID:
            return {
                ...state,
                resultInputJSONObj: {
                    ...state.resultInputJSONObj,
                    [action.payload.id]: action.payload.value
                }
            }
        case UPDATE_SHOWHIDE_LIST_QUANTITY_SELLING_FIELDS:
            return {
                ...state,
                showListPriceField: action.payload.showListPriceField,
                showDiscountField: action.payload.showDiscountField,
                showSellingPriceField: action.payload.showSellingPriceField,
            }
        default:
            return state;
    }
};

export default newSelectionSummaryReducer;
