import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    characteristics: {},
    parameters: {},
    resultsGridSelections: [],
    tagTemplates: [],
    conceptTemplates: []
};

export default function cduResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_CHARSTCS_RESPONSE_CDU:
            return {
                ...state,
                characteristics: action.payload
            }
        case actionConstants.UPDATE_PARAMS_VALUES_CDU:
            return {
                ...state,
                parameters: action.payload
            }
        case actionConstants.UPDATE_TAG_TEMPLATES_CDU:
            return {
                ...state,
                tagTemplates: action.payload
            }
        case actionConstants.RESET_CDU_RESPONSE_OBJ:
            return { ...initialState }

        default:
            return state;
    }
}