import {
    UPDATE_TAG_SELECTION,
    SELECTION_SUMMARY_LOADING
} from "../constants/constant";

const selectionSummaryReducer = (state = { selectedTags: [] }, action) => {
    switch (action.type) {
        case UPDATE_TAG_SELECTION:
            return {
                ...state,
                selectedTags: action.payload
            }

        case SELECTION_SUMMARY_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }

        default: return state;
    }
}

export default selectionSummaryReducer