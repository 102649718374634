import { combineReducers } from 'redux';
import pipingResponseReducer from './pipingResponseReducer';
import pipingSelectionObjReducer from './pipingSelectionObjReducer';
import pipeLinesDataReducer from './pipeLinesDataReducer';
import pipingTrackReducer from './pipingTrackReducer';

const pipingReducer = combineReducers({
    pipingResponse: pipingResponseReducer,
    pipingSelectionObj: pipingSelectionObjReducer,
    pipeLinesData: pipeLinesDataReducer,
    pipeTracksData: pipingTrackReducer
});

export default pipingReducer;