import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";
import produce from "immer";

const initialState = {
    isEditMode: false,
    isTextFieldUpdated: false,
    isInitialLoadPipeSelObjCompleted: false,
    userModidfiedFields: [],
    currSelectedCircuitId: {},
    defaultSettingsObj: {},
    editSelectionObj: {},
    currentSelectionObj: {},
    defaultPipeSelectionObj: {},
    currentPipeSelectionObj: {}
};

export default function pipingSelectionObjReducer(state = { ...initialState }, action) {

    switch (action.type) {

        case actionConstants.SET_CURRENT_SELECTED_CIRCUITID:
            return {
                ...state,
                currSelectedCircuitId: action.payload
            }

        case actionConstants.SET_EDIT_MODE_PIPING:
            return {
                ...state,
                isEditMode: action.payload
            }

        case actionConstants.RESET_USER_MODIFIED_FIELDS_PIPING:
            return {
                ...state,
                userModidfiedFields: [""]
            }


        case actionConstants.RESET_PIPING_SELECTION_OBJ:
            return { ...initialState }

        case actionConstants.UPDATE_DEFAULT_OBJ_PIPING:
            return {
                ...state,
                defaultSettingsObj: action.payload
            }
        case actionConstants.SET_EDIT_SELECTION_OBJ_PIPING:
            return {
                ...state,
                editSelectionObj: action.payload
            }
        case actionConstants.SET_CURRENT_SELECTION_OBJ_PIPING:
            return {
                ...state,
                currentSelectionObj: action.payload
            }
        case actionConstants.TEXTFIELD_UPDATED_PIPING:
            return {
                ...state,
                isTextFieldUpdated: action.payload
            }
        case actionConstants.UPDATE_INITIAL_OBJ_LOAD_PIPING:
            return {
                ...state,
                isInitialLoadPipeSelObjCompleted: action.payload
            }
        case actionConstants.RESET_PIPING_RESULT_GRID_DEPENDENTS:
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        IsPerformanceImpactUpdated: false,
                        SelectedCandidateIds: [],
                        UserSelectedCandidates: [],
                        row: {},
                        Options: [],
                        // ComparativeModeCandidates: [],
                    }
                }
            }
        case actionConstants.UPDATE_CURRENT_SELECTION_OBJ_PIPING:
            if (action.payload.stage === "RuleCandidates") {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        RuleCandidates: [
                            {
                                ...state.currentSelectionObj.RuleCandidates[0],
                                [action.payload.id]: action.payload.value
                            }
                        ]
                    }
                }
            }
            else {
                return {
                    ...state,
                    currentSelectionObj: {
                        ...state.currentSelectionObj,
                        [action.payload.stage]: {
                            ...state.currentSelectionObj[action.payload.stage],
                            [action.payload.id]: action.payload.value
                        }
                    }
                }
            }
        case actionConstants.UPDATE_UI_RULES_INCLUSIONS_PIPING: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                BLACKFont: action.payload.blackFont
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_EXCLUSIONS_PIPING: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                Disabled: action.payload.exclude
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_SECTION_VISIBILITY_PIPING: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            SectionsVisibility: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules.SectionsVisibility,
                                [action.payload.id]: action.payload.value
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.UPDATE_UI_RULES_FIELDS_VISIBILITY_PIPING: {
            return {
                ...state,
                currentSelectionObj: {
                    ...state.currentSelectionObj,
                    SaveInputData: {
                        ...state.currentSelectionObj.SaveInputData,
                        UI_Rules: {
                            ...state.currentSelectionObj.SaveInputData.UI_Rules,
                            [action.payload.id]: {
                                ...state.currentSelectionObj.SaveInputData.UI_Rules[action.payload.id],
                                Visible: action.payload.visible
                            }
                        }
                    }
                }
            }
        }
        case actionConstants.SET_CURRENT_PIPE_SELECTION_OBJ: {
            return {
                ...state,
                currentPipeSelectionObj: action.payload
            }
        }
        case actionConstants.SET_DEFAULT_PIPE_SELECTION_OBJ: {
            return {
                ...state,
                defaultPipeSelectionObj: action.payload
            }
        }
        case actionConstants.UPDATE_CIRCUIT_SELECTION_OBJ: {
            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex] = action.payload.value
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }
        }
        case actionConstants.UPDATE_LINE_SELECTION_OBJ: {

            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex][action.payload.section][action.payload.labelId] = action.payload.value
                        }
                    })
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }
        }
        case actionConstants.UPDATE_LINE_SELECTION_OBJ_COMMON_DATA: {

            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex]['SaveInputData'][action.payload.labelId] = action.payload.value
                        }
                    })
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }


        }
        case actionConstants.SET_CURRENT_PIPE_LINE_SELECTION_OBJ: {

            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex] = action.payload.value
                        }
                    })
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }
        }
        case actionConstants.SET_DEFAULT_PIPE_LINE_SELECTION_OBJ: {

            let newPipeSelectionObjState = produce(state.defaultPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex] = action.payload.value
                        }
                    })
                }
            })

            return {
                ...state,
                defaultPipeSelectionObj: newPipeSelectionObjState
            }
        }
        case actionConstants.UPDATE_LINE_SYSTEM_TYPE: {
            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex].systemType = action.payload.value
                        }
                    })
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }

        }
        case actionConstants.UPDATE_USER_MODIFIED_FIELDS_PIPING: {
            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex].SaveInputData.userModifiedFields = [...new Set(lineDraft[lineIndex].SaveInputData.userModifiedFields.concat(action.payload.id))]
                        }
                    })
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }
        }
        case actionConstants.UPDATE_PIPE_NETWORK_GRID_DATA: {
            let newPipeSelectionObjState = produce(state.currentPipeSelectionObj, circuitDraft => {
                const circuitIndex = circuitDraft.findIndex(item => item.circuitId === action.payload.circuitId)
                if (circuitIndex !== -1) {
                    circuitDraft[circuitIndex].pipeObj = produce(circuitDraft[circuitIndex].pipeObj, lineDraft => {
                        const lineIndex = lineDraft.findIndex(item => item.lineId === action.payload.lineId)
                        if (lineIndex !== -1) {
                            lineDraft[lineIndex].ConnectedIntoPipeNetwork.PipeNetworkGridRows = produce(lineDraft[lineIndex].ConnectedIntoPipeNetwork.PipeNetworkGridRows, gridDataDraft => {
                                const rowIndex = gridDataDraft.findIndex(item => item.PipeSection === action.payload.pipeSectionId)
                                if (rowIndex !== -1) {
                                    gridDataDraft[rowIndex][action.payload.id] = action.payload.value
                                }
                            })
                        }
                    })
                }
            })

            return {
                ...state,
                currentPipeSelectionObj: newPipeSelectionObjState
            }
        }
        default:
            return state;
    }
}