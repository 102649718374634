import {
    GET_ALL_PRODUCTS_START,
    GET_ALL_PRODUCTS_FULFILLED,
    GET_ALL_PRODUCTS_FAILED,
    UPDATE_BUILDER_LIST_WITH_PERMISSION,
    UPDATE_ALL_BUILDER_ROUTES
} from './../constants/constant';

const initialState = {
    isLoading: false,
    allBuilderRoutes: [],
    builderList: [],
    AllProductBuilders: [],
    AllProductBuilderModels: []
};

export default function getAllProductReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PRODUCTS_START:
            return {
                ...state,
                isLoading: true
            }
        case GET_ALL_PRODUCTS_FULFILLED:
            return {
                ...state,
                isLoading: false,
                AllProductBuilders: action.data.AllProductBuilders,
                AllProductBuilderModels: action.data.AllProductBuilderModels
            }
        case GET_ALL_PRODUCTS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case UPDATE_BUILDER_LIST_WITH_PERMISSION:
            return {
                ...state,
                builderList: action.data.builderList
            }
        case UPDATE_ALL_BUILDER_ROUTES:
            return {
                ...state,
                allBuilderRoutes: action.data
            }
        default:
            return state;
    }
}