import { combineReducers } from 'redux';
import condenserReducer from './condenser/condenserReducer';
import evaporatorReducer from './evaporator/evaporatorReducer';
import drycoolerReducer from './drycooler/drycoolerReducer';
import brinecoolerReducer from './brinecooler/brinecoolerReducer';
import gascoolerReducer from './gascooler/gascoolerReducer';
import racksReducer from './racks/racksReducer';
import electricReducer from './electricpanel/electricReducer';
import coldroomReducer from './coldroom/coldroomReducer';
import cduReducer from './cdu/cduReducer';
import pipingReducer from './piping/pipingReducer';
import cabinetReducer from './Cabinet/CabinetReducer';

import refregirationsupplyReducer from './refregirationsupply/refregirationsupplyReducer'
import treeStructureResponseReducer from '../TreeStructure/selectedTreeStructureReducer';
import cabinetmanualReducer from './cabinetmanual/cabinetmanualReducer';
import cabinetotherconsumerReducer from './cabinetotherconsumer/cabinetotherconsumerReducer';
import cabinetsummaryReducer from './CabinetSummary/cabinetsummaryReducer';

const productsReducer = combineReducers({
    condenser: condenserReducer,
    evaporator: evaporatorReducer,
    drycooler: drycoolerReducer,
    brinecooler: brinecoolerReducer,
    gascooler: gascoolerReducer,
    racks: racksReducer,
    electricPanel: electricReducer,
    cabinet: cabinetReducer,
    coldroom: coldroomReducer,
    cdu: cduReducer,
    piping: pipingReducer,
    refregirationsupply: refregirationsupplyReducer,
    treeStructure: treeStructureResponseReducer,
    cabinetmanual: cabinetmanualReducer,
    cabinetotherconsumer: cabinetotherconsumerReducer,
    cabinetsummary: cabinetsummaryReducer,
});

export default productsReducer;