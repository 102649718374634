import React from "react";
import { connect } from "react-redux";
import { Snackbar, Alert } from '@mui/material'
import { hideNotification } from "@ccr-main/diapason-sharedlibrary-ui"

const SnackbarNotification = (props) => {
    const { visible, statusType, notificationText, hideNotification, isAutohide } = props;

    // const closeNotification = (event, reason) => {
    //     hideNotification();
    // }

    const closeSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        hideNotification();
    }
    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={visible} autoHideDuration={isAutohide ? 6000 : null} onClose={closeSnackBar} style={{ whiteSpace: "pre-line" }}>
            <Alert variant='filled' onClose={closeSnackBar} severity={statusType}>
                {notificationText}
            </Alert>
        </Snackbar>
    )
}

const mapStateToProps = (state) => ({
    visible: state.notification.visible,
    statusType: state.notification.statusType,
    notificationText: state.notification.notificationText,
    isAutohide: state.notification.isAutohide,
});

export default connect(mapStateToProps, { hideNotification })(SnackbarNotification);