
import {
    FETCH_PROJECTLIST_START,
    FETCH_PROJECTLIST_FULFILLED,
    FETCH_PROJECTLIST_FAILED,
    NEED_PROJECTLIST_REFRESH,
    SAVE_DROPDOWN_VALUE,
    UPDATE_PROJECT_GRID_TAB,
    UPDATE_PROJECT_GRID_STATE,
    OPEN_OPPORTUNITY_ID,
    UPDATE_DASHBOARD_OPPORTUNITY_ID,
    UPDATE_DASHBOARD_PROJECT_SEARCH_FILTER,
    UPDATE_DASHBOARD_PROJECT_SEARCH_COLUMN
} from '../constants/constant';
import { actionConstants, projectListColumn, sortingOrder, projectType } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    records: [],
    totalCount: 0,
    noRecords: 20,
    isLoading: false,
    needRefresh: false,
    error: '',
    NewlySharedProjectsCount: 0,
    order: sortingOrder.descending,
    orderBy: projectListColumn.LastModifiedDate,
    rowsPerPage: 10,
    page: 0,
    searchColumn: 'all',
    searchText: "",
    selectedFilter: "DisplayAll",
    opportunityStageId: OPEN_OPPORTUNITY_ID,
    projectType: projectType.AllProjects,
    Projects: [{}],
    CustomerDetails: [{}],
    exisitingProjDetails: {
        ProjectTagGroupdetails: [],
        ProjectGroupDetails: [{}],
        TagDetails: [{}]
    },
    currentProjDetails: {
        ProjectTagGroupdetails: [],
        ProjectGroupDetails: [{}],
        TagDetails: [{}]
    }
};

const getProjectListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROJECTLIST_START:
            return {
                ...state,
                needRefresh: false,
                isLoading: true
            }
        case FETCH_PROJECTLIST_FULFILLED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                totalCount: action.data.TotalCount,
                NewlySharedProjectsCount: action.data.NewlySharedProjectsCount,
                records: action.data.Projects
            }
        case FETCH_PROJECTLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                needRefresh: false,
                error: action.data
            }
        case UPDATE_PROJECT_GRID_STATE:
            return {
                ...state,
                ...action.data
            }
        case NEED_PROJECTLIST_REFRESH:
            return {
                ...state,
                needRefresh: true
            }
        case SAVE_DROPDOWN_VALUE:
            return {
                ...state,
                noRecords: action.data
            }
        case actionConstants.PRJ_CUST_DETAILS:
            return {
                ...state,
                Projects: action.data.Projects,
                CustomerDetails: action.data.CustomerDetails,
                exisitingProjDetails: {
                    ...state.exisitingProjDetails,
                    ProjectTagGroupdetails: action.data.ProjectTagGroupdetails,
                }
            }

        case actionConstants.PRJ_GRP_DETAILS:
            if (action && action.data && action.data.type === "existing") {
                return {
                    ...state,
                    exisitingProjDetails: {
                        ...state.exisitingProjDetails,
                        ProjectTagGroupdetails: action.data.ProjectTagGroupdetails,
                        ProjectGroupDetails: action.data.ProjectGroupDetails,
                        TagDetails: action.data.TagDetails
                    }
                }
            }
            else {
                return {
                    ...state,
                    currentProjDetails: {
                        ...state.currentProjDetails,
                        ProjectTagGroupdetails: action.data.ProjectTagGroupdetails,
                        ProjectGroupDetails: action.data.ProjectGroupDetails,
                        TagDetails: action.data.TagDetails
                    }
                }
            }
        case UPDATE_PROJECT_GRID_TAB:
            return {
                ...state,
                page: 0,
                needRefresh: true,
                records: [],
                totalCount: 0,
                projectType: action.data.projectType
            }

        case UPDATE_DASHBOARD_OPPORTUNITY_ID:
            return {
                ...state,
                opportunityStageId: action.payload
            }

        case UPDATE_DASHBOARD_PROJECT_SEARCH_FILTER:
            return {
                ...state,
                selectedFilter: action.payload
            }
        case UPDATE_DASHBOARD_PROJECT_SEARCH_COLUMN:
            return {
                ...state,
                searchColumn: action.payload
            }
        default:
            return state;
    }
}

export default getProjectListReducer
