import React, { useState, cloneElement } from 'react';
import { makeStyles } from "@mui/styles";
// import CustomButton from './CustomButton';
import CustomButton from '../../NewDashboard/Common/CustomButton/CustomButton'
import classNames from 'classnames';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const useStyles = makeStyles((theme) => ({
    dropdownMenu: { 
        position: "absolute",
        backgroundColor: "#ffffff",
        zIndex: "999",
        borderRadius: "4px",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
        border: "1px solid grey"
    },
    disableCursor: {
        cursor: "not-allowed"
    }
}));

const DropdownMenu = (props) => {
    const { id, children, buttonProps, className, dropdownMenuClass } = props;
    const { dropdownMenu, disableCursor } = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickAway = () => {
        setOpen(false);
    }

    const handleBtnClick = (event) => {
        setOpen(!open);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div id={id} className={classNames(className, 'dropdownButton', buttonProps.disabled && disableCursor)} >
                <CustomButton onClick={handleBtnClick} showDropdownIcon {...buttonProps} className={classNames('reactour__addselection')}/>
                {open && (
                    <div className={classNames(dropdownMenuClass, dropdownMenu,'reactour__modeltype')}>
                        {children && cloneElement(children, { closeDropdown: handleBtnClick })}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    )
}

export default DropdownMenu;
