import { combineReducers } from 'redux';
import cabinetmanualResponseReducer from './cabinetmanualResponseReducer';
import cabinetmanualSelectionObjReducer from './cabinetmanualSelectionObjReducer';
// import condenserSelectionObjReducer from './condenserSelectionObjReducer';

const cabinetmanualReducer = combineReducers({
    cabinetmanualResponse: cabinetmanualResponseReducer,
    cabinetmanualSelectionObj: cabinetmanualSelectionObjReducer,
});

export default cabinetmanualReducer;