import { actionConstants } from "@ccr-main/diapason-sharedlibrary-ui";

const initialState = {
    DefaultSelection: [],
    Inclusions: [],
    PipingData: {
        ConnectedIntoPipeNetwork: {},
        ListOfPipeMaterial: {},
        MaterialSet: {},
        Parameters: {},
        PipeSections: {},
        PipeTrackSelection: {},
        ResultSummary: {},
        ResultsDesuperheater: {},
        TemperatureDifference: {}
    },
    Visibility: [],
    successResponse: false
};

export default function pipingResponseReducer(state = initialState, action) {
    switch (action.type) {
        case actionConstants.UPDATE_PIPING_RESPONSE: {
            return {
                ...state,
                DefaultSelection: action.payload.DefaultSelection,
                Inclusions: action.payload.Inclusions,
                PipingData: action.payload.PipingData,
                Visibility: action.payload.Visibility
            }
        }
        case actionConstants.RESET_PIPING_RESPONSE: {
            return { ...initialState }
        }
        case actionConstants.UPDATE_PIPING_RESPONSE_STATE: {
            return {
                ...state,
                successResponse: action.payload
            }
        }
        default:
            return state;
    }
}