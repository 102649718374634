import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { enqueueSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';

import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import makeStyles from '@mui/styles/makeStyles';

import {
    translation,
    getProjectList,
    createNewProject,
    RandomNumber,
    addProjectFormValidator as formValidator,
    injectIntlTranslation,
    filterItems,
    groupItems,
    sortingOrder,
    projectListColumn,
    projectType,
} from '@ccr-main/diapason-sharedlibrary-ui';

import BuilderMenu from '../common/controls/builderList/builderMenu';
import { launchSelectionFlow } from '../../redux/Actions/launchSelectionFlow';
import { updateProjectGridState } from "../../redux/Actions/updateProjectListState";
import { OPEN_OPPORTUNITY_ID } from "../../redux/constants/constant";

export const dialogStyles = makeStyles((theme) => ({
    dialogTitle: {
        padding: "5px 10px !important",
        backgroundColor: "lightgray"
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: "space-between"
    },
    dialogHeaderText: {
        paddingTop: "4px"
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: "15px 15px !important",
    },
    dialogActions: {
        padding: "5px 10px !important",
    },
    panelRoot: {
        padding: 0,
        minHeight: "310px"
    },
    tabLabel: {
        textTransform: "none",
        fontWeight: "600 !important"
    },
    tab: {
        width: '20%',
        paddingBottom: '0px !important',
        paddingRight: '0px !important'
    }
}));

const filter = createFilterOptions();

const AddProjectModal = (props) => {

    const classes = dialogStyles();
    const dispatch = useDispatch();

    const { openAddProjectModal, closeAddProjectDialog, intl } = props;

    const userCustomers = useSelector(state => state.getUserCustomers.records)
    // const projectInfo = useSelector(state => state.getProjectList)
    const projectTypeOptionsData = useSelector(state => state.createNewProject.projectTypeOptions)
    const accountAreaNameDetails = useSelector(state => state.createNewProject.areaDetails)
    const isPFIUser = useSelector(state => state.createNewProject.isPFIUser)
    const projectStatusOptionsData = useSelector(state => state.createNewProject.projectStatusOptions)

    const applicationTypeOptionsData = useSelector(state => state.createNewProject.applicationTypeOptions)
    const groupedAppListData = applicationTypeOptionsData.length > 0 ? groupItems(applicationTypeOptionsData, "category") : {}

    const [isValidForm, setIsValidForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disableCustContactFields, setDisableCustContactFields] = useState(true);

    const [addProjectFields, setAddProjectFields] = useState({
        projectName: "Project " + RandomNumber(),
        tagName: "",
        groupName: "",
        projectTypeId: projectTypeOptionsData.length > 0 ? projectTypeOptionsData[0].projectTypeID : "",
        projectStatusId: projectStatusOptionsData.length > 0 ? projectStatusOptionsData[0].projectStatusID : "",
        applicationTypeId: applicationTypeOptionsData.length > 0 ? applicationTypeOptionsData.filter(data => (data.DefaultColumn === true || data.DefaultColumn === "true"))[0].applicationID : "",
        projectGroupName: "",
        custName: "",
        custContactName: "",
        custContactEmail: "",
        custContactNum: "",
        accountareaname: isPFIUser ? (accountAreaNameDetails.length > 0 ? accountAreaNameDetails[0].AreaName : "") : '',
    })

    const [addProjectFieldsErrors, setAddProjectFieldsErrors] = useState({
        projectName: "",
        tagName: "",
        groupName: "",
        projectTypeId: "",
        projectStatusId: "",
        applicationTypeId: "",
        projectGroupName: "",
        custName: "",
        custContactName: "",
        custContactEmail: "",
        custContactNum: "",
        accountareaname: ""
    })

    useEffect(() => {
        if (projectTypeOptionsData.length) {
            let addProjFields = { ...addProjectFields };
            addProjFields.projectTypeId = projectTypeOptionsData[0].projectTypeID
            setAddProjectFields(addProjFields)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectTypeOptionsData.length])

    useEffect(() => {
        if (projectStatusOptionsData.length) {
            let addProjFields = { ...addProjectFields };
            addProjFields.projectStatusId = projectStatusOptionsData[0].projectStatusID
            setAddProjectFields(addProjFields)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectStatusOptionsData.length])

    useEffect(() => {
        if (applicationTypeOptionsData.length) {
            let addProjFields = { ...addProjectFields };
            addProjFields.applicationTypeId = applicationTypeOptionsData.filter(data => (data.DefaultColumn === true || data.DefaultColumn === "true"))[0].applicationID
            setAddProjectFields(addProjFields)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationTypeOptionsData.length])

    useEffect(() => {
        if (accountAreaNameDetails.length && isPFIUser) {
            let addProjFields = { ...addProjectFields };
            addProjFields.accountareaname = accountAreaNameDetails[0].AreaName
            setAddProjectFields(addProjFields)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountAreaNameDetails.length])
    const validateNewProjectForm = (id, e) => {
        const { errors, fields, formIsValid } = formValidator(id, e, e.target.value, { ...addProjectFields }, { ...addProjectFieldsErrors }, intl)
        setAddProjectFields(fields);
        setAddProjectFieldsErrors(errors);
        setIsValidForm(formIsValid)
    }
    //eslint-disable-next-line no-unused-vars
    const onCustInputChange = (value) => {

        let fields = { ...addProjectFields };
        fields.custName = value;
        fields.custId = "";
        fields.custContactName = "";
        fields.custContactEmail = "";
        fields.custContactNum = "";

        setAddProjectFields(fields);

        if (value === "") {
            setDisableCustContactFields(true);
        }
        else {
            setDisableCustContactFields(false);
        }

        let errorFields = { ...addProjectFieldsErrors };
        errorFields.custName = "";
        errorFields.custId = "";
        errorFields.custContactName = "";
        errorFields.custContactEmail = "";
        errorFields.custContactNum = "";
        setAddProjectFieldsErrors(errorFields);
    }

    //eslint-disable-next-line no-unused-vars
    const onCustNameChange = (selectedCustomer) => {

        if (selectedCustomer) {
            setDisableCustContactFields(true);

            let fields = { ...addProjectFields };

            fields.custName = selectedCustomer.customerName;
            fields.custId = selectedCustomer.customerID;
            fields.custContactName = selectedCustomer.companyName;
            fields.custContactEmail = selectedCustomer.email;
            fields.custContactNum = selectedCustomer.phone;

            setAddProjectFields(fields);

            let errorFields = { ...addProjectFieldsErrors };
            errorFields.custName = "";
            errorFields.custId = "";
            errorFields.custContactName = "";
            errorFields.custContactEmail = "";
            errorFields.custContactNum = "";
            setAddProjectFieldsErrors(errorFields);
        }
    }

    const onCustomerValueChange = (event, value, changeType) => {

        if (value.trim() !== "") {
            let customer = filterItems(userCustomers, (item) => { return item.customerName === value })

            if (customer.length > 0) {
                let fields = { ...addProjectFields };
                fields.custName = customer[0].customerName;
                fields.custId = customer[0].customerID;
                fields.custContactName = customer[0].companyName;
                fields.custContactEmail = customer[0].email;
                fields.custContactNum = customer[0].phone;

                setAddProjectFields(fields);
                setDisableCustContactFields(true);
            }
            else {
                if (value.length <= 40) {
                    let fields = { ...addProjectFields };
                    fields.custName = value;
                    fields.custId = "";
                    fields.custContactName = "";
                    fields.custContactEmail = "";
                    fields.custContactNum = "";

                    setAddProjectFields(fields);
                    setDisableCustContactFields(false);

                    let errorFields = { ...addProjectFieldsErrors };
                    errorFields.custName = "";
                    errorFields.custId = "";
                    errorFields.custContactName = "";
                    errorFields.custContactEmail = "";
                    errorFields.custContactNum = "";
                    setAddProjectFieldsErrors(errorFields);
                    setIsValidForm(true);
                } else {
                    let errorFields = { ...addProjectFieldsErrors };
                    errorFields.custName = translation('MaxLengthError');
                    errorFields.custId = "";
                    errorFields.custContactName = "";
                    errorFields.custContactEmail = "";
                    errorFields.custContactNum = "";
                    setAddProjectFieldsErrors(errorFields);
                    setIsValidForm(false);
                }
            }
        }
    }

    const handleCustomerChange = (event, selectedCustomer, changeType) => {
        if (changeType !== "clear" && changeType === "select-option") {
            setDisableCustContactFields(true);

            let fields = { ...addProjectFields };

            fields.custName = selectedCustomer.customerName;
            fields.custId = selectedCustomer.customerID;
            fields.custContactName = selectedCustomer.companyName;
            fields.custContactEmail = selectedCustomer.email;
            fields.custContactNum = selectedCustomer.phone;

            setAddProjectFields(fields);

            let errorFields = { ...addProjectFieldsErrors };
            errorFields.custName = "";
            errorFields.custId = "";
            errorFields.custContactName = "";
            errorFields.custContactEmail = "";
            errorFields.custContactNum = "";
            setAddProjectFieldsErrors(errorFields);
        }
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);

        if (
            !options.find(
                (option) =>
                    option.customerName.toString().toLowerCase() ===
                    params.inputValue.toString().trim().toLowerCase()
            ) &&
            params.inputValue.trim() !== ""
        ) {
            filtered.push({
                inputValue: params.inputValue,
                customerName: `Add "${params.inputValue}"`,
            });
        }
        return filtered;
    };

    const handleSubmit = () => {
        setLoading(true)
        dispatch(createNewProject(
            '',
            addProjectFields.projectName,
            addProjectFields.projectTypeId,
            addProjectFields.projectStatusId,
            addProjectFields.applicationTypeId,
            addProjectFields.minAmbientTemp,
            addProjectFields.maxAmbientTemp,
            addProjectFields.custId,
            addProjectFields.custName,
            addProjectFields.custContactName,
            addProjectFields.custContactNum,
            addProjectFields.custContactEmail,
            addProjectFields.accountareaname,
            createNewProjectResponse
        ))
    }

    const createNewProjectResponse = (response) => {
        setLoading(false)
        if (response.ProjectId) {
            enqueueSnackbar(injectIntlTranslation(intl, "ProjectSaveSuccessMessage").replace('_PROJECTNAME_', "\"" + addProjectFields.projectName + "\""), { variant: 'success' });
            dispatch(updateProjectGridState({
                searchText: "",
                searchColumn: "",
                page: 0,
                rowsPerPage: 10,
                order: sortingOrder.descending,
                orderBy: projectListColumn.LastModifiedDate,
                selectedFilter: "DisplayAll",
                opportunityStageId: OPEN_OPPORTUNITY_ID,
                projectType: projectType.AllProjects,
            }));
            dispatch(getProjectList(
                1,
                10,
                `${projectListColumn.LastModifiedDate}_${sortingOrder.descending}`,
                "",
                "",
                projectType.AllProjects,
                OPEN_OPPORTUNITY_ID
            ));
            closeAddProjectDialog();
        } else {
            enqueueSnackbar(injectIntlTranslation(intl, 'ProjectNameAlreadyExists'), { variant: 'error' });
        }
    }

    const OnSaveNavigateTemplate = (workflow, childWorkflow, history) => {
        dispatch(createNewProject(
            '',
            addProjectFields.projectName,
            addProjectFields.projectTypeId,
            addProjectFields.projectStatusId,
            addProjectFields.applicationTypeId,
            addProjectFields.minAmbientTemp,
            addProjectFields.maxAmbientTemp,
            addProjectFields.custId,
            addProjectFields.custName,
            addProjectFields.custContactName,
            addProjectFields.custContactNum,
            addProjectFields.custContactEmail,
            addProjectFields.accountareaname,
            (response) => {
                if (response.ProjectId) {
                    enqueueSnackbar(injectIntlTranslation(intl, "ProjectSaveSuccessMessage").replace('_PROJECTNAME_', "\"" + addProjectFields.projectName + "\""), { variant: 'success' });
                    closeAddProjectDialog();
                    let projectDetails = { route: "From selection", ProjectName: addProjectFields.projectName, ProjectID: response.ProjectId };
                    dispatch(launchSelectionFlow({ workflow, childWorkflow, history, projectDetails }))
                } else {
                    enqueueSnackbar(injectIntlTranslation(intl, 'ProjectNameAlreadyExists'), { variant: 'error' });
                }
            }
        ))
    }

    return (
        <Dialog onClose={closeAddProjectDialog} aria-labelledby="add-project-dialog" open={openAddProjectModal} maxWidth="sm" fullWidth={true}>

            <DialogTitle className={classes.dialogTitle} id="add-project-dialog" onClose={closeAddProjectDialog}>
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogHeaderText}>{translation("AddNewProject")}</div>
                    <div><IconButton id="add-project-close-icon" className={classes.closeButton} onClick={closeAddProjectDialog}><CloseIcon /></IconButton></div>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Grid container rowSpacing={4} columnSpacing={4} columns={16}>
                    <Grid item xs={8}>
                        {/* Add Project  - Project Name */}
                        <TextField
                            id="add-project-name"
                            variant="standard"
                            fullWidth
                            required
                            label={translation("NewProjectProjectName")}
                            value={addProjectFields.projectName}
                            onChange={(e) => validateNewProjectForm("projectName", e)}
                            error={addProjectFieldsErrors.projectName !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.projectName)}
                            sx={{ mb: 2 }}
                            disabled={loading}
                        />

                        {/* Add Project - Project Type */}
                        <TextField
                            id="np-project-type"
                            variant="standard"
                            fullWidth
                            required
                            select
                            label={translation("ProjectType")}
                            value={addProjectFields.projectTypeId}
                            onChange={(e) => validateNewProjectForm("projectTypeId", e)}
                            error={addProjectFieldsErrors.projectTypeId !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.projectTypeId)}
                            sx={{ mb: 2 }}
                            disabled={loading}
                        >
                            {projectTypeOptionsData.length > 0 && projectTypeOptionsData.map(option => <MenuItem id={option.projectTypeID} key={option.projectTypeID} value={option.projectTypeID}> {option.projectTypeName} </MenuItem>)}
                        </TextField>

                        {/* Add Project - Project Status */}
                        <TextField
                            id="np-project-status"
                            variant="standard"
                            fullWidth
                            required
                            select
                            label={translation("ProjectStatus")}
                            value={addProjectFields.projectStatusId}
                            onChange={(e) => validateNewProjectForm("projectStatusId", e)}
                            error={addProjectFieldsErrors.projectStatusId !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.projectStatusId)}
                            sx={{ mb: 2 }}
                            disabled={loading}
                        >
                            {projectStatusOptionsData.length > 0 && projectStatusOptionsData.map(option => <MenuItem id={option.projectStatusID} key={option.projectStatusID} value={option.projectStatusID}>{option.projectStatusName}</MenuItem>)}
                        </TextField>

                        {/* Add Project - Project Application */}
                        <TextField
                            id="np-application-type"
                            variant="standard"
                            fullWidth
                            required
                            select
                            label={translation("ProjectApplication")}
                            value={addProjectFields.applicationTypeId}
                            onChange={(e) => validateNewProjectForm("applicationTypeId", e)}
                            error={addProjectFieldsErrors.applicationTypeId !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.applicationTypeId)}
                            sx={{ mb: 2 }}
                            SelectProps={{
                                renderValue: (option) => {
                                    const selectedApp = applicationTypeOptionsData.find(i => i.applicationID === addProjectFields.applicationTypeId)
                                    return (<span>{`${selectedApp.category} - ${selectedApp.applicationName}`}</span>);
                                }
                            }}
                            disabled={loading}
                        >
                            {Object.keys(groupedAppListData).map(item => {
                                let listItems = groupedAppListData[item].map(item => {
                                    return (<MenuItem id={item.applicationID} key={item.applicationID} value={item.applicationID}>{item.applicationName}</MenuItem>)
                                })

                                return [
                                    <ListSubheader key={`groupitem_${item}`} disableSticky={true} style={{ color: "black", fontWeight: "bold", fontSize: "16px" }} >
                                        {translation(item)}
                                    </ListSubheader>,
                                    listItems,
                                ]
                            })}
                        </TextField>
                    </Grid>

                    <Grid item xs={8}>
                        {/* Add Project - Customer Name */}
                        <Autocomplete
                            id="add-project-cust-name-autocomplete"
                            freeSolo
                            options={userCustomers}
                            filterOptions={(options, params) =>
                                filterOptions(options, params)
                            }
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.customerName;
                            }}
                            onInputChange={(event, value, changeType) => onCustomerValueChange(event, value, changeType)}
                            onChange={(event, selectedCustomer, reason) => handleCustomerChange(event, selectedCustomer, reason)}
                            renderOption={(props, option) =>
                                option && option.customerName !== ""
                                    ? <li {...props} key={option && option.customerID}>{option.customerName}</li>
                                    : null
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translation("CustomerName")}
                                    error={addProjectFieldsErrors.custName !== "" ? true : false}
                                    helperText={injectIntlTranslation(props.intl, addProjectFieldsErrors.custName)}
                                    variant="standard"
                                    sx={{ mb: 2 }}
                                />
                            )}
                            disabled={loading}
                        />

                        {/* Add Project - Customer Contact Name */}
                        <TextField
                            id="add-project-contact-name"
                            variant="standard"
                            fullWidth
                            label={translation("CustomerContactName")}
                            value={addProjectFields.custContactName}
                            onChange={(e) => validateNewProjectForm("custContactName", e)}
                            error={addProjectFieldsErrors.custContactName !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.custContactName)}
                            disabled={loading || disableCustContactFields}
                            sx={{ mb: 2 }}
                        />

                        {/* Add Project - Customer Contact Email */}
                        <TextField
                            id="add-project-contact-email"
                            variant="standard"
                            fullWidth
                            label={translation("CustomerContactEmail")}
                            value={addProjectFields.custContactEmail}
                            onChange={(e) => validateNewProjectForm("custContactEmail", e)}
                            error={addProjectFieldsErrors.custContactEmail !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.custContactEmail)}
                            disabled={loading || disableCustContactFields}
                            sx={{ mb: 2 }}
                        />

                        {/* Add Project - Customer Contact Number */}
                        <TextField
                            id="add-project-contact-email"
                            variant="standard"
                            fullWidth
                            label={translation("CustomerContactNumber")}
                            value={addProjectFields.custContactNum}
                            onChange={(e) => validateNewProjectForm("custContactNum", e)}
                            error={addProjectFieldsErrors.custContactNum !== "" ? true : false}
                            helperText={injectIntlTranslation(intl, addProjectFieldsErrors.custContactNum)}
                            disabled={disableCustContactFields}
                            sx={{ mb: 2 }}
                        />

                        {/* Add Project  - Account Area Name */}
                        {isPFIUser
                            ? <TextField
                                id="add-project-account-area-name"
                                variant="standard"
                                fullWidth
                                select
                                label={translation("AccountAreaName")}
                                value={addProjectFields.accountareaname}
                                onChange={(e) => validateNewProjectForm("accountareaname", e)}
                                error={addProjectFieldsErrors.accountareaname !== "" ? true : false}
                                helperText={injectIntlTranslation(intl, addProjectFieldsErrors.accountareaname)}
                                sx={{ mb: 2 }}
                                disabled={loading}
                            >
                                {accountAreaNameDetails.length > 0 && accountAreaNameDetails.map(option => <MenuItem id={option.ID} key={option.ID} value={option.AreaName}> {option.AreaName} </MenuItem>)}
                            </TextField>
                            : null
                        }
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{ height: '42px' }}>
                <Button
                    id="add-project-save-btn"
                    variant="contained"
                    color="secondary"
                    startIcon={loading ? <CircularProgress color="inherit" size={20} /> : <SaveRoundedIcon />}
                    onClick={handleSubmit}
                    disabled={loading || (!isValidForm || (addProjectFieldsErrors.custName || addProjectFieldsErrors.custContactName || addProjectFieldsErrors.custContactEmail || addProjectFieldsErrors.custContactNum))}
                    style={{ height: "35px", marginRight: "10px" }}
                >
                    {injectIntlTranslation(intl, "Save")}
                </Button>

                <BuilderMenu
                    dropdownMenuClass="saveAndAddDropwDown"
                    clickHandler={OnSaveNavigateTemplate}
                    buttonProps={{
                        name: translation("SaveAndAddSelection"),
                        disabled: (loading || !isValidForm || (addProjectFieldsErrors.custName || addProjectFieldsErrors.custContactName || addProjectFieldsErrors.custContactEmail || addProjectFieldsErrors.custContactNum)),
                        id: "SaveandAddSelection",
                        iconProps: { icon: props.workflowIcon }
                    }}
                />

            </DialogActions>

        </Dialog >
    )

}

export default injectIntl(AddProjectModal);