import React from 'react';
import { Route } from 'react-router-dom';
import Error from './Error';
import { connect } from "react-redux";
// import BrowserCompatability from './NewDashboard/BrowserCompatability/BrowserCompatability'
// import MigrationError from './Shared/MigrationError/MigrationError';

const PrivateRoute = ({ component: Component,path,userRole, ...rest }) => {
  // const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
  // const isEdge = window.navigator.userAgent.indexOf("Edge") > -1 || window.navigator.userAgent.indexOf("Edg") > -1;
  const { role, permissions} = rest.props;
  const showBlankScreen = (permissions.permissions) ? ((permissions.permissions.length > 0) ? true : false) : true;

  // if (!isChrome || isEdge) {
  //   return (
  //     <Route
  //       {...rest.props}
  //       render={props =>
  //         (<BrowserCompatability to={{ pathname: '/', state: { from: props.location } }} role={role} permissions={permissions} />)
  //       }
  //     />
  //   )
  // }
  // else {
    if(permissions.length === 0){
     return(
       <Route 
         {...rest.props}
         render = { props => 
           (<Error to = {{ pathname: '/ErrorPage', state: { from: props.location }}} role={role} permissions={permissions} />) 
         }
       />
     )
    }
  //  else if (showMigrationError || showEcatMigrationError || showInvalidHVACError) {
  //     return (
  //       <Route
  //         {...rest.props}
  //         render={() =>
  //           (<MigrationError showMigrationError={showMigrationError} showInvalidHVACError={showInvalidHVACError} />)
  //         }
  //       />
  //     )
  //   }
    else if (!permissions.appAccess && showBlankScreen) {
      return null
    }
    else if (permissions.appAccess) {
      return (
        <Route
          {...rest.props}
          render={props =>
            (<Component {...props} role={role} permissions={permissions} />)
          }
        />
      )
    }
    else {
      return (
        <Route
          {...rest.props}
          render={props =>
            (<Error to={{ pathname: '/ErrorPage', state: { from: props.location } }} role={role} permissions={permissions} />)
          }
        />
      )
    }
  }
// };
const mapStateToProps = (state) => ({
  userRole: state.locale.role
})
export default connect(mapStateToProps)(PrivateRoute);
